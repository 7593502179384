import React, { InputHTMLAttributes, ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
  value: string;
  change: (value: string) => void;
}

const InputPhoneDDI = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = '',
      sizeClass = 'h-11 px-4 py-3',
      fontClass = 'text-sm font-normal',
      placeholder,
      type = 'text',
      value,
      change
    },
    ref
  ) => {
    const { i18n } = useTranslation();
    const [ddi, setDdi] = React.useState('+55');
    const [phone, setPhone] = React.useState(value);

    const formatPhoneNumber = (inputValue: string) => {
      let formattedValue = inputValue.replace(/\D/g, '');

      if (i18n.language === 'pt') {
        formattedValue = formattedValue
          .replace(/^(\d)/, '+$1')
          .replace(/(.{3})(\d)/, '$1 ($2')
          .replace(/(.{7})(\d)/, '$1) $2')
          .replace(/(.{10})(\d)/, '$1 $2');
      } else {
        formattedValue = formattedValue
          .replace(/^(\d)/, '+$1')
          .replace(/(.{4})(\d)/, '$1 ($2')
          .replace(/(.{8})(\d)/, '$1) $2')
          .replace(/(.{11})(\d)/, '$1 $2');
      }
      // TODO: ajustar regex para que funcione com DDI com 3 digitos +181 ou +100
      // e conseguentemente validar eles
      const length = formattedValue.length;
      if (length == 19) {
        formattedValue = formattedValue.replace(/(.{4})$/, '-$1');
      } else if (length >= 20) {
        formattedValue = formattedValue.replace(/(.{5})$/, '-$1').slice(0, 20);
      }

      return formattedValue;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value: inputValue } = event.target;
      const formattedValue = formatPhoneNumber(`${ddi} ${inputValue}`);
      const [primeiraParte, ...restante] = formattedValue.split(' ');
      const phone = restante.join(' ');

      setPhone(phone);
      change(`${ddi} ${phone}`);
    };

    useEffect(() => {
      const [primeiraParte, ...restante] = value.split(' ');
      const resp = restante.join(' ');
      setPhone(resp);
    }, [value, setPhone]);

    return (
      <div className="flex">
        <select
          className={`block w-24 border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-l-2xl ${fontClass} ${sizeClass} ${className}`}
          value={ddi}
          onChange={(e) => setDdi(e.target.value)}
        >
          <option value="+55">+55</option>
          <option value="+158">+158</option>
          <option value="+33">+33</option>
          <option value="+44">+44</option>
        </select>

        <input
          ref={ref}
          type={type}
          value={phone}
          placeholder={placeholder}
          onChange={handleChange}
          className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-r-2xl ${fontClass} ${sizeClass} ${className}`}
        />
      </div>
    );
  }
);

export default InputPhoneDDI;
