import { Popover, Transition } from '@headlessui/react';
import { avatarImgs } from 'contains/fakeData';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import SwitchDarkMode2 from 'shared/SwitchDarkMode/SwitchDarkMode2';
import facebookSvg from 'images/socials/facebook.svg';
import twitterSvg from 'images/socials/twitter.svg';
import googleSvg from 'images/socials/Google.svg';
import { useProfile } from 'context/ProfileContext';
import Input from 'shared/Input/Input';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import saveOrUpdateToken, { deleteToken } from 'utils/authorizarionToken';
import { AuthService } from 'services/auth';
import notifyToaster from 'shared/Toaster/NotifyToaster';
export interface AvatarDropdownProps {
  className?: string;
  children?: React.ReactNode;
}
const loginSocials = [
  {
    name: 'Facebook',
    href: '#',
    icon: facebookSvg
  },
  {
    name: 'Twitter',
    href: '#',
    icon: twitterSvg
  },
  {
    name: 'Google',
    href: '#',
    icon: googleSvg
  }
];

export default function AvatarDropdown({
  className = ''
}: AvatarDropdownProps) {
  const { t } = useTranslation();
  const { user, changeUser } = useProfile();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    AuthService.login({ email, password })
      .then((response) => {
        saveOrUpdateToken(response.data.authorization.token);
        changeUser({
          fullName: response.data.user.name,
          email: response.data.user.email,
          dateOfBirth: Date.now().toString(),
          address: 'Teresina, PI',
          gender: 'Male',
          phone: '',
          about: 'Teste de descrição do usuário'
        });
        navigate('/');
      })
      .catch((error) => {
        notifyToaster('error', error.response.data);
      });
  };

  const handleLogout = () => {
    changeUser(undefined);
    deleteToken();
    navigate('/');
  };
  return (
    <div className={`AvatarDropdown ${className}`}>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button className={``}>
              <svg
                className=" w-6 h-6 text-slate-400 hover:text-slate-50"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[300px] px-4 mt-3.5 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid grid-cols-1 gap-6 bg-white dark:bg-neutral-800 py-7 px-6">
                    {user ? (
                      <>
                        <div className="flex items-center space-x-3">
                          <Avatar
                            imgUrl={avatarImgs[7]}
                            sizeClass="w-12 h-12"
                          />

                          <div className="flex-grow">
                            <h4 className="font-semibold">{user.fullName}</h4>
                            <p className="text-xs mt-0.5">{user.address}</p>
                          </div>
                        </div>

                        <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />

                        <Link
                          to={'/account'}
                          className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          onClick={() => close()}
                        >
                          <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-medium ">
                              {t('Header.AvatarDropdown.labelAccount')}
                            </p>
                          </div>
                        </Link>

                        <Link
                          to={'/checkout'}
                          className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          onClick={() => close()}
                        >
                          <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M8 12.2H15"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8 16.2H12.38"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10 6H14C16 6 16 5 16 4C16 2 15 2 14 2H10C9 2 8 2 8 4C8 6 9 6 10 6Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M16 4.02002C19.33 4.20002 21 5.43002 21 10V16C21 20 20 22 15 22H9C4 22 3 20 3 16V10C3 5.44002 4.67 4.20002 8 4.02002"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-medium ">
                              {t('Header.AvatarDropdown.labelOrders')}
                            </p>
                          </div>
                        </Link>

                        <Link
                          to={'/account-savelists'}
                          className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          onClick={() => close()}
                        >
                          <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-medium ">
                              {t('Header.AvatarDropdown.labelWishlist')}
                            </p>
                          </div>
                        </Link>

                        <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />

                        <div className="flex items-center justify-between p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                          <div className="flex items-center">
                            <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.0001 7.88989L10.9301 9.74989C10.6901 10.1599 10.8901 10.4999 11.3601 10.4999H12.6301C13.1101 10.4999 13.3001 10.8399 13.0601 11.2499L12.0001 13.1099"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8.30011 18.0399V16.8799C6.00011 15.4899 4.11011 12.7799 4.11011 9.89993C4.11011 4.94993 8.66011 1.06993 13.8001 2.18993C16.0601 2.68993 18.0401 4.18993 19.0701 6.25993C21.1601 10.4599 18.9601 14.9199 15.7301 16.8699V18.0299C15.7301 18.3199 15.8401 18.9899 14.7701 18.9899H9.26011C8.16011 18.9999 8.30011 18.5699 8.30011 18.0399Z"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8.5 22C10.79 21.35 13.21 21.35 15.5 22"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="ml-4">
                              <p className="text-sm font-medium ">
                                {t('Header.AvatarDropdown.labelDarkmode')}
                              </p>
                            </div>
                          </div>
                          <SwitchDarkMode2 />
                        </div>

                        <Link
                          to={'/#'}
                          className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          onClick={() => close()}
                        >
                          <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.97 22C17.4928 22 21.97 17.5228 21.97 12C21.97 6.47715 17.4928 2 11.97 2C6.44715 2 1.97 6.47715 1.97 12C1.97 17.5228 6.44715 22 11.97 22Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4.89999 4.92993L8.43999 8.45993"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4.89999 19.07L8.43999 15.54"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M19.05 19.07L15.51 15.54"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M19.05 4.92993L15.51 8.45993"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-medium ">
                              {t('Header.AvatarDropdown.labelHelp')}
                            </p>
                          </div>
                        </Link>

                        <Link
                          to={'/'}
                          className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          onClick={() => {
                            handleLogout();
                            close();
                          }}
                        >
                          <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M15 12H3.62"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M5.85 8.6499L2.5 11.9999L5.85 15.3499"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-medium">
                              {t('Header.AvatarDropdown.labelLogout')}
                            </p>
                          </div>
                        </Link>
                      </>
                    ) : (
                      <div className="flex flex-col gap-3 ">
                        <h2 className="flex items-center text-3xl md:text-2xl font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                          {t('Header.AvatarDropdown.labelLogin')}
                        </h2>
                        <form
                          className="grid grid-cols-1 gap-6"
                          onSubmit={handleSubmit}
                        >
                          <label className="block ">
                            <span className="text-neutral-800 dark:text-neutral-200">
                              {t('Header.AvatarDropdown.labelEmail')}
                            </span>
                            <Input
                              type="email"
                              placeholder={t(
                                'Header.AvatarDropdown.labelPlaceholderEmail'
                              )}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="mt-1"
                              tabIndex={1}
                            />
                          </label>
                          <label className="block">
                            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                              {t('Header.AvatarDropdown.labelPassword')}
                              <Link
                                to="/forgot-pass"
                                className="text-xs text-green-600"
                              >
                                {t('Containers.PageLogin.labelForgotPassword')}
                              </Link>
                            </span>
                            <Input
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="mt-1"
                              tabIndex={2}
                            />
                          </label>
                          <ButtonPrimary type="submit">
                            {t('Header.AvatarDropdown.labelContinue')}
                          </ButtonPrimary>
                        </form>

                        <div className="flex gap-3 text-center self-center w-full">
                          {loginSocials.map((item, index) => (
                            <a
                              key={index}
                              href={item.href}
                              className="flex w-full justify-center gap-2 rounded-lg bg-primary-50 dark:bg-slate-300 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                            >
                              <img
                                className="flex-shrink-0"
                                src={item.icon}
                                alt={item.name}
                              />
                            </a>
                          ))}
                        </div>
                        <div className="flex items-center gap-3">
                          <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
                          {t('Header.AvatarDropdown.labelOr')}
                          <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
                        </div>
                        <div className="flex gap-3 ">
                          <ButtonPrimary href="/signup" className="flex-1">
                            {t('Header.AvatarDropdown.labelRegister')}
                          </ButtonPrimary>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
