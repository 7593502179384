import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrency, CurrencyOptions } from './CurrencyContext';

type LanguageContextType = {
  lang: string;
  changeLanguage: (storedLanguage: string) => void;
};

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

const spanishSpeakingCountries = [
  'es',
  'es-419',
  'es-AR',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-DO',
  'es-EC',
  'es-SV',
  'es-GT',
  'es-HN',
  'es-MX',
  'es-NI',
  'es-PA',
  'es-PY',
  'es-PE',
  'es-PR',
  'es-ES',
  'es-UY',
  'es-VE'
];
const portugueseSpeakingCountries = ['pt', 'pt-BR', 'pt-PT'];

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState<string>('pt');
  const { changeCurrency } = useCurrency();

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      changeLanguage(storedLanguage);
    } else {
      const systemLanguage = navigator.language.split('-')[0];
      changeLanguage(systemLanguage);
    }
  }, []);

  const changeLanguage = (lng: string) => {
    setLang(lng);
    localStorage.setItem('selectedLanguage', lng);
    i18n.changeLanguage(lng);

    // Change currency based on language
    if (spanishSpeakingCountries.includes(lng)) {
      changeCurrency(CurrencyOptions.USD);
    } else if (portugueseSpeakingCountries.includes(lng)) {
      changeCurrency(CurrencyOptions.BRL);
    } else {
      changeCurrency(CurrencyOptions.USD);
    }
  };

  return (
    <LanguageContext.Provider value={{ lang, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error(
      'useLanguage deve ser utilizado dentro de um LanguageProvider'
    );
  }
  return context;
};
