import toast from 'react-hot-toast';

const notifyToaster = (type: 'success' | 'error', data: any) => {
  const notifyFunctions = {
    success: toast.success,
    error: toast.error
  };

  // Chama a função apropriada do objeto notifyFunctions
  notifyFunctions[type](data.message, {
    position: 'top-right',
    id: 'nc-product-notify',
    duration: 3000
  });
};
export default notifyToaster;
