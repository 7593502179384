import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Pagination from 'shared/Pagination/Pagination';
import Input from 'shared/Input/Input';
import ButtonCircle from 'shared/Button/ButtonCircle';
import ProductCard from 'components/ProductCard';
import { TabFiltersState } from 'components/TabFilters';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useProdutosSearch } from 'hooks/useQuery';
import useDebounce from 'hooks/useDebounce';
import { ILink, IPageRequest, IPageResult } from 'types/pagination';

export interface PageSearchProps {
    className?: string;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const PageSearch: FC<PageSearchProps> = ({ className = '' }) => {
    const { t } = useTranslation();

    const query = useQuery();
    const search = query.get('q') || '';
    const page = query.get('page') || '';

    const [searchName, setSearchName] = useState(search);
    const [filters, setFilters] = useState<TabFiltersState>({
        isOnSale: false,
        rangePrices: [100, 500],
        categoriesState: [],
        colorsState: [],
        sizesState: [],
        sortOrderStates: '',
        searchName: ''
    });
    const [pagination, setPagination] = useState<IPageRequest>({
        page: 1,
        per_page: 12
    });

    const debouncedSearchName = useDebounce(searchName, 1000);

    const { data: products, isLoading } = useProdutosSearch(
        debouncedSearchName,
        filters,
        pagination
    );

    const itemsPerPage = 12;
    const [currentPage, setCurrentPage] = useState(1); //inital page

    const handleChangePage = (page: number) => {
        setCurrentPage(page);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Pegue os produtos da página atual
    const currentProducts = products?.produtos.slice(
        indexOfFirstItem,
        indexOfLastItem
    );

    // Calcular quantas páginas serão necessárias
    const totalPages = Math.ceil(
        (products?.produtos?.length || 0) / itemsPerPage
    );

    // Gere os links de paginação
    const paginationLinks = Array.from({ length: totalPages }, (_, index) => ({
        label: `${index + 1}`,
        url: '#',
        active: index + 1 === currentPage
    }));

    const initial = products?.produtos.map((item) => item).slice(0, 12);

    const handleScrollToEl = (id: string) => {
        const element = document.getElementById(id);
        setTimeout(() => {
            element?.scrollIntoView({ behavior: 'smooth' });
        }, 80);
    };

    // Atualiza a página quando o usuário digitar algo no campo de busca
    useEffect(() => {
        setFilters({ ...filters, searchName: debouncedSearchName });
        setPagination({ ...pagination, page: 1 });
    }, [debouncedSearchName]);

    // Inicializa a página com os valores da URL
    useEffect(() => {
        setFilters({ ...filters, searchName: searchName });
        setPagination({ ...pagination, page: Number(page) });
    }, []);

    // Atualiza a página quando o usuário digitar algo no campo de busca
    useEffect(() => {
        setFilters({ ...filters, searchName: searchName });
    }, [search]);

    // Atualiza a página quando o usuário clicar em uma páginação
    useEffect(() => {
        setPagination({ ...pagination, page: Number(page) });
    }, [page]);

    return (
        <div
            className={`nc-PageSearch  ${className} scroll-smooth`}
            data-nc-id="PageSearch"
            id="PageSearch"
        >
            <Helmet>
                <title>{searchName ?? 'Busca'} Ap Professional</title>
            </Helmet>

            <div
                className={`nc-HeadBackgroundCommon h-24 2xl:h-28 top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
                data-nc-id="HeadBackgroundCommon"
            />
            <div className="container">
                <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
                    <form
                        className="relative w-full"
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <label
                            htmlFor="search-input"
                            className="text-neutral-500 dark:text-neutral-300"
                        >
                            <span className="sr-only">Search all icons</span>
                            <Input
                                className="shadow-lg border-0 dark:border"
                                id="search-input"
                                type="search"
                                placeholder={t(
                                    'Header.MainNav2.labelPlaceholder'
                                )}
                                sizeClass="pl-14 py-5 pr-5 md:pl-16"
                                rounded="rounded-full"
                                value={searchName}
                                onChange={(e) => setSearchName(e.target.value)}
                            />
                            <ButtonCircle
                                className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                                size=" w-11 h-11"
                                type="submit"
                            >
                                <i className="las la-arrow-right text-xl"></i>
                            </ButtonCircle>
                            <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                                <svg
                                    className="h-5 w-5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M22 22L20 20"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </label>
                    </form>
                </header>
            </div>

            <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
                <main>
                    {/* FILTER */}
                    {/* <HeaderFilterSearchPage setFilters={setFilters} /> */}

                    {/* LOOP ITEMS */}
                    {isLoading ? (
                        <div className=" h-[50vh]">Loading...</div>
                    ) : products?.produtos?.length === 0 ? (
                        <div className="flex flex-col justify-center items-center min-h-[300px] text-3xl">
                            {/* <img
                src={NoFoundProducts}
                alt="No products found"
                className="w-96 h-auto"
              /> */}
                            No products found
                        </div>
                    ) : (
                        <div>
                            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
                                {currentProducts?.map((item, index) => (
                                    <ProductCard data={item} key={index} />
                                ))}
                            </div>
                            {/* PAGINATION */}
                            <div
                                className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center"
                                onClick={() => handleScrollToEl('PageSearch')}
                            >
                                {/* <Pagination links={products?.links} /> */}
                                <Pagination
                                    links={paginationLinks}
                                    onPageChange={handleChangePage}
                                />

                                {/* <ButtonPrimary loading>Show me more</ButtonPrimary> */}
                            </div>
                        </div>
                    )}
                </main>
            </div>
        </div>
    );
};

export default PageSearch;
