import React, { FC, useEffect, useId, useState } from 'react';
// import HeaderFilterSection from "components/HeaderFilterSection";
import ProductCard from 'components/ProductCard';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { Product, PRODUCTS } from 'data/data';
import { useProdutos } from 'hooks/useQuery';
import { useNavigate } from 'react-router-dom';

//
export interface SectionGridFeatureItemsProps {
    data?: Product[];
}

const SectionGridFeatureItems: FC<SectionGridFeatureItemsProps> = ({
    data = PRODUCTS
}) => {
    const id = useId();
    const { data: product = [] } = useProdutos();
    const navigate = useNavigate();
    const [items, setItems] = useState<Product[]>(data);

    useEffect(() => {
        if (product) {
            setItems(product.slice(0, 9));
        }
    }, [product]);

    return (
        <div className="nc-SectionGridFeatureItems relative">
            <div
                className={` grid gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 `}
            >
                {items.map((item, index) => (
                    <ProductCard data={item} key={index} />
                ))}
            </div>
            <div className="flex mt-16 justify-center items-center">
                <ButtonPrimary onClick={() => navigate('/busca')}>
                    Show me more
                </ButtonPrimary>
            </div>
        </div>
    );
};

export default SectionGridFeatureItems;
