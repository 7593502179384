import { Product } from 'data/data';
import React, { createContext, useContext, useEffect, useState } from 'react';

export enum CurrencyOptions {
  USD = '$',
  EUR = '€',
  BRL = 'R$'
}

type CurrencyContextType = {
  currency: CurrencyOptions;
  changeCurrency: (storedLanguage: CurrencyOptions) => void;
  switchCurrency: (product: Product) => number;
};

const CurrencyContext = createContext<CurrencyContextType | undefined>(
  undefined
);

export const CurrencyProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [currency, setCurrency] = useState<CurrencyOptions>(
    CurrencyOptions.BRL
  );

  const changeCurrency = (currency: CurrencyOptions) => {
    setCurrency(currency);
    localStorage.setItem('selectedCurrency', currency);
  };

  const switchCurrency = (product: Product) => {
    switch (currency) {
      case CurrencyOptions.USD:
        return product.price_venda_dolar;
      case CurrencyOptions.EUR:
        return product.price_venda_euro;
      default:
        return product.price;
    }
  };

  useEffect(() => {
    const storedCurrency = localStorage.getItem(
      'selectedCurrency'
    ) as CurrencyOptions;
    if (
      storedCurrency &&
      Object.values(CurrencyOptions).includes(storedCurrency)
    ) {
      changeCurrency(storedCurrency);
    } else {
      changeCurrency(CurrencyOptions.BRL);
    }
  }, []);

  return (
    <CurrencyContext.Provider
      value={{ currency, changeCurrency, switchCurrency }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error(
      'useCurrency deve ser utilizado dentro de um CurrencyProvider'
    );
  }
  return context;
};
