import React, { FC } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import bannerDtox from "images/banners/banner-detox.jpg"

export interface SectionPromo2Props {
  className?: string;
}

const SectionPromo2: FC<SectionPromo2Props> = ({ className = '' }) => {
  const { t } = useTranslation();

  return (
    <div className={`nc-SectionPromo2 ${className}`}>
        <div className="relative rounded-2xl sm:rounded-[40px]">
            <Link to={"/page-collection"}>
                <NcImage
                className='rounded-2xl w-[1450px]'
                src={bannerDtox} 
                />
                <div className='absolute lg:bottom-48 lg:ml-28 lg:block hidden'>
                    <ButtonPrimary
                        href="/page-collection"
                        className="border border-green-700 text-white bg-green-700 cursor-pointer"
                        >
                        {t('Components.SectionPromo1.labelLearnMore')}
                    </ButtonPrimary>
                </div>
            </Link>
            {/* <div className="lg:w-[45%] max-w-lg relative">
            <h2 className="font-semibold text-3xl sm:text-4xl xl:text-3xl 2xl:text-6xl mt-2 sm:mt-4 !leading-[1.13] tracking-tight">
                Rainha Luxury <br />
                Profissional
            </h2>
            <span className="text-xl block mt-6 text-slate-500 dark:text-slate-400">
                {t('Components.SectionPromo2.labelSectionPromo2ProdutDesc')}
            </span>
            <div className="flex max-md:justify-center space-x-2 sm:space-x-5 mt-6 sm:mt-12">
                <ButtonPrimary
                href="/busca"
                className="border border-orange-500 text-orange-700 bg-orange-400 hover:bg-orange-700 hover:text-orange-300"
                >
                {t('Components.SectionPromo1.labelLearnMore')}
                </ButtonPrimary>
            </div>
            </div>
            <NcImage
            containerClassName="relative block lg:absolute lg:left-0 lg:bottom-0 mt-4 lg:mt-0 max-w-xl lg:max-w-[calc(50%-40px)]"
            src={rightImgDemo}
            /> */}
        </div>
    </div>
  );
};

export default SectionPromo2;
