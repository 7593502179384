import React, { FC, useEffect, useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import LikeButton from 'components/LikeButton';
import AccordionInfo from './AccordionInfo';
import { StarIcon } from '@heroicons/react/24/solid';
import BagIcon from 'components/BagIcon';
import NcInputNumber from 'components/NcInputNumber';
import { Product, PRODUCTS } from 'data/data';
import {
    NoSymbolIcon,
    ClockIcon,
    SparklesIcon
} from '@heroicons/react/24/outline';
import IconDiscount from 'components/IconDiscount';
import Prices from 'components/Prices';
import toast from 'react-hot-toast';
import SectionSliderProductCard from 'components/SectionSliderProductCard1';
import detail3JPG from 'images/products/detalhes-carrinho2-.png';
import Policy from './Policy';
import ReviewItem from 'components/ReviewItem';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
// import SectionPromo2 from "components/SectionPromo2";
import ModalViewAllReviews from './ModalViewAllReviews';
import NotifyAddTocart from 'components/NotifyAddTocart';
import { ProdutoService } from 'services/produtos';
import { useParams } from 'react-router-dom';
import { transformProdutos } from 'utils/transformToProduct';
import { useTranslation } from 'react-i18next';
import { useCart } from 'context/CartContext';
import { useProdutos } from 'hooks/useQuery';

export interface ProductDetailPageProps {
    className?: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = '' }) => {
    const { id } = useParams();
    const { addOneProductToCart } = useCart();
    const { t } = useTranslation();
    const { data: products } = useProdutos();

    const [data, setData] = useState<Product>({} as Product);
    const { sizes, variants, status, allOfSizes } = data;
    const LIST_IMAGES_DEMO = data.image
        ? [data.image, ...data.images]
        : [detail3JPG];

    const [variantActive, setVariantActive] = React.useState(0);
    const [sizeSelected, setSizeSelected] = React.useState(
        sizes ? sizes[0] : ''
    );
    const [qualitySelected, setQualitySelected] = React.useState(1);
    const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] =
        useState(false);

    // BUSCA ID DA API
    useEffect(() => {
        id &&
            ProdutoService.getById({ id }).then((res) => {
                const dataFiltered = transformProdutos([res.data]);
                // setData(dataFiltered[0]);
            });
    }, [id]);

    useEffect(() => {
        const fetchProduct = () => {
            // Encontrando o produto
            const dataFiltered = products?.find(
                (product) => String(product.id) === id
            );

            if (dataFiltered) {
                setData(dataFiltered); // Define o produto encontrado
            }
        };

        fetchProduct();
    }, [id]);

    const notifyAddTocart = () => {
        toast.custom(
            (t) => (
                <NotifyAddTocart
                    productImage={LIST_IMAGES_DEMO[0]}
                    qualitySelected={qualitySelected}
                    show={t.visible}
                    sizeSelected={sizeSelected}
                    variantActive={variantActive}
                />
            ),
            { position: 'top-right', id: 'nc-product-notify', duration: 3000 }
        );
    };

    const renderVariants = () => {
        if (!variants || !variants.length) {
            return null;
        }

        return (
            <div>
                <label htmlFor="">
                    <span className="text-sm font-medium">
                        Color:
                        <span className="ml-1 font-semibold">
                            {variants[variantActive].name}
                        </span>
                    </span>
                </label>
                <div className="flex mt-3">
                    {variants.map((variant, index) => (
                        <div
                            key={index}
                            onClick={() => setVariantActive(index)}
                            className={`relative flex-1 max-w-[75px] h-10 sm:h-11 rounded-full border-2 cursor-pointer ${
                                variantActive === index
                                    ? 'border-primary-6000 dark:border-primary-500'
                                    : 'border-transparent'
                            }`}
                        >
                            <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
                                <img
                                    src={variant.thumbnail}
                                    alt=""
                                    className="absolute w-full h-full object-cover"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderSizeList = () => {
        if (!allOfSizes || !sizes || !sizes.length) {
            return null;
        }
        return (
            <div>
                <div className="flex justify-between font-medium text-sm">
                    <label htmlFor="">
                        <span className="">
                            Size:
                            <span className="ml-1 font-semibold">
                                {sizeSelected}
                            </span>
                        </span>
                    </label>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="##"
                        className="text-primary-6000 hover:text-primary-500"
                    >
                        See sizing chart
                    </a>
                </div>
                <div className="grid grid-cols-5 sm:grid-cols-7 gap-2 mt-3">
                    {allOfSizes.map((size, index) => {
                        const isActive = size === sizeSelected;
                        const sizeOutStock = !sizes.includes(size);
                        return (
                            <div
                                key={index}
                                className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${
                    sizeOutStock
                        ? 'text-opacity-20 dark:text-opacity-20 cursor-not-allowed'
                        : 'cursor-pointer'
                } ${
                    isActive
                        ? 'bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000'
                        : 'border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700'
                }`}
                                onClick={() => {
                                    if (sizeOutStock) {
                                        return;
                                    }
                                    setSizeSelected(size);
                                }}
                            >
                                {size}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const renderStatus = () => {
        if (!status) {
            return null;
        }
        const CLASSES =
            'absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300';
        if (status === 'New in') {
            return (
                <div className={CLASSES}>
                    <SparklesIcon className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        if (status === '50% Discount') {
            return (
                <div className={CLASSES}>
                    <IconDiscount className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        if (status === 'Sold Out') {
            return (
                <div className={CLASSES}>
                    <NoSymbolIcon className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        if (status === 'limited edition') {
            return (
                <div className={CLASSES}>
                    <ClockIcon className="w-3.5 h-3.5" />
                    <span className="ml-1 leading-none">{status}</span>
                </div>
            );
        }
        return null;
    };

    const renderSectionContent = () => {
        return (
            <div className="space-y-7 2xl:space-y-8">
                {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
                {/* <div className="">{renderVariants()}</div> */}
                {/* <div className="">{renderSizeList()}</div> */}

                {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
                <div>
                    <div className="flex items-center mt-5 w-full text-9xl space-x-4 sm:space-x-5">
                        <Prices
                            contentClass="py-1 px-2 md:py-1.5 md:px-0 font-semibold"
                            price={data.price}
                            fontSize="3xl"
                            fontSpacing="wide"
                        />
                        <div className="flex items-center"></div>
                    </div>
                </div>
                <div className="flex space-x-3.5">
                    <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
                        <NcInputNumber
                            defaultValue={qualitySelected}
                            onChange={setQualitySelected}
                        />
                    </div>
                    <ButtonPrimary
                        className="flex-1 flex-shrink-0"
                        onClick={() => {
                            notifyAddTocart();
                            addOneProductToCart({
                                id: data.id,
                                product: data,
                                quantity: qualitySelected
                            });
                        }}
                    >
                        <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
                        <span className="ml-3">
                            {t(
                                'Containers.ProductDetailPage.ProductDetailPage.labelAddToCart'
                            )}
                        </span>
                    </ButtonPrimary>
                </div>

                {/*  */}
                <hr className=" 2xl:!my-10 border-slate-200 dark:border-slate-700"></hr>
                {/*  */}

                {/* ---------- 5 ----------  */}
                <AccordionInfo
                    data={[
                        {
                            name: t('ProductQuickView.labelDescription'),
                            content: data.seo_meta_tag_descricao ?? ''
                        },
                        {
                            name: t('ProductQuickView.labelFeatures'),
                            content: data.description ?? ''
                        }
                    ]}
                />

                {/* ---------- 6 ----------  */}
                <div className="hidden xl:block">
                    <Policy />
                </div>
            </div>
        );
    };

    const renderReviews = () => {
        return (
            <div className="">
                {/* HEADING */}
                <h2 className="text-2xl font-semibold flex items-center">
                    <StarIcon className="w-7 h-7 mb-0.5" />
                    <span className="ml-1.5"> 4,87 · 142 Reviews</span>
                </h2>

                {/* comment */}
                <div className="mt-10">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-11 gap-x-28">
                        <ReviewItem
                            data={{
                                comment: t(
                                    'Components.ReviewItem.labelReviewComent1'
                                ),
                                date: 'December 22, 2021',
                                name: 'Carla M.',
                                starPoint: 5
                            }}
                        />
                        <ReviewItem
                            data={{
                                comment: t(
                                    'Components.ReviewItem.labelReviewComent2'
                                ),
                                date: 'December 22, 2021',
                                name: 'Olivia W.',
                                starPoint: 5
                            }}
                        />
                        <ReviewItem
                            data={{
                                comment: t(
                                    'Components.ReviewItem.labelReviewComent3'
                                ),
                                date: 'August 15, 2022',
                                name: ' Ana J.',
                                starPoint: 5
                            }}
                        />
                        <ReviewItem
                            data={{
                                comment: t(
                                    'Components.ReviewItem.labelReviewComent4'
                                ),
                                date: 'December 12, 2022',
                                name: 'Marta S.',
                                starPoint: 5
                            }}
                        />
                    </div>

                    <ButtonSecondary
                        onClick={() => setIsOpenModalViewAllReviews(true)}
                        className="mt-10 border border-slate-300 dark:border-slate-700 "
                    >
                        {t(
                            'Containers.ProductDetailPage.ProductDetailPage.labelShowMoreReview'
                        )}
                    </ButtonSecondary>
                </div>
            </div>
        );
    };

    return (
        <div className={`nc-ProductDetailPage ${className}`}>
            {/* MAIn */}
            <main className="container mt-5 lg:mt-11">
                <div className="lg:flex">
                    {/* CONTENT */}
                    <div className="w-full lg:w-[55%] ">
                        {/* HEADING */}
                        <div className="text-center md:text-start">
                            <h2 className="text-2xl sm:text-3xl font-semibold">
                                {data.name}
                            </h2>
                        </div>
                        <div className="relative">
                            <div className="aspect-w-16 aspect-h-16 mt-4">
                                <img
                                    src={LIST_IMAGES_DEMO[0]}
                                    className="w-full rounded-2xl object-contain"
                                    alt="product detail 1"
                                />
                            </div>
                            {/* {renderStatus()} */}
                            {/* META FAVORITES */}
                            <LikeButton className="absolute right-3 top-3 " />
                        </div>
                    </div>

                    {/* SIDEBAR */}
                    <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
                        {renderSectionContent()}
                    </div>
                </div>

                {/* DETAIL AND REVIEW */}
                <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
                    <div className="block xl:hidden">
                        <Policy />
                    </div>

                    {/* {renderDetailSection()} */}

                    <hr className="border-slate-200 dark:border-slate-700" />

                    {renderReviews()}

                    <hr className="border-slate-200 dark:border-slate-700" />

                    {/* OTHER SECTION */}
                    <SectionSliderProductCard
                        heading="Customers also purchased"
                        subHeading=""
                        headingFontClassName="text-2xl font-semibold"
                        headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
                    />
                </div>
            </main>

            {/* MODAL VIEW ALL REVIEW */}
            <ModalViewAllReviews
                show={isOpenModalViewAllReviews}
                onCloseModalViewAllReviews={() =>
                    setIsOpenModalViewAllReviews(false)
                }
            />
        </div>
    );
};

export default ProductDetailPage;
