import CardCategory1 from 'components/CardCategories/CardCategory1';
import CardCategory4 from 'components/CardCategories/CardCategory4';
import Heading from 'components/Heading/Heading';
import NavItem2 from 'components/NavItem2';
import React, { FC } from 'react';
import Nav from 'shared/Nav/Nav';
import explore1Png from 'images/products/look-safira.jpg';
import explore2Png from 'images/products/liso-lambido.png';
import explore3Png from 'images/products/col-rica-tratamento.png';
import explore5Png from 'images/products/mainha-nutre.jpg';
import explore6Png from 'images/products/kit-hair-styler-brown.jpg';
import explore7Png from 'images/products/chapinha_gold.png';
import explore8Png from 'images/products/lavatorio-1.png';
import explore9Png from 'images/products/serum-sem-lagrimas2.jpg';
import CardCategory6 from 'components/CardCategories/CardCategory6';
import CardCategory7 from 'components/CardCategories/CardCategory7';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ExploreType {
    id: number;
    name: string;
    desc: string;
    image: string;
    svgBg: string;
    color?: string;
    href: string;
}

export interface SectionGridMoreExploreProps {
    className?: string;
    gridClassName?: string;
    boxCard?: 'box1' | 'box4' | 'box6' | 'box8';
    data?: ExploreType[];
}

export const DEMO_MORE_EXPLORE_DATA = [
    {
        id: 1,
        name: '',
        desc: '',
        image: explore1Png,
        svgBg: explore1Png,
        color: 'bg-indigo-50',
        href: '/page-ana-autentic'
    },
    {
        id: 2,
        name: ``,
        desc: '',
        image: explore2Png,
        svgBg: explore2Png,
        color: 'bg-slate-100/80',
        href: '/page-transformation'
    },
    {
        id: 3,
        name: '',
        desc: '',
        image: explore3Png,
        svgBg: explore3Png,
        color: 'bg-violet-50',
        href: '/page-treatment'
    },
    {
        id: 4,
        name: '',
        desc: '',
        image: explore9Png,
        svgBg: explore9Png,
        color: 'bg-orange-50',
        href: '/page-finishing'
    },
    {
        id: 5,
        name: '',
        desc: '',
        image: explore5Png,
        svgBg: explore5Png,
        color: 'bg-blue-50',
        href: '/page-home-care'
    },
    {
        id: 6,
        name: '',
        desc: '',
        image: explore6Png,
        svgBg: explore6Png,
        color: 'bg-orange-50',
        href: '/page-accessories'
    },
    {
        id: 7,
        name: '',
        desc: '',
        image: explore7Png,
        svgBg: explore7Png,
        color: 'bg-stone-100',
        href: '/page-equipament'
    },
    {
        id: 8,
        name: '',
        desc: '',
        image: explore8Png,
        svgBg: explore8Png,
        color: 'bg-blue-50',
        href: '/page-forniture'
    }
];

const SectionGridMoreExplore: FC<SectionGridMoreExploreProps> = ({
    className = '',
    boxCard = 'box4',
    gridClassName = 'grid-cols-1 md:grid-cols-2 xl:grid-cols-4',
    data = DEMO_MORE_EXPLORE_DATA.filter((_, i) => i < 8)
}) => {
    const [tabActive, setTabActive] = React.useState('Man');

    const { t } = useTranslation();
    const renderCard = (item: ExploreType) => {
        switch (boxCard) {
            case 'box1':
                return (
                    <CardCategory1
                        key={item.id}
                        name={item.name}
                        desc={item.desc}
                        featuredImage={item.image}
                    />
                );

            case 'box4':
                return (
                    <CardCategory4
                        name={item.name}
                        desc={item.desc}
                        bgSVG={item.svgBg}
                        featuredImage={item.image}
                        key={item.id}
                        color={item.color}
                        href={item.href}
                    />
                );
            case 'box6':
                return (
                    <CardCategory6
                        name={item.name}
                        desc={item.desc}
                        bgSVG={item.svgBg}
                        featuredImage={item.image}
                        key={item.id}
                        color={item.color}
                    />
                );
            case 'box8':
                return (
                    <CardCategory7
                        name={item.name}
                        desc={item.desc}
                        bgSVG={item.svgBg}
                        featuredImage={item.image}
                        key={item.id}
                        color={item.color}
                    />
                );

            default:
                return (
                    <CardCategory4
                        name={item.name}
                        desc={item.desc}
                        bgSVG={item.svgBg}
                        featuredImage={item.image}
                        key={item.id}
                        color={item.color}
                    />
                );
        }
    };

    data[0].name = `${t(`Components.SectionGridMoreExplore.labelSectionNavAna`).toLocaleUpperCase()}`;
    data[1].name = `${t(`Components.SectionGridMoreExplore.labelSectionNavTransformation`).toLocaleUpperCase()}`;
    data[2].name = `${t(`Components.SectionGridMoreExplore.labelSectionNavTreatment`).toLocaleUpperCase()}`;
    data[3].name = `${t(`Components.SectionGridMoreExplore.labelSectionNavFinishing`).toLocaleUpperCase()}`;
    data[4].name = `${t(`Components.SectionGridMoreExplore.labelSectionNavHomeCare`).toLocaleUpperCase()}`;
    data[5].name = `${t(`Components.SectionGridMoreExplore.labelSectionNavAccessories`).toLocaleUpperCase()}`;
    data[6].name = `${t(`Components.SectionGridMoreExplore.labelSectionNavEquipment`).toLocaleUpperCase()}`;
    data[7].name = `${t(`Components.SectionGridMoreExplore.labelSectionNavFurniture`).toLocaleUpperCase()}`;

    const renderHeading = () => {
        return (
            <div>
                <Heading
                    className="mb-4 lg:mb-8 text-neutral-900 dark:text-neutral-50"
                    fontClass="text-2xl md:text-2xl lg:text-4xl font-semibold"
                    isCenter
                    desc=""
                >
                    {t(
                        `Components.SectionGridMoreExplore.labelSectionExploreTitle`
                    )}
                </Heading>
                <Nav
                    className="p-1 bg-white dark:bg-neutral-800 rounded-full shadow-lg overflow-x-auto hiddenScrollbar"
                    containerClassName="mb-4 lg:mb-8 relative flex justify-center w-full text-xs md:text-sm"
                >
                    {[
                        {
                            name: `${t(`Components.SectionGridMoreExplore.labelSectionNavAna`)}`,
                            icon: ``,
                            href: '/page-ana-autentic'
                        },
                        {
                            name: `${t(`Components.SectionGridMoreExplore.labelSectionNavTransformation`)}`,
                            icon: ``,
                            href: '/page-transformation'
                        },
                        {
                            name: `${t(`Components.SectionGridMoreExplore.labelSectionNavTreatment`)}`,
                            icon: ``,
                            href: 'page-treatment'
                        },
                        {
                            name: `${t(`Components.SectionGridMoreExplore.labelSectionNavFinishing`)}`,
                            icon: ``,
                            href: '/page-finishing'
                        },
                        {
                            name: `${t(`Components.SectionGridMoreExplore.labelSectionNavHomeCare`)}`,
                            icon: ``,
                            href: '/page-home-care'
                        },
                        {
                            name: `${t(`Components.SectionGridMoreExplore.labelSectionNavAccessories`)}`,
                            icon: ``,
                            href: '/page-accessories'
                        },
                        {
                            name: `${t(`Components.SectionGridMoreExplore.labelSectionNavEquipment`)}`,
                            icon: ``,
                            href: '/page-equipament'
                        },
                        {
                            name: `${t(`Components.SectionGridMoreExplore.labelSectionNavFurniture`)}`,
                            icon: ``,
                            href: '/page-forniture'
                        }
                    ].map((item, index) => (
                        <NavItem2
                            key={index}
                            isActive={tabActive === item.name}
                            onClick={() => setTabActive(item.name)}
                        >
                            <div className="flex items-center justify-center space-x-1.5 sm:space-x-2.5 text-xs sm:text-sm ">
                                <span
                                    className="inline-block"
                                    dangerouslySetInnerHTML={{
                                        __html: item.icon
                                    }}
                                ></span>
                                <span>{item.name}</span>
                                <Link to={item.href} />
                            </div>
                        </NavItem2>
                    ))}
                </Nav>
            </div>
        );
    };

    return (
        <div
            className={`nc-SectionGridMoreExplore relative ${className}`}
            data-nc-id="SectionGridMoreExplore"
        >
            {renderHeading()}
            <div className={`grid gap-4 md:gap-7 ${gridClassName}`}>
                {data.map((item) => renderCard(item))}
            </div>
        </div>
    );
};

export default SectionGridMoreExplore;
