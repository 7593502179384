import Label from 'components/Label/Label';
import React from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
import CommonLayout from './CommonLayout';
import { useTranslation } from 'react-i18next';

const AccountPass = () => {
  const { t } = useTranslation();
  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            {t('Containers.AccountPage.AccountPass.labelHeaderName')}
          </h2>
          <div className=" max-w-xl space-y-6">
            <div>
              <Label>
                {t('Containers.AccountPage.AccountPass.labelCurrentPass')}
              </Label>
              <Input type="password" className="mt-1.5" />
            </div>
            <div>
              <Label>
                {t('Containers.AccountPage.AccountPass.labelNewPass')}
              </Label>
              <Input type="password" className="mt-1.5" />
            </div>
            <div>
              <Label>
                {t('Containers.AccountPage.AccountPass.labelConfirmPass')}
              </Label>
              <Input type="password" className="mt-1.5" />
            </div>
            <div className="pt-2">
              <ButtonPrimary>
                {t('Containers.AccountPage.AccountPass.labelUpdatePass')}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
