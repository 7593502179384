import React, { FC, useEffect, useId, useRef } from 'react';
import Heading from 'components/Heading/Heading';
import Glide from '@glidejs/glide';
import ProductCard from './ProductCard';
import { Product, PRODUCTS } from 'data/data';
import { useProdutos } from 'hooks/useQuery';
import { useTranslation } from 'react-i18next';

export interface SectionSliderProductCard2Props {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  data?: Product[];
}

const SectionSliderProductCard2: FC<SectionSliderProductCard2Props> = ({
  className = '',
  itemClassName = '',
  headingFontClassName,
  headingClassName,
  heading,

  data = PRODUCTS.filter((_, i) => i < 8 && i > 2)
}) => {
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = 'glidejs' + id.replace(/:/g, '_');
  const { t } = useTranslation();


  const { data: products = [] } = useProdutos();

  useEffect(() => {
    let slider: Glide;
    if (products.length > 0) {
      const OPTIONS = {
        perView: 4,
        gap: 32,
        bound: true,
        breakpoints: {
          1280: {
            perView: 4 - 1
          },
          1024: {
            gap: 20,
            perView: 4 - 1
          },
          768: {
            gap: 20,
            perView: 4 - 2
          },
          640: {
            gap: 20,
            perView: 1.5
          },
          500: {
            gap: 20,
            perView: 2.4
          }
        }
      };

      slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
      slider.mount();
    }

    return () => {
      if (slider) {
        slider.destroy();
      }
    };
  }, [products, UNIQUE_CLASS]);

  return (
    <div className={`nc-SectionSliderProductCard2 ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading
          className={`mb-4 ${headingClassName}`}
          fontClass={headingFontClassName}
          hasNextPrev
        >
          {heading || t('SectionSliderProductCard.labelHighlight')}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {products.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                <ProductCard data={item} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderProductCard2;
