import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import NcImage from 'shared/NcImage/NcImage';
import LikeButton from './LikeButton';
import Prices from './Prices';
import {
    ArrowsPointingOutIcon,
    DocumentCurrencyBangladeshiIcon
} from '@heroicons/react/24/outline';
import { Product, PRODUCTS } from 'data/data';

import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import BagIcon from './BagIcon';
import toast from 'react-hot-toast';
import { Transition } from '@headlessui/react';
import ModalQuickView from './ModalQuickView';
import ProductStatus from './ProductStatus';
import { useTranslation } from 'react-i18next';
import { useCart } from 'context/CartContext';
import { useCurrency } from 'context/CurrencyContext';
import { useProdutos } from 'hooks/useQuery';

export interface ProductCardProps {
    className?: string;
    data?: Product;
    isLiked?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({
    className = '',
    data = PRODUCTS[0],
    isLiked
}) => {
    const { t: translate } = useTranslation();
    const { switchCurrency } = useCurrency();
    const { addOneProductToCart } = useCart();
    const { name, sizes, status, image, seo_tag_titulo } = data;
    const [showModalQuickView, setShowModalQuickView] = React.useState(false);

    const notifyAddTocart = ({ size }: { size?: string }) => {
        toast.custom(
            (t) => (
                <Transition
                    appear
                    show={t.visible}
                    className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                    enter="transition-all duration-150"
                    enterFrom="opacity-0 translate-x-20"
                    enterTo="opacity-100 translate-x-0"
                    leave="transition-all duration-150"
                    leaveFrom="opacity-100 translate-x-0"
                    leaveTo="opacity-0 translate-x-20"
                >
                    <p className="block text-base font-semibold leading-none">
                        {translate('ProductCard.labelAddedToCart')}
                    </p>
                    <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
                    {renderProductCartOnNotify({ size })}
                </Transition>
            ),
            { position: 'top-right', id: 'nc-product-notify', duration: 3000 }
        );
    };

    const renderProductCartOnNotify = ({ size }: { size?: string }) => {
        return (
            <div className="flex ">
                <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                    <img
                        src={image}
                        alt={name}
                        className="h-full w-full object-cover object-center"
                    />
                </div>

                <div className="ml-4 flex flex-1 flex-col">
                    <div>
                        <div className="flex justify-between ">
                            <div>
                                <h3 className="text-base font-medium ">
                                    {name}
                                </h3>
                                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                                    <span></span>
                                    {/* <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span> */}
                                    {/* <span>{size || "XL"}</span> */}
                                </p>
                            </div>
                            <Prices
                                price={switchCurrency(data)}
                                className="mt-0.5"
                            />
                        </div>
                    </div>
                    <div className="flex flex-1 items-end justify-end text-sm">
                        {/* <p className="text-gray-500 dark:text-slate-400">Qty 1</p> */}

                        <div className="flex">
                            <Link
                                to={'/cart'}
                                className="font-medium text-primary-6000 dark:text-primary-500 "
                            >
                                {translate('ProductCard.labelViewCart')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div
                className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`}
                data-nc-id="ProductCard"
            >
                <Link
                    to={'/produto/' + data.id}
                    className="absolute inset-0"
                ></Link>

                <div className="relative flex-shrink-0 bg-slate-200 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
                    <Link to={'/produto/' + data.id} className="block">
                        <NcImage
                            containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
                            src={image}
                            className="object-cover w-full h-full drop-shadow-xl"
                        />
                    </Link>

                    {/* <ProductStatus status={status} /> */}

                    <LikeButton
                        liked={isLiked}
                        className="absolute top-3 right-3 z-10"
                    />

                    {/* {sizes ? renderSizeList() : renderGroupButtons()} */}
                </div>

                <div className="space-y-4 px-2.5 pt-5 pb-2.5">
                    {/* {renderVariants()} */}
                    <div>
                        <h2
                            className={`nc-ProductCard__title text-base font-light transition-colors`}
                        >
                            {name}
                        </h2>
                        <p
                            className={`text-sm text-slate-500 dark:text-slate-400 mt-1 `}
                        >
                            {/* {shortDescription} */}
                        </p>
                    </div>
                    <div className="md:flex md:items-center md:justify-between">
                        <Prices price={switchCurrency(data)} />
                        <div className="flex pt-2">
                            <ButtonPrimary
                                className="shadow-lg"
                                fontSize="text-xs"
                                sizeClass="py-2 px-4"
                                onClick={() => {
                                    notifyAddTocart({ size: '' });
                                    addOneProductToCart({
                                        id: data.id,
                                        product: data,
                                        quantity: 1
                                    });
                                }}
                            >
                                {' '}
                                Comprar
                                <BagIcon className="w-3.5 h-3.5 mb-0.5 mx-1" />
                            </ButtonPrimary>
                            <ButtonSecondary
                                className="ml-1.5 bg-white hover:!bg-gray-100 hover:text-slate-900 transition-colors shadow-lg border border-gray-600 dark:border-slate-700"
                                fontSize="text-xs"
                                sizeClass="py-2 px-4"
                                onClick={() => setShowModalQuickView(true)}
                            >
                                <ArrowsPointingOutIcon className="w-3.5 h-3.5" />
                            </ButtonSecondary>
                        </div>
                    </div>
                </div>
            </div>

            {/* QUICKVIEW */}
            <ModalQuickView
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
                data={data}
            />
        </>
    );
};

export default ProductCard;
