import React, { createContext, useContext, useEffect, useState } from 'react';
import { Item } from './CartContext';
import { AuthService } from 'services/auth';
import saveOrUpdateToken, { deleteToken } from 'utils/authorizarionToken';
import { EnderecoService } from 'services/endereco';
import notifyToaster from 'shared/Toaster/NotifyToaster';

interface IUser {
  fullName: string;
  email: string;
  dateOfBirth: string;
  address: string;
  gender: 'Male' | 'Female' | 'Other';
  phone: string;
  about: string;
  avatar?: string;
}

interface IOrder {
  id: number;
  date: string;
  total: number;
  items: Item[];
}
type ProfileContextType = {
  user: IUser | undefined;
  zipCode: string;
  changeZipCode: (zipCode: string) => void;
  address: string;
  changeUser: (user: IUser | undefined) => void;
  saveList: Item[];
  addToSaveList: (item: Item) => void;
  removeToSaveList: (id: number) => void;
  orderList: IOrder[];
  cart: Item[];
  loadProfile: () => void;
};

// Mock data for IUser
const mockUser: IUser = {
  fullName: 'John Doe',
  email: 'teste@example.com',
  dateOfBirth: '1997-03-30',
  address: '123 Main St',
  gender: 'Male',
  phone: '123-456-7890',
  about: 'Lorem ipsum dolor sit amet'
};

// Mock data for IOrder
const mockOrder: IOrder = {
  id: 1,
  date: '2022-01-01',
  total: 100,
  items: []
};

const mockItem: Item = {
  id: 1,
  product: {
    id: 0,
    name: '',
    price: 0,
    image: '',
    description: '',
    category: '',
    tags: [],
    link: '/product-detail/',
    images: [],
    price_venda_dolar: 0,
    price_venda_euro: 0
  },
  quantity: 1
};

// Mock data for ProfileContextType
const mockProfileContext: ProfileContextType = {
  user: mockUser,
  zipCode: '64000-000',
  address: '123 Main St',
  changeZipCode: () => {},
  changeUser: () => {},
  saveList: [mockItem],
  addToSaveList: () => {},
  removeToSaveList: () => {},
  orderList: [mockOrder],
  cart: [mockItem],
  loadProfile: () => {}
};

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);
export const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [user, setUser] = useState<IUser>();
  const [saveList, setSaveList] = useState<Item[]>([]);
  const [orderList, setOrderList] = useState<IOrder[]>([]);
  const [cart, setCart] = useState<Item[]>([]);
  const [zipCode, setZipCode] = useState('');
  const [address, setAdress] = useState('');
  let isLoaded = true;

  const addToSaveList = (item: Item) => {
    setSaveList([...saveList, item]);
  };

  const removeToSaveList = (id: number) => {
    setSaveList(saveList.filter((item) => item.id !== id));
  };

  const loadProfile = () => {
    // TODO: Busca no endpoint os dados do usuário
    AuthService.refresh()
      .then((response) => {
        setUser({
          fullName: response.data.user.name,
          email: response.data.user.email,
          dateOfBirth: Date.now().toString(),
          address: 'Teresina, PI',
          gender: 'Male',
          phone: '',
          about: 'Teste de atualização do usuário'
        });
        saveOrUpdateToken(response.data.authorization.token);
      })
      .catch(() => {
        deleteToken();
      })
      .finally(() => {
        isLoaded = true;
      });
    const saveList = mockProfileContext.saveList;
    const orderList = mockProfileContext.orderList;
    const cart = mockProfileContext.cart;

    if (user) {
      setUser(user);
    }
    if (saveList) {
      setSaveList(saveList);
    }
    if (orderList) {
      setOrderList(orderList);
    }
    if (cart) {
      setCart(cart);
    }
  };
  
  const handleChangeZipCode = (zipCode: string) => {
    zipCode
      ? EnderecoService.get({ cep: zipCode.replace(/\D/g, '') })
          .then(({ data }) => {
            setZipCode(zipCode);
            setAdress(
              `${data.logradouro ? data.logradouro + ',' : ''} ${data.cep} - ${data.localidade} - ${data.uf}`
            );
            localStorage.setItem('zipcode', zipCode);
          })
          .catch(() => {
            notifyToaster('error', { message: 'CEP não encontrado' });
            setAdress('');
            setZipCode('');
            localStorage.setItem('zipcode', '');
          })
      : setZipCode('');
  };

  useEffect(() => {
    const storedZipCode = localStorage.getItem('zipcode');
    const localToken = document.cookie
      .split('; ')
      .find((row) => row.startsWith('token='));
    if (isLoaded && localToken) {
      isLoaded = false;
      loadProfile();
    }
    if (storedZipCode) {
      setZipCode(storedZipCode);
      handleChangeZipCode(storedZipCode);
    }
  }, []);

  return (
    <ProfileContext.Provider
      value={{
        zipCode,
        changeZipCode: handleChangeZipCode,
        user,
        changeUser: setUser,
        address,
        saveList,
        addToSaveList,
        removeToSaveList,
        orderList,
        cart,
        loadProfile
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error(
      'useLanguage deve ser utilizado dentro de um LanguageProvider'
    );
  }
  return context;
};
