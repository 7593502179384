import { useProfile } from 'context/ProfileContext';
import React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export interface CommonLayoutProps {
    children?: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
    const { t } = useTranslation();
    const { user } = useProfile();
    return (
        <div className="nc-CommonLayoutProps container">
            <div className="mt-14 sm:mt-20">
                <div className="max-w-4xl mx-auto">
                    <div className="max-w-2xl">
                        <h2 className="text-3xl xl:text-4xl font-semibold">
                            {t(
                                `Containers.AccountPage.CommonLayout.labelHeaderName`
                            )}
                        </h2>
                        {user && Object.keys(user).length !== 0 && (
                            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-base sm:text-lg">
                                <span className="text-slate-900 dark:text-slate-200 font-semibold">
                                    {user.fullName.charAt(0).toUpperCase() +
                                        user.fullName.slice(1)}
                                    ,
                                </span>{' '}
                                {user.email.charAt(0).toUpperCase() +
                                    user.email.slice(1)}{' '}
                                · {user.address}
                            </span>
                        )}
                    </div>
                    <hr className="mt-10 border-slate-200 dark:border-slate-700"></hr>

                    <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
                        {[
                            {
                                name: t(
                                    `Containers.AccountPage.CommonLayout.labelAccountInfo`
                                ),
                                link: '/account'
                            },
                            {
                                name: t(
                                    `Containers.AccountPage.CommonLayout.labelSaveList`
                                ),
                                link: '/account-savelists'
                            },
                            {
                                name: t(
                                    `Containers.AccountPage.CommonLayout.labelMyOrders`
                                ),
                                link: '/account-my-order'
                            },
                            {
                                name: t(
                                    `Containers.AccountPage.CommonLayout.labelChangePass`
                                ),
                                link: '/account-change-password'
                            },
                            {
                                name: t(
                                    `Containers.AccountPage.CommonLayout.labelBilling`
                                ),
                                link: '/account-billing'
                            }
                        ].map((item, index) => (
                            <NavLink
                                key={index}
                                to={item.link}
                                className={({ isActive }) =>
                                    `block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0  text-sm sm:text-base ${
                                        isActive
                                            ? 'border-primary-500 font-medium text-slate-900 dark:text-slate-200'
                                            : 'text-slate-500 dark:text-slate-400 hover:text-slate-800 dark:hover:text-slate-200'
                                    }`
                                }
                            >
                                {item.name}
                            </NavLink>
                        ))}
                    </div>
                    <hr className="border-slate-200 dark:border-slate-700"></hr>
                </div>
            </div>
            <div className="max-w-4xl mx-auto pt-14 sm:pt-26 pb-24 lg:pb-32">
                {children}
            </div>
        </div>
    );
};

export default CommonLayout;
