import React, { FC, useEffect, useId, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Pagination from 'shared/Pagination/Pagination';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ProductCard from 'components/ProductCard';
import TabFilters from './TabFilters';
import { useTranslation } from 'react-i18next';
import { Product, PRODUCTS } from 'data/data';
import { useProdutos, useProdutosSearch } from 'hooks/useQuery';

export interface ICategoriesType {
    1: 'Finalizadores';
    2: 'Tratamento';
    3: 'Transformação';
    4: 'Equipamentos';
    5: 'Acessórios';
    6: 'Móveis';
    7: 'Ana Autêntic';
    8: 'Home Care';
}

export interface PageCollectionProps {
    className?: string;
    data?: Product[];
}

const PageCollection: FC<PageCollectionProps> = ({
    className = '',
    data = PRODUCTS
}) => {
    const id = useId();
    const { t } = useTranslation();
    const { data: product = [] } = useProdutos();
    const [items, setItems] = useState<Product[]>(data);
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
    const [isfilteredOrderProduct, setFilteredOrderProduct] = useState<
        Product[]
    >([]);
    const [isOrderProduct, setOrderProduct] = useState<Product[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [sortOrder, setSortOrder] = useState<string>('');

    const [isClear, setClear] = useState<boolean>(true);

    useEffect(() => {
        if (product) {
            setItems(product);
        }
    }, [product]);

    const itemsPerPage = 12;
    const [currentPage, setCurrentPage] = useState(1); //inital page

    const handleChangePage = (page: number) => {
        setCurrentPage(page);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentProducts = product.slice(indexOfFirstItem, indexOfLastItem); //produto  página atual
    const totalPages = Math.ceil((product.length || 0) / itemsPerPage); // qtd páginas necessárias
    const paginationLinks = Array.from({ length: totalPages }, (_, index) => ({
        label: `${index + 1}`,
        url: '#',
        active: index + 1 === currentPage // página atual ativa
    }));

    const handleClearSelect = (isClear: boolean) => {
        setClear(isClear);
    };

    const handleSortChange = (order: string) => {
        setSortOrder(order);

        const sortedProducts = [...product];
        if (order === 'Maior Preço') {
            sortedProducts.sort((a, b) => b.price - a.price); // decrescente
        } else if (order === 'Menor Preço') {
            sortedProducts.sort((a, b) => a.price - b.price); // crescente
        }
        setProducts(sortedProducts); // Atualiza o estado com os produtos ordenados
        setClear(false);
    };

    const handleCategorySelect = (category: string) => {
        console.log('categoria ', category);
        if (category === 'All Categories') {
            setFilteredProducts(product);
        } else {
            const filtered = product
                .filter((product) => product.category === category)
                .slice(indexOfFirstItem, indexOfLastItem);
            setFilteredProducts(filtered);
        }
        setClear(false);
    };

    // paginação produtos filtrados por categoria
    const totalPage = Math.ceil((filteredProducts.length || 0) / itemsPerPage); // qtd páginas necessárias
    const paginationLink = Array.from({ length: totalPage }, (_, index) => ({
        label: `${index + 1}`,
        url: '#',
        active: index + 1 === currentPage // página atual ativa
    }));

    //acompanha a renderização dos produtos filtrados por categoria com ordenação
    useEffect(() => {
        if (filteredProducts.length !== 0) {
            const sortedOrderProducts = [...filteredProducts];

            if (sortOrder === 'Maior Preço') {
                sortedOrderProducts.sort((a, b) => b.price - a.price); // decrescente
            } else if (sortOrder === 'Menor Preço') {
                sortedOrderProducts.sort((a, b) => a.price - b.price); // crescente
            }
            // sortedOrderProducts.slice(indexOfFirstItem, indexOfLastItem);
            setFilteredOrderProduct(
                sortedOrderProducts.slice(indexOfFirstItem, indexOfLastItem)
            );
        }
    }, [filteredProducts, sortOrder]);

    //acompanha a renderização de todos produtos com ordenação
    useEffect(() => {
        if (product.length !== 0) {
            const sortedOrderProducts = [...product];

            if (sortOrder === 'Maior Preço') {
                sortedOrderProducts.sort((a, b) => b.price - a.price); // decrescente
            } else if (sortOrder === 'Menor Preço') {
                sortedOrderProducts.sort((a, b) => a.price - b.price); // crescente
            }
            // sortedOrderProducts.slice(indexOfFirstItem, indexOfLastItem);
            setOrderProduct(
                sortedOrderProducts.slice(indexOfFirstItem, indexOfLastItem)
            );
        }
    }, [product, sortOrder]);

    const totalPageOrder = Math.ceil(
        (isOrderProduct.length || 0) / itemsPerPage
    ); // qtd páginas necessárias
    const paginationLinkOrder = Array.from(
        { length: totalPageOrder },
        (_, index) => ({
            label: `${index + 1}`,
            url: '#',
            active: index + 1 === currentPage // página atual ativa
        })
    );

    const renderProductGrid = (products: Product[]) => (
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
            {products.map((item, index) => (
                <ProductCard data={item} key={index} />
            ))}
        </div>
    );

    const renderEmptyState = () => (
        <div className="h-full">
            <div className="flex flex-col justify-center items-center h-[50vh] text-3xl">
                {t('Containers.PageTabFilters.labelCategoriesPageCollection')}
            </div>
        </div>
    );

    return (
        <div
            className={`nc-PageCollection ${className}`}
            data-nc-id="PageCollection"
        >
            <Helmet>
                <title>AP Professional</title>
            </Helmet>

            <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
                <div className="space-y-10 lg:space-y-14">
                    <hr className="border-slate-200 dark:border-slate-700" />
                    <main>
                        {/* TABS FILTER */}
                        <TabFilters
                            data={product}
                            onCategorySelect={handleCategorySelect}
                            onClearSelect={handleClearSelect}
                            onSortChange={handleSortChange}
                        />
                        <>
                            {product.length === 0 ? (
                                renderEmptyState()
                            ) : (
                                <>
                                    {isClear
                                        ? renderProductGrid(currentProducts)
                                        : filteredProducts.length === 0 &&
                                            product.length === 0 &&
                                            sortOrder
                                          ? renderEmptyState()
                                          : //   : filteredProducts.length === 0 &&
                                            //       product.length !== 0
                                            //       &&
                                            //       sortOrder

                                            //     ? renderProductGrid(isOrderProduct)
                                            //     : filteredProducts.length === 0 &&
                                            //         product.length !== 0
                                            //       ? renderEmptyState()
                                            filteredProducts.length === 0 &&
                                              product.length !== 0
                                            ? renderEmptyState() // Não renderiza nada mesmo se sortOrder estiver ativo
                                            : filteredProducts.length === 0 &&
                                                product.length !== 0
                                              ? sortOrder
                                                  ? renderProductGrid(
                                                        isOrderProduct
                                                    )
                                                  : renderEmptyState()
                                              : filteredProducts.length !== 0
                                                ? sortOrder
                                                    ? renderProductGrid(
                                                          isfilteredOrderProduct
                                                      )
                                                    : renderProductGrid(
                                                          filteredProducts
                                                      )
                                                : filteredProducts.length ===
                                                        0 &&
                                                    product.length !== 0
                                                  ? //    &&
                                                    //   sortOrder
                                                    renderEmptyState()
                                                  : filteredProducts.length !==
                                                          0 && sortOrder
                                                    ? renderProductGrid(
                                                          isfilteredOrderProduct
                                                      )
                                                    : product.length !== 0 &&
                                                        sortOrder
                                                      ? renderProductGrid(
                                                            isOrderProduct
                                                        )
                                                      : product && sortOrder
                                                        ? renderProductGrid(
                                                              isOrderProduct
                                                          )
                                                        : renderProductGrid(
                                                              filteredProducts
                                                          )}

                                    <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                                        {isClear ? (
                                            <Pagination
                                                links={paginationLinks}
                                                onPageChange={handleChangePage}
                                            />
                                        ) : filteredProducts.length !== 0 ? (
                                            <Pagination
                                                links={paginationLink}
                                                onPageChange={handleChangePage}
                                            />
                                        ) : (
                                            <Pagination
                                                links={paginationLinks}
                                                onPageChange={handleChangePage}
                                            />
                                        )}
                                        {/* <ButtonPrimary loading>Mostrar mais</ButtonPrimary> */}
                                    </div>
                                </>
                            )}
                        </>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default PageCollection;
