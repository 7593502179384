import React, { createContext, useContext, useEffect, useState } from 'react';

type ThemeContextType = {
  isDarkMode: boolean;
  setDarkMode: (isDarkMode: boolean) => void;
  toggleDarkMode: () => void;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [isDarkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.theme === 'dark') {
      setDarkMode(true);
      toggleDarkMode();
    }
  }, []);

  const toggleDarkMode = () => {
    if (!isDarkMode) {
      const root = document.querySelector('html');
      if (!root) return;
      !root.classList.contains('dark') && root.classList.add('dark');
      localStorage.theme = 'dark';
      setDarkMode(true);
    } else {
      const root = document.querySelector('html');
      if (!root) return;
      root.classList.remove('dark');
      localStorage.theme = 'light';
      setDarkMode(false);
    }
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, setDarkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme deve ser utilizado dentro de um ThemeProvider');
  }
  return context;
};
