import axios from 'axios';
import { AxiosResponse } from 'axios';
import {
    IProduto,
    IProdutosAllProps,
    IProdutosByIdProps,
    IProdutosResult
} from '../produtos/types';

import { axiosApi as FaroviteApi } from '../index';

const baseUrlFavorite = `https://api.approsystem.com.br/api/v1/clientes`;

export class FavoriteService {
    static async getAll(
        props: IProdutosByIdProps
    ): Promise<AxiosResponse<IProduto>> {
        const url = `${baseUrlFavorite}/${props.id}/favoritos`;
        return FaroviteApi.get(url);
    }
}
