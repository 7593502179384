import React, { useRef, useState, FC } from 'react';

import Prices from 'components/Prices';
import { PRODUCTS, Product } from 'data/data';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import CommonLayout from './CommonLayout';
import { useTranslation } from 'react-i18next';
import { useProdutoById, useProdutos } from 'hooks/useQuery';
import { Imagen } from 'services/produtos/types';

const AccountOrder = () => {
    const { t } = useTranslation();
    const id1 = '2';
    const id2 = '6';
    const id3 = '10';
    const id4 = '20';

    const { data: product1 } = useProdutoById(id1);
    const { data: product2 } = useProdutoById(id2);
    const { data: product3 } = useProdutoById(id3);
    const { data: product4 } = useProdutoById(id4);

    const data1 = PRODUCTS[id1];
    const data3 = PRODUCTS[id3];
    const data2 = PRODUCTS[id2];
    const data4 = PRODUCTS[id4];

    const statusList = [
        'Pedido Efetuado - AC',
        'Pedido efetuado',
        'Cancelado',
        'Entregue',
        'Enviado',
        'Pago',
        'Em produção',
        'Em separação',
        'Pronto pra retirada',
        'Aguardando pgto',
        'Pgto devolvido',
        'Pgto em análise',
        'Pgto em chargeback',
        'Pgto em disputa'
    ];

    const { name, status, image, price } = data1;
    const {
        name: name2,
        status: status2,
        image: image2,
        price: price2
    } = data2;

    const {
        name: name3,
        status: status3,
        image: image3,
        price: price3
    } = data3;

    const {
        name: name4,
        status: status4,
        image: image4,
        price: price4
    } = data4;

    // console.log(data1);

    const fakeData = [
        {
            id: 1,
            idOrder: 'fb88b7ce-d493-430f-9dab-19c0c24d6019',
            dateOrder: '2024-03-06',
            statusOrder: 'Entregue',
            data: [
                {
                    id: 1,
                    itemOrder: name,
                    itemImg: image,
                    valueOrder: price
                },
                {
                    id: 2,

                    itemOrder: name4,
                    itemImg: image4,
                    valueOrder: price4
                },
                {
                    id: 3,
                    itemOrder: name3,
                    itemImg: image3,
                    valueOrder: price3
                }
            ]
        },
        {
            id: 2,
            idOrder: 'a198d317-f4be-496a-a02e-6e06fa7c1104',
            dateOrder: '2024-03-08',
            statusOrder: 'Pagamento em análise',
            itemOrder: name2,
            itemImg: image2,
            valueOrder: price2
        }
    ];

    const renderProductItem = (image: string, name: string, price: number) => {
        return (
            <div className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
                <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                    <img
                        src={image}
                        alt={name}
                        className="h-full w-full object-cover object-center"
                    />{' '}
                </div>

                <div className="ml-4 flex flex-1 flex-col">
                    <div>
                        <div className="flex justify-between ">
                            <div>
                                <h3 className="text-base font-medium line-clamp-1">
                                    {name}
                                </h3>
                                <p className="text-gray-500 dark:text-slate-400 flex items-center">
                                    <span className="hidden sm:inline-block">
                                        Qty
                                    </span>
                                    <span className="inline-block sm:hidden">
                                        x
                                    </span>
                                    <span className="ml-2">1</span>
                                </p>
                            </div>
                            <Prices price={price} className="mt-0.5 ml-2" />
                        </div>
                    </div>

                    <div className="flex flex-1 items-end justify-between text-sm">
                        <div className="flex">
                            <button
                                type="button"
                                className="font-medium text-indigo-600 dark:text-primary-500 "
                            >
                                {t(
                                    'Containers.AccountPage.AccountOrder.labelLeaveReview'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderOrder = () => {
        return (
            <>
                {fakeData.map((item, index) => (
                    <div key={index}>
                        {item.data ? (
                            <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
                                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
                                    <div>
                                        <p className="text-lg font-semibold">
                                            {item.idOrder}
                                        </p>
                                        <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
                                            <span>{item.dateOrder}</span>
                                            <span className="mx-2">·</span>
                                            <span className="text-primary-500">
                                                {item.statusOrder}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="mt-3 sm:mt-0">
                                        <ButtonSecondary
                                            sizeClass="py-2.5 px-4 sm:px-6"
                                            fontSize="text-sm font-medium"
                                        >
                                            {t(
                                                'Containers.AccountPage.AccountOrder.labelViewOrder'
                                            )}
                                        </ButtonSecondary>
                                    </div>
                                </div>
                                <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
                                    {item.data.map((nestedItem) =>
                                        renderProductItem(
                                            nestedItem.itemImg,
                                            nestedItem.itemOrder,
                                            nestedItem.valueOrder
                                        )
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
                                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
                                    <div>
                                        <p className="text-lg font-semibold">
                                            {item.idOrder}
                                        </p>
                                        <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
                                            <span>{item.dateOrder}</span>
                                            <span className="mx-2">·</span>
                                            <span className="text-primary-500">
                                                {item.statusOrder}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="mt-3 sm:mt-0">
                                        <ButtonSecondary
                                            sizeClass="py-2.5 px-4 sm:px-6"
                                            fontSize="text-sm font-medium"
                                        >
                                            {t(
                                                'Containers.AccountPage.AccountOrder.labelViewOrder'
                                            )}
                                        </ButtonSecondary>
                                    </div>
                                </div>
                                <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
                                    {renderProductItem(
                                        item.itemImg,
                                        item.itemOrder,
                                        item.valueOrder
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </>
        );
    };

    return (
        <div>
            <CommonLayout>
                <div className="space-y-10 sm:space-y-12">
                    {/* HEADING */}
                    <h2 className="text-2xl sm:text-3xl font-semibold">
                        {t(
                            'Containers.AccountPage.AccountOrder.labelHeaderName'
                        )}
                    </h2>
                    {renderOrder()}
                </div>
            </CommonLayout>
        </div>
    );
};

export default AccountOrder;
