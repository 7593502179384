import React, { useState, useEffect, FC } from 'react';
import ProductCard from 'components/ProductCard';
import { Product } from 'data/data';
import CommonLayout from './CommonLayout';
import { useTranslation } from 'react-i18next';
import { useFavorites, useProdutos } from 'hooks/useQuery';
import { transformProdutos } from 'utils/transformToProduct';

const AccountSavelists = () => {
    const { t } = useTranslation();
    const { data: favorites } = useFavorites('85517');
    const { data: products } = useProdutos();
    const [fav, setFav] = useState<Product[]>([]);

    useEffect(() => {
        if (favorites?.data && Array.isArray(favorites.data) && products) {
            const favoriteProducts = favorites.data
                .filter((favorite) => {
                    // Verifica se o ID do produto dentro de favoritos existe na lista de produtos
                    return products.some(
                        (produto) => produto.id === favorite.produto_id
                    );
                })
                .map((favorite) => {
                    // Retorna o objeto produto dentro de `favorites`
                    return favorite.produto;
                });

            // realiza a transformação dos produtos para o formato de Products[]
            const formatedData = transformProdutos(favoriteProducts);
            setFav(formatedData);
        }
    }, [favorites, products]);

    const renderSection1 = () => {
        return (
            <div className="space-y-10 sm:space-y-12 mb-40">
                <div>
                    <h2 className="text-2xl sm:text-3xl font-semibold">
                        {t(
                            'Containers.AccountPage.AccountSavelist.labelHeaderName'
                        )}
                    </h2>
                </div>

                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 ">
                    {fav.map((item) => (
                        <ProductCard key={item.id} data={item} />
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div>
            <CommonLayout>{renderSection1()}</CommonLayout>
        </div>
    );
};

export default AccountSavelists;
