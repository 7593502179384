import React, { FC, useEffect, useId, useRef } from 'react';
import Heading from 'components/Heading/Heading';
import Glide from '@glidejs/glide';
import ProductCard from './ProductCard';
import { Product, PRODUCTS } from 'data/data';
// import { productImgs } from "contains/fakeData";
import { useTranslation } from 'react-i18next';
import { useProdutos } from 'hooks/useQuery';

export interface SectionSliderProductCardProps {
    className?: string;
    itemClassName?: string;
    heading?: string;
    headingFontClassName?: string;
    headingClassName?: string;
    subHeading?: string;
    data?: Product[];
}

const SectionSliderProductCard: FC<SectionSliderProductCardProps> = ({
    className = '',
    itemClassName = '',
    headingFontClassName,
    headingClassName,
    heading,
    subHeading = 'Produtos com preços imperdíveis',
    data = PRODUCTS.filter((_, i) => i < 8 && i > 2)
}) => {
    const sliderRef = useRef(null);
    const id = useId();
    const { t } = useTranslation();
    const UNIQUE_CLASS = 'glidejs' + id.replace(/:/g, '_');

    const { data: products } = useProdutos();

    useEffect(() => {
        let slider: Glide;
        if (products && products.length > 0) {
            const OPTIONS = {
                perView: 4,
                gap: 32,
                bound: true,
                breakpoints: {
                    1280: {
                        perView: 4 - 1
                    },
                    1024: {
                        gap: 20,
                        perView: 4 - 1
                    },
                    768: {
                        gap: 20,
                        perView: 4 - 2
                    },
                    640: {
                        gap: 20,
                        perView: 1.5
                    },
                    500: {
                        gap: 20,
                        perView: 2.4
                    }
                }
            };

            slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
            slider.mount();
        }

        return () => {
            if (slider) {
                slider.destroy();
            }
        };
    }, [products, UNIQUE_CLASS]);

    subHeading = `${t('Components.SectionSliderProductCard.labelProductCardsubHeading')}`;

    return (
        <div className={`nc-SectionSliderProductCard ${className}`}>
            <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
                <Heading
                    className={headingClassName}
                    fontClass={headingFontClassName}
                    rightDescText={subHeading}
                    hasNextPrev
                >
                    {heading || t('SectionSliderProductCard.labelHighlight')}
                </Heading>
                <div className="glide__track md:mb-10" data-glide-el="track">
                    <ul className="glide__slides">
                        {products &&
                            products.map((item, index) => (
                                <li
                                    key={index}
                                    className={`glide__slide ${itemClassName}`}
                                >
                                    <ProductCard data={item} />
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SectionSliderProductCard;
