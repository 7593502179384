import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface ExpiredOrderProps {
    className?: string;
    onNext?: () => void;
    type?: string;
}

const ExpiredOrder: FC<ExpiredOrderProps> = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleRedirect = () => {
        console.log('here');
    };

    return (
        <div className="h-[50vh]">
            <main className="block max-h-[65vh] flex-col items-center justify-between px-4">
                <div className="container mx-auto px-4">
                    <div className="w-full h-full justify-center flex flex-col items-center md:mt-10 py-10">
                        <h1 className="fs-3x text-[24px] md:text-[55px] mt-2 mb-2 md:mt-12 font-sans text-red-600 font-bold">
                            Oops!
                        </h1>
                        <h6 className="text-[24px] md:text-[55px] mt-0 mb-8 md:mt-12 font-sans text-black font-bold">
                            {t(
                                'Containers.PageCheckout.ExpiredOrder.labelExpiredPix'
                            )}
                        </h6>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            width="100"
                            height="100"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-18"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.182 16.318A4.486 4.486 0 0 0 12.016 15a4.486 4.486 0 0 0-3.198 1.318M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z"
                            />
                        </svg>

                        <p className="text-base md:text-lg max-w-[620px] text-center font-sans text-black font-semibold mt-4 md:mt-8">
                            {t(
                                'Containers.PageCheckout.ExpiredOrder.labelPurchaseNotCompleteInit'
                            )}
                            <br />

                            {t(
                                'Containers.PageCheckout.ExpiredOrder.labelPurchaseNotCompleteMiddle'
                            )}
                            <br />
                            <br />

                            {t(
                                'Containers.PageCheckout.ExpiredOrder.labelPurchaseNotCompleteEnd'
                            )}
                        </p>
                        <button
                            onClick={() => navigate('/cart')}
                            className="py-[14px] w-[60%] px-3 text-white font-sans text-base font-semibold rounded-3xl flex items-center justify-center gap-2 hover:brightness-70 duration-300 relative bg-black mt-10 disabled:cursor-not-allowed"
                        >
                            {t(
                                'Containers.PageCheckout.ExpiredOrder.labelTryAgain'
                            )}
                        </button>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ExpiredOrder;
