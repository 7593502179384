import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Page } from './types';
import ScrollToTop from './ScrollToTop';
import Footer from 'shared/Footer/Footer';
import PageHome from 'containers/PageHome/PageHome';
import Page404 from 'containers/Page404/Page404';
import AccountPage from 'containers/AccountPage/AccountPage';
import PageContact from 'containers/PageContact/PageContact';
import PageSignUp from 'containers/PageSignUp/PageSignUp';
import PageLogin from 'containers/PageLogin/PageLogin';
import PageSubcription from 'containers/PageSubcription/PageSubcription';
import BlogPage from 'containers/BlogPage/BlogPage';
import SiteHeader from 'containers/SiteHeader';
import PageCollection from 'containers/PageCollection';
import PageSearch from 'containers/PageSearch';
import PageHome2 from 'containers/PageHome/PageHome2';
import ProductDetailPage from 'containers/ProductDetailPage/ProductDetailPage';
import ProductDetailPage2 from 'containers/ProductDetailPage/ProductDetailPage2';
import AccountSavelists from 'containers/AccountPage/AccountSavelists';
import AccountPass from 'containers/AccountPage/AccountPass';
import AccountBilling from 'containers/AccountPage/AccountBilling';
import AccountOrder from 'containers/AccountPage/AccountOrder';
import CartPage from 'containers/ProductDetailPage/CartPage';
import CheckoutPage from 'containers/PageCheckout/CheckoutPage';
import PageCollection2 from 'containers/PageCollection2';
import { QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from 'context/ThemeContext';
import { LanguageProvider } from 'context/LanguageContext';
import { CurrencyProvider } from 'context/CurrencyContext';
import { CartProvider } from 'context/CartContext';
import { ProfileProvider } from 'context/ProfileContext';
import queryClient from 'services/querys';
import PageForgotPassword from 'containers/PageForgotPassword/PageForgotPassword';
import PageResetPassword from 'containers/PageResetPassword/PageResetPassword';
import SectionAnaPage from 'components/SectionGridMoreExplore/SectionGridAnaPage';
import SectionTransformationPage from 'components/SectionGridMoreExplore/SectionTransformationPage';
import SectionTreatmentPage from 'components/SectionGridMoreExplore/SectionTreatmentPage';
import SectionFinishingPage from 'components/SectionGridMoreExplore/SectionFinishingPage';
import SectionHomeCarePage from 'components/SectionGridMoreExplore/SectionHomeCarePage';
import SectionAccessoriesPage from 'components/SectionGridMoreExplore/SectionAccessoriesPage';
import SectionEquipamentPage from 'components/SectionGridMoreExplore/SectionEquipamentPage';
import SectionFurniturePage from 'components/SectionGridMoreExplore/SectionFurniturePage';
import OrderConfirmation from 'containers/PageCheckout/Cards/OrderConfirmation';

export const pages: Page[] = [
  { path: '/', component: PageHome },
  { path: '/home2', component: PageHome2 },
  //
  { path: '/home-header-2', component: PageHome },
  { path: '/product-detail', component: ProductDetailPage },
  { path: '/product-detail-2/:id', component: ProductDetailPage2 },
  { path: '/produto/:id', component: ProductDetailPage },
  //
  { path: '/page-collection-2', component: PageCollection2 },
  { path: '/page-collection', component: PageCollection },

  //
  { path: '/page-ana-autentic', component: SectionAnaPage },
  { path: '/page-transformation', component: SectionTransformationPage },
  { path: '/page-treatment', component: SectionTreatmentPage },
  { path: '/page-finishing', component: SectionFinishingPage },
  { path: '/page-home-care', component: SectionHomeCarePage },
  { path: '/page-equipament', component: SectionEquipamentPage },
  { path: '/page-accessories', component: SectionAccessoriesPage },
  { path: '/page-forniture', component: SectionFurniturePage },

  //

  { path: '/busca', component: PageSearch },
  //
  { path: '/account', component: AccountPage },
  { path: '/account-savelists', component: AccountSavelists },
  { path: '/account-change-password', component: AccountPass },
  { path: '/account-billing', component: AccountBilling },
  { path: '/account-my-order', component: AccountOrder },
  //
  { path: '/cart', component: CartPage },
  { path: '/checkout', component: CheckoutPage },
  { path: '/confirm-order', component: OrderConfirmation },

  //
  { path: '/blog', component: BlogPage },
  //
  { path: '/contact', component: PageContact },
  { path: '/signup', component: PageSignUp },
  { path: '/login', component: PageLogin },
  { path: '/subscription', component: PageSubcription },
  { path: '/forgot-pass', component: PageForgotPassword },
  { path: '/reset-password/:token', component: PageResetPassword }
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <ProfileProvider>
          <CurrencyProvider>
            <LanguageProvider>
              <CartProvider>
                <QueryClientProvider client={queryClient}>
                  <Toaster />
                  <ScrollToTop />
                  <SiteHeader />
                  <Routes>
                    {pages.map(({ component: Component, path }, index) => {
                      return (
                        <Route
                          key={index}
                          element={<Component />}
                          path={path}
                        />
                      );
                    })}
                    <Route element={<Page404 />} />
                  </Routes>
                </QueryClientProvider>
              </CartProvider>
            </LanguageProvider>
          </CurrencyProvider>
        </ProfileProvider>
      </ThemeProvider>
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
