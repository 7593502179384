import { AxiosResponse } from 'axios';
import { IFrete, IFreteProps } from './types';

import { axiosApi as freteApi } from '../index';

const baseUrlFrete = '/api/v1/frete';

export class FreteService {
  static async get(props?: IFreteProps): Promise<AxiosResponse<IFrete[]>> {
    const url = baseUrlFrete;
    return freteApi.get(url, { params: { ...props } });
  }
}
