import { AxiosResponse } from 'axios';
import {
    IProduto,
    IProdutosAllProps,
    IProdutosByIdProps,
    IProdutosResult
} from './types';

import { axiosApi as produtosApi } from '../index';

const baseUrlProdutos = 'https://api.approsystem.com.br/api/v1/produtos';

export class ProdutoService {
    static async getAll(
        props?: IProdutosAllProps
    ): Promise<AxiosResponse<IProdutosResult>> {
        const url = baseUrlProdutos;
        return produtosApi.get(
            'https://api.approsystem.com.br/api/v1/produtos/?per_page=9999999'
        );
    }

    static async getById(
        props: IProdutosByIdProps
    ): Promise<AxiosResponse<IProduto>> {
        const url = `${baseUrlProdutos}/${props.id}`;
        return produtosApi.get(url);
    }
    //   static async save(
    //     data: IProdutosSaveBody
    //   ): Promise<AxiosResponse<number>> {
    //     const url = baseUrlProdutos;
    //     return produtosApi.post(url, data);
    //   }
    //   static async update(
    //     props: IProdutosProps,
    //     data: IProdutosUpdateBody
    //   ): Promise<AxiosResponse<IProdutosDTO>> {
    //     const url = `${baseUrlProdutos}/${props.id}`;
    //     return produtosApi.put(url, data);
    //   }
    //   static async delete(
    //     props: IProdutosProps
    //   ): Promise<AxiosResponse<IProdutosProps>> {
    //     const url = `${baseUrlProdutos}/${props.id}`;
    //     return produtosApi.delete(url, { data: { ...props}});
    //   }
}
