import { Popover, Transition } from '@headlessui/react';
import Prices from 'components/Prices';
import { Item, useCart } from 'context/CartContext';
import { useCurrency } from 'context/CurrencyContext';
// import { PRODUCTS } from "data/data";
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import cartSmile from 'images/cartSmile.svg';
import NcInputNumber from 'components/NcInputNumber';

export default function CartDropdown() {
  const { t } = useTranslation();
  const { currency, switchCurrency } = useCurrency();
  const {
    cart,
    totalPrice,
    removeManyProductToCart,
    addOneProductToCart,
    removeOneProductToCart
  } = useCart();

  const renderProduct = (item: Item, index: number, close: () => void) => {
    const { name, image } = item.product;
    return (
      <div key={index} className="flex py-5 last:pb-0">
        <div className="relative h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl dark:bg-slate-900">
          <img
            src={image}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
          <Link
            onClick={close}
            className="absolute inset-0"
            to={'/produto/' + item.product.id}
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex flex-col gap-2">
                <h3 className="text-base font-medium ">
                  <Link onClick={close} to={'/produto/' + item.product.id}>
                    {name}
                  </Link>
                </h3>
                <NcInputNumber
                  className="relative z-10"
                  defaultValue={item.quantity}
                  handleAddOne={() =>
                    addOneProductToCart({ ...item, quantity: 1 })
                  }
                  handleRemoveOne={() => removeOneProductToCart(item)}
                />
              </div>
              <Prices price={switchCurrency(item.product)} className="mt-0.5" />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-end text-sm">
            {/* <p className="text-gray-500 dark:text-slate-400">{`Qty ${item.quantity}`}</p> */}

            <div className="flex">
              <button
                type="button"
                className="font-medium text-primary-6000 dark:text-primary-500 "
                onClick={() => {
                  removeManyProductToCart([item]);
                }}
              >
                {t('Header.CartDropdown.labelRemoveButton')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`
                ${open ? '' : 'text-opacity-90'}
                 group w-10 h-10 sm:w-12 sm:h-12 text-slate-400 hover:text-slate-50 inline-flex items-center justify-center relative`}
          >
            <div className="w-3.5 h-3.5 flex items-center justify-center bg-primary-500 absolute top-1.5 right-1.5 rounded-full text-[10px] leading-none text-white font-medium">
              <span className="mt-[1px]">{cart.length}</span>
            </div>
            <svg
              className="w-6 h-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 8H21"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <Link className="block md:hidden absolute inset-0" to={'/cart'} />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="hidden md:block absolute z-10 w-screen max-w-xs sm:max-w-md px-4 mt-3.5 -right-28 sm:right-0 sm:px-0">
              <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10">
                <div className="relative bg-white dark:bg-neutral-800">
                  <div className="max-h-[60vh] p-5 overflow-y-auto hiddenScrollbar">
                    <h3 className="text-xl font-semibold">
                      {t('Header.CartDropdown.labelHeaderName')}{' '}
                    </h3>
                    <div className="divide-y divide-slate-100 dark:divide-slate-700">
                      {/* {[PRODUCTS[0], PRODUCTS[1], PRODUCTS[2], PRODUCTS[3]].map( */}
                      {cart.length > 0 ? (
                        cart.map((item, index) =>
                          renderProduct(item, index, close)
                        )
                      ) : (
                        <div className="flex flex-col justify-center text-center gap-4">
                          <div className="flex justify-center">
                            <img
                              className="flex-shrink-0 h-24 w-24 dark:invert opacity-70"
                              src={cartSmile}
                              alt={'Empyt cart'}
                            />
                          </div>
                          <h3 className="text-lg font-semibold ">
                            {t(`Header.CartDropdown.labelEmpytCart`)}
                          </h3>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bg-neutral-50 dark:bg-slate-900 p-5">
                    <p className="flex justify-between font-semibold text-slate-900 dark:text-slate-100">
                      <span>
                        <span>Subtotal</span>
                        <span className="block text-sm text-slate-500 dark:text-slate-400 font-normal">
                          {t('Header.CartDropdown.labelCartMsgTaxes')}
                        </span>
                      </span>
                      <span className="text-blue-700 tracking-widest">
                        {currency}
                        {totalPrice}
                      </span>
                    </p>
                    <div className="flex space-x-2 mt-5">
                      <ButtonSecondary
                        href="/cart"
                        className="flex-1 border border-slate-200 dark:border-slate-700"
                        onClick={close}
                      >
                        {t('Header.CartDropdown.labelCartView')}
                      </ButtonSecondary>
                      <ButtonPrimary
                        href="/checkout"
                        onClick={() => {
                          // TODO: Add product to cart
                          // addOneProductToCart({
                          //   id: 1,
                          //   product: PRODUCTS[2],
                          //   quantity: 1,
                          // })
                        }}
                        className="flex-1"
                      >
                        {t('Header.CartDropdown.labelCartCheckout')}
                      </ButtonPrimary>
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
