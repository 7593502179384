import React, { FC } from 'react';

export interface NewsletterProps {}

const Newsletter: FC<NewsletterProps> = () => {
  return (
    <div className="">
      <form className="flex flex-col items-baseline justify-center gap-2 w-full md:flex-row">
        <label
          form="email"
          className="flex text-sm font-medium text-gray-900 dark:text-gray-400 md:m-0"
        >
          Receba ofertas e novidades da APPRO
        </label>
        <input
          type="email"
          id="email"
          placeholder="Informe seu email"
          className="bg-white text-gray-900 md:w-64 text-sm rounded-full w-full p-2.5 focus:border-none focus:shadow-xl dark:bg-white dark:border-gray-300 dark:placeholder-gray-500 dark:text-gray-800"
          required
        />
        <button
          type="submit"
          className="text-white bg-black hover:bg-gray-800 font-medium rounded-full text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-transparent dark:border-2 dark:hover:bg-gray-200 dark:hover:text-gray-700"
        >
          Quero assinar
        </button>
      </form>
    </div>
  );
};

export default Newsletter;
