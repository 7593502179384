import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import Prices from 'components/Prices';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ContactInfo from './ContactInfo';
import PaymentMethod from './PaymentMethod';
import ShippingAddress from './ShippingAddress';
import { CurrencyOptions, useCurrency } from 'context/CurrencyContext';
import { Item, useCart } from 'context/CartContext';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import NcInputNumber from 'components/NcInputNumber';
import ExpiredOrder from './Cards/expiredOrder';
import PixMethod from './Cards/PaymentPix';
import CreditMethod from './Cards/CreditMethod';
import OrderConfirmation from './Cards/OrderConfirmation';

export interface IContactInfoState {
    name: string;
    cpf: string;
    phoneNumber: string;
    email: string;
    receiveOffers: boolean;
}

export interface IShippingAddress {
    country: string;
    postalCode: string;
    address: string;
    aptSuite: string;
    complement: string;
    city: string;
    state: string;
    addressType: 'home' | 'office';
    deliveryTime: string;
}

export interface IPaymentMethod {
    pix?: string;
    cardCpf?: string;
    cardType?: string;
    cardNumber?: string;
    cardName?: string;
    cardSecurityCode?: string;
    cardInstallment?: string;
    cardExpiration?: string;
    saveCard?: boolean;
}

interface ICheckout {
    contactInfo: IContactInfoState;
    shippingAddress: IShippingAddress;
    paymentMethod: IPaymentMethod;
}

export const CHECKOUTDATA: ICheckout = {
    contactInfo: {
        name: '',
        cpf: '',
        phoneNumber: '',
        email: '',
        receiveOffers: true
    },
    shippingAddress: {
        country: 'BRA',
        postalCode: '',
        address: '',
        aptSuite: '',
        complement: '',
        city: '',
        state: '',
        addressType: 'home',
        deliveryTime: ''
    },
    paymentMethod: {
        pix: '',
        cardCpf: '',
        cardType: '',
        cardNumber: '',
        cardName: '',
        cardExpiration: '',
        cardSecurityCode: '',
        cardInstallment: '1x de',
        saveCard: false
    }
};

const CheckoutPage = () => {
    const { t } = useTranslation();
    const { currency, switchCurrency } = useCurrency();
    const [isPay, setIsPay] = useState<boolean>(false);
    const [isDisabledContact, setIsDisabledContact] = useState<boolean>(false);
    const [isDisabledShipping, setIsDisabledShipping] =
        useState<boolean>(false);
    const [isDisabledPayment, setIsDisabledPayment] = useState<boolean>(false);

    const {
        cart,
        totalPrice,
        totalWithDiscounts,
        addOneProductToCart,
        removeOneProductToCart,
        shipping
    } = useCart();
    // const { zipCode, changeZipCode } = useProfile();
    const [isOpenSubmit, setIsOpenSubmit] = useState(false);

    const [errorsTabs, setErrorsTabs] = useState({
        ContactInfo: false,
        ShippingAddress: false,
        PaymentMethod: false
    });

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1022);

    // verifica o tamanho da tela
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 1022);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [checkoutData, setCheckoutData] = useState<ICheckout>({
        contactInfo: {
            name: 'Ranni Samara Arcanjo de Freitas',
            cpf: '123.456.789-00',
            phoneNumber: '+55 (86) 9 9474-9020',
            email: 'ranni@gmail.com',
            receiveOffers: true
        },
        shippingAddress: {
            country: 'BRA',
            postalCode: '64037032',
            address: 'Rua Jornalista Osório Júnior',
            aptSuite: '5348',
            complement: '',
            city: 'Teresina',
            state: 'Piaui',
            addressType: 'home',
            deliveryTime: ''
        },
        paymentMethod: {
            pix: '',
            cardCpf: '',
            cardType: 'Credit-Card',
            cardNumber: '',
            cardName: '',
            cardExpiration: '',
            cardSecurityCode: '',
            cardInstallment: '1x de',
            saveCard: false
        }
    });

    const [tabActive, setTabActive] = useState<
        'ContactInfo' | 'ShippingAddress' | 'PaymentMethod' | 'OrderSummary'
    >('ContactInfo');
    const [errors, setErrors] = useState<Record<string, string>>({});

    const contactInfoSchema = z.object({
        name: z.string().min(1, t('Containers.PageCheckout.labelInvalidName')),
        cpf: z.string().optional(),
        phoneNumber: z
            .string()
            .min(1, t('Containers.PageCheckout.labelInvalidPhoneNumber')),
        email: z.string().email(t('Containers.PageCheckout.labelInvalidEmail')),
        receiveOffers: z.boolean()
    });

    const shippingAddressSchema = z
        .object({
            country: z.string().min(1, 'Country is required'),
            postalCode: z.string().optional(),
            address: z
                .string()
                .min(1, t('Containers.PageCheckout.labelInvalidAddress')),
            aptSuite: z.string().optional(),
            complement: z.string().optional(),
            city: z
                .string()
                .min(1, t('Containers.PageCheckout.labelInvalidCity')),
            state: z
                .string()
                .min(1, t('Containers.PageCheckout.labelInvalidState')),
            addressType: z.enum(['home', 'office'])
        })
        .refine(
            (data) => {
                if (data.country === 'BRA') {
                    return data.postalCode && data.postalCode.length > 0;
                }
                return true;
            },
            {
                message: t('Containers.PageCheckout.labelInvalidPostalCode'),
                path: ['postalCode'] // customize the path to show the error
            }
        );

    const paymentMethodSchemaPix = z.object({
        pix: z.string(),
        cardType: z.string().optional(),
        cardNumber: z.string().optional(),
        cardName: z.string().optional(),
        cardSecurityCode: z.string().optional(),
        cardExpiration: z.string().optional(),
        cardInstallment: z.string().optional(),
        saveCard: z.boolean().optional()
    });

    const paymentMethodSchemaCard = z.object({
        cardType: z.string(),
        pix: z.string().optional(),
        cardCpf: z
            .string()
            .min(1, t('Containers.PageCheckout.labelInvalidCpf')),
        cardNumber: z
            .string()
            .min(1, t('Containers.PageCheckout.labelInvalidCardNumber')),
        cardName: z
            .string()
            .min(1, t('Containers.PageCheckout.labelInvalidCardName')),
        cardSecurityCode: z
            .string()
            .min(1, t('Containers.PageCheckout.labelInvalidCardSecurityCode')),
        cardExpiration: z
            .string()
            .min(1, t('Containers.PageCheckout.labelInvalidcardExpiration'))
            .refine(
                (value) => {
                    const month = parseInt(value, 10);
                    return month >= 1 && month <= 12;
                },
                {
                    message: t(
                        'Containers.PageCheckout.labelInvalidcardExpiration'
                    )
                }
            ),
        cardInstallment: z.string().min(1, 'Selecione o número de parcelas!'),
        saveCard: z.boolean()
    });
    const checkoutSchema = z.object({
        contactInfo: contactInfoSchema,
        shippingAddress: shippingAddressSchema,
        paymentMethodPix: paymentMethodSchemaPix,
        paymentMethodCard: paymentMethodSchemaCard
    });

    const handleScrollToEl = (id: string) => {
        const element = document.getElementById(id);
        setTimeout(() => {
            element?.scrollIntoView({ behavior: 'smooth' });
        }, 80);
    };
    const handleSubmit = () => {
        try {
            console.log('Form is valid:', isOpenSubmit);
            setIsPay(!isPay);
            checkoutSchema.parse(checkoutData);

            // Se a validação passar, prossiga com o envio do formulário
        } catch (e) {
            if (e instanceof z.ZodError) {
                // Se a validação falhar, exiba os erros
                console.error('Validation errors:', e.errors);
            }
        }
    };
    const renderProduct = (item: Item, index: number) => {
        const { image, name } = item.product;

        if (isWideScreen) {
            return (
                <div
                    key={index}
                    className="relative flex py-7 first:pt-0 last:pb-0"
                >
                    <div className="relative h-24 w-14 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                        <img
                            src={image}
                            alt={name}
                            className="h-full w-full object-contain object-center"
                        />
                        <Link
                            to={'/produto/' + item.id}
                            className="absolute inset-0"
                        ></Link>
                    </div>

                    <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
                        <div>
                            <div className="flex justify-between ">
                                <div className="flex-[1.5] ">
                                    <h3 className="text-base font-semibold">
                                        <Link to="/product-detail">{name}</Link>
                                    </h3>
                                    <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                                        <div className="flex items-center space-x-1.5">
                                            <NcInputNumber
                                                className="relative z-10"
                                                defaultValue={item.quantity}
                                                handleAddOne={() =>
                                                    addOneProductToCart({
                                                        ...item,
                                                        quantity: 1
                                                    })
                                                }
                                                handleRemoveOne={() =>
                                                    removeOneProductToCart(item)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="hidden flex-1 sm:flex justify-end">
                                    <Prices
                                        price={switchCurrency(item.product)}
                                        className="mt-0.5"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-auto pt-4 items-end justify-between text-sm">
                            <div className="hidden sm:block text-center relative font-semibold"></div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    key={index}
                    className="relative flex py-7 first:pt-0 last:pb-0"
                >
                    <div className="relative  rounded-full  h-16 w-16 mr-4 flex-shrink-0 overflow-hidden bg-slate-100">
                        <img
                            src={image}
                            alt={name}
                            className="h-full w-full object-contain object-center"
                        />
                        <Link
                            to={'/produto/' + item.id}
                            className="absolute inset-0"
                        ></Link>
                    </div>

                    <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
                        <div>
                            <div className="flex justify-between ">
                                <div className="flex-[1.5] ">
                                    <h3 className="text-base font-semibold">
                                        <Link to="/product-detail">{name}</Link>
                                    </h3>
                                    <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                                        <div className="flex items-center space-x-1.5">
                                            <NcInputNumber
                                                className="relative z-10"
                                                defaultValue={item.quantity}
                                                handleAddOne={() =>
                                                    addOneProductToCart({
                                                        ...item,
                                                        quantity: 1
                                                    })
                                                }
                                                handleRemoveOne={() =>
                                                    removeOneProductToCart(item)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="hidden flex-1 sm:flex justify-end">
                                    <Prices
                                        price={switchCurrency(item.product)}
                                        className="mt-0.5"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-auto pt-4 items-end justify-between text-sm">
                            <div className="hidden sm:block text-center relative font-semibold"></div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const renderLeft = () => {
        return (
            <div className="space-y-8">
                <div
                    id="ContactInfo"
                    className={`scroll-mt-24 rounded-xl ${errorsTabs.ContactInfo ? 'ring-2 ring-red-300 dark:ring-red-500' : 'ring-2 ring-green-200 dark:ring-green-400'}`}
                >
                    <ContactInfo
                        isActive={tabActive === 'ContactInfo'}
                        onOpenActive={() => {
                            setTabActive('ContactInfo');
                            handleScrollToEl('ContactInfo');
                        }}
                        onCloseActive={() => {
                            try {
                                contactInfoSchema.parse(
                                    checkoutData.contactInfo
                                );
                                setErrors({});
                                setErrorsTabs({
                                    ...errorsTabs,
                                    ContactInfo: false
                                });
                                setTabActive('ShippingAddress');
                                handleScrollToEl('ShippingAddress');
                                setIsDisabledContact(true);
                            } catch (e) {
                                setErrorsTabs({
                                    ...errorsTabs,
                                    ContactInfo: true
                                });
                                if (e instanceof z.ZodError) {
                                    const fieldErrors: Record<string, string> =
                                        {};
                                    e.errors.forEach((error) => {
                                        fieldErrors[error.path[0]] =
                                            error.message;
                                    });
                                    setErrors(fieldErrors);
                                    setIsDisabledContact(false);
                                }
                            }
                        }}
                        contact={checkoutData.contactInfo}
                        onChange={(value) =>
                            setCheckoutData({
                                ...checkoutData,
                                contactInfo: value
                            })
                        }
                        errors={errors}
                    />
                </div>

                <div
                    id="ShippingAddress"
                    className={`scroll-mt-24 rounded-xl ${errorsTabs.ShippingAddress ? 'ring-2 ring-red-300 dark:ring-red-500' : 'ring-2 ring-green-200 dark:ring-green-400'}`}
                >
                    <ShippingAddress
                        isActive={tabActive === 'ShippingAddress'}
                        onOpenActive={() => {
                            setTabActive('ShippingAddress');
                            handleScrollToEl('ShippingAddress');
                        }}
                        onCloseActive={() => {
                            try {
                                shippingAddressSchema.parse(
                                    checkoutData.shippingAddress
                                );
                                setErrors({});
                                setErrorsTabs({
                                    ...errorsTabs,
                                    ShippingAddress: false
                                });
                                setTabActive('PaymentMethod');
                                handleScrollToEl('PaymentMethod');
                                setIsDisabledShipping(true);
                            } catch (e) {
                                setErrorsTabs({
                                    ...errorsTabs,
                                    ShippingAddress: true
                                });
                                if (e instanceof z.ZodError) {
                                    const fieldErrors: Record<string, string> =
                                        {};
                                    e.errors.forEach((error) => {
                                        fieldErrors[error.path[0]] =
                                            error.message;
                                    });
                                    setErrors(fieldErrors);
                                    setIsDisabledShipping(false);
                                }
                            }
                        }}
                        shippingAddress={checkoutData.shippingAddress}
                        onChange={(value) =>
                            setCheckoutData({
                                ...checkoutData,
                                shippingAddress: value
                            })
                        }
                        errors={errors}
                    />
                </div>

                <div
                    id="PaymentMethod"
                    className={`scroll-mt-24 rounded-xl ${errorsTabs.PaymentMethod ? 'ring-2 ring-red-300 dark:ring-red-500' : 'ring-2 ring-green-200 dark:ring-green-400'}`}
                >
                    <PaymentMethod
                        isActive={tabActive === 'PaymentMethod'}
                        onOpenActive={() => {
                            setTabActive('PaymentMethod');
                            handleScrollToEl('PaymentMethod');
                        }}
                        onCloseActive={() => {
                            try {
                                if (
                                    checkoutData.paymentMethod.pix ===
                                    'Internet-banking'
                                ) {
                                    paymentMethodSchemaPix.parse(
                                        checkoutData.paymentMethod
                                    );
                                    setErrors({});
                                    setErrorsTabs({
                                        ...errorsTabs,
                                        PaymentMethod: false
                                    });
                                    setTabActive('PaymentMethod');
                                    handleScrollToEl('PaymentMethod');
                                    setIsDisabledPayment(true);
                                } else if (
                                    checkoutData.paymentMethod.cardType ===
                                    'Credit-Card'
                                ) {
                                    paymentMethodSchemaCard.parse(
                                        checkoutData.paymentMethod
                                    );
                                    setErrors({});
                                    setErrorsTabs({
                                        ...errorsTabs,
                                        PaymentMethod: false
                                    });
                                    setTabActive('PaymentMethod');
                                    handleScrollToEl('PaymentMethod');
                                    setIsDisabledPayment(true);
                                }
                            } catch (e) {
                                setErrorsTabs({
                                    ...errorsTabs,
                                    PaymentMethod: true
                                });
                                if (e instanceof z.ZodError) {
                                    console.log(e.errors);
                                    const fieldErrors: Record<string, string> =
                                        {};
                                    e.errors.forEach((error) => {
                                        fieldErrors[error.path[0]] =
                                            error.message;
                                    });
                                    setErrors(fieldErrors);
                                    setIsDisabledPayment(false);
                                }
                            }
                        }}
                        payment={checkoutData.paymentMethod}
                        onChange={(value) =>
                            setCheckoutData({
                                ...checkoutData,
                                paymentMethod: value
                            })
                        }
                        errors={errors}
                    />
                </div>
            </div>
        );
    };

    const OrderSummaryMobile = () => {
        return (
            <div>
                <div className="w-full lg:w-[36%] border-t-2 border-l-2 border-r-2 border-b-2 rounded-xl mb-8">
                    <div className="flex flex-col sm:flex-row items-start px-6 py-6">
                        <div className="w-full flex justify-between items-baseline">
                            <h3 className="text-lg font-semibold">
                                {t('Containers.PageCheckout.labelOrderSummary')}
                            </h3>
                            <div>
                                <span className="mr-2 font-bold">
                                    {currency}
                                    {totalWithDiscounts}
                                </span>
                                <ButtonSecondary
                                    sizeClass="py-2 px-4"
                                    fontSize="text-sm font-medium"
                                    className="bg-slate-50 dark:bg-slate-800   !rounded-lg"
                                    onClick={toggleExpansion}
                                >
                                    {isExpanded ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-4 h-4 text-slate-600 dark:text-slate-400"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="m4.5 15.75 7.5-7.5 7.5 7.5"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-4 h-4 text-slate-600 dark:text-slate-400"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="m19.5 8.25-7.5 7.5-7.5-7.5"
                                            />
                                        </svg>
                                    )}
                                </ButtonSecondary>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`border-t border-slate-200 dark:border-slate-700 ${isExpanded ? 'max-h-[auto]' : 'max-h-0 overflow-hidden'}`}
                    >
                        <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 px-6 py-6">
                            {cart.map(renderProduct)}
                        </div>
                        <div className="mt-8 pt-6 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700  px-6 py-6">
                            <div className="mt-4 flex justify-between py-2.5">
                                <span>
                                    {t(
                                        'Containers.PageCheckout.labelShippingEstimate'
                                    )}
                                </span>
                                <span className="font-semibold text-slate-900 dark:text-slate-200">
                                    {currency}
                                    {new Intl.NumberFormat('en-US', {
                                        minimumFractionDigits: 2
                                    }).format(shipping)}
                                </span>
                            </div>
                            <div className="flex justify-between py-2.5">
                                <span>
                                    {t('Containers.PageCheckout.labelSubtotal')}
                                </span>
                                <span className="font-semibold text-slate-900 dark:text-slate-200">
                                    {currency}
                                    {totalPrice}
                                </span>
                            </div>
                            <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                                <span>
                                    {t(
                                        'Containers.PageCheckout.labelOrderTotal'
                                    )}
                                </span>
                                <span>
                                    {currency}
                                    {totalWithDiscounts}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const OrderSummaryWeb = () => {
        return (
            <>
                <h3 className="text-lg font-semibold">
                    {t('Containers.PageCheckout.labelOrderSummary')}
                </h3>
                <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700">
                    {cart.map(renderProduct)}
                </div>
                <div className="mt-10 pt-6 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">
                    <div className="mt-4 flex justify-between py-2.5">
                        <span>
                            {t('Containers.PageCheckout.labelShippingEstimate')}
                        </span>
                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                            {currency}
                            {new Intl.NumberFormat('pt-BR', {
                                minimumFractionDigits: 2
                            }).format(shipping)}
                        </span>
                    </div>
                    <div className="flex justify-between py-2.5">
                        <span>
                            {t('Containers.PageCheckout.labelSubtotal')}
                        </span>
                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                            {currency}
                            {totalPrice}
                        </span>
                    </div>
                    <div className="flex justify-between font-bold text-slate-900 dark:text-slate-200 text-base pt-4">
                        <span>
                            {t('Containers.PageCheckout.labelOrderTotal')}
                        </span>
                        <span>
                            {currency}
                            {totalWithDiscounts}
                        </span>
                    </div>
                </div>
            </>
        );
    };

    const headerCheckout = () => {
        return (
            <div className="mb-16">
                <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
                    {t(
                        'Containers.PageCheckout.ContactInfo.labelProductCheckout'
                    )}
                </h2>
                <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
                    <Link to={'/#'} className="">
                        {t(
                            'Containers.PageCheckout.ContactInfo.labelheaderCheckout'
                        )}
                    </Link>
                    <span className="text-xs mx-1 sm:mx-1.5">/</span>
                    <Link to={'/page-collection'} className="">
                        {t(
                            'Containers.PageCheckout.ContactInfo.labelProductCategories'
                        )}
                    </Link>
                    <span className="text-xs mx-1 sm:mx-1.5">/</span>
                    <span className="underline">
                        {t(
                            'Containers.PageCheckout.ContactInfo.labelProductCheckout'
                        )}
                    </span>
                </div>
            </div>
        );
    };

    const toolbarCheckout = () => {
        return (
            <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
                <p className="block relative pl-5">
                    <svg
                        className="w-4 h-4 absolute -left-1 top-0.5"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M12 8V13"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M11.9945 16H12.0035"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    Learn more{` `}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="##"
                        className="text-slate-900 dark:text-slate-200 underline font-medium"
                    >
                        Taxes
                    </a>
                    <span>
                        {` `}and{` `}
                    </span>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="##"
                        className="text-slate-900 dark:text-slate-200 underline font-medium"
                    >
                        Shipping
                    </a>
                    {` `} information
                </p>
            </div>
        );
    };

    CHECKOUTDATA.shippingAddress = checkoutData.shippingAddress;
    CHECKOUTDATA.paymentMethod = checkoutData.paymentMethod;
    CHECKOUTDATA.contactInfo = checkoutData.contactInfo;

    const isValid: boolean =
        isDisabledContact && isDisabledShipping && isDisabledPayment;

    if (isWideScreen) {
        return (
            <>
                {isPay ? (
                    checkoutData.paymentMethod.pix ? (
                        <OrderConfirmation />
                    ) : checkoutData.paymentMethod.cardType ? (
                        <CreditMethod />
                    ) : null
                ) : (
                    <div
                        className={
                            isPay ? 'max-h-0 overflow-hidden' : 'max-h-[auto]'
                        }
                    >
                        <Helmet>
                            <title>Checkout</title>
                        </Helmet>
                        <main className="container py-16 lg:pb-28 lg:pt-20 ">
                            <>{headerCheckout()}</>
                            <div className="flex flex-col lg:flex-row">
                                <div className="flex-1">{renderLeft()}</div>
                                <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>
                                <div className="w-full lg:w-[36%]">
                                    <>{OrderSummaryWeb()}</>
                                    <div className=" mt-10">
                                        {toolbarCheckout()}
                                    </div>
                                </div>
                            </div>
                            <div>
                                {isValid ? (
                                    <ButtonPrimary
                                        onClick={handleSubmit}
                                        className="mt-8 w-full"
                                    >
                                        {t(
                                            'Containers.PageCheckout.labelConfirmOrder'
                                        )}
                                    </ButtonPrimary>
                                ) : (
                                    <ButtonPrimary
                                        onClick={handleSubmit}
                                        disabled
                                        className="mt-8 w-full"
                                    >
                                        {t(
                                            'Containers.PageCheckout.labelConfirmOrder'
                                        )}
                                    </ButtonPrimary>
                                )}
                            </div>
                        </main>
                    </div>
                )}
            </>
        );
    } else {
        return (
            <div className="h-full lg:h-[65vh]">
                {isPay ? (
                    checkoutData.paymentMethod.pix ? (
                        <OrderConfirmation />
                    ) : checkoutData.paymentMethod.cardType ? (
                        <CreditMethod />
                    ) : null
                ) : (
                    <div
                        className={
                            isPay ? 'max-h-0 overflow-hidden' : 'max-h-[auto]'
                        }
                    >
                        <Helmet>
                            <title>Checkout</title>
                        </Helmet>
                        <main className="container py-16 lg:pb-28 lg:pt-20 ">
                            <>{headerCheckout()}</>
                            <div className="flex flex-col lg:flex-row">
                                <div>{OrderSummaryMobile()}</div>
                                <div className="flex-1">{renderLeft()}</div>
                                <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>
                                <div className="w-full lg:w-[36%]">
                                    {toolbarCheckout()}
                                </div>
                            </div>
                            <div>
                                {isValid ? (
                                    <ButtonPrimary
                                        onClick={handleSubmit}
                                        className="mt-8 w-full"
                                    >
                                        {t(
                                            'Containers.PageCheckout.labelConfirmOrder'
                                        )}
                                    </ButtonPrimary>
                                ) : (
                                    <ButtonPrimary
                                        onClick={handleSubmit}
                                        disabled
                                        className="mt-8 w-full"
                                    >
                                        {t(
                                            'Containers.PageCheckout.labelConfirmOrder'
                                        )}
                                    </ButtonPrimary>
                                )}
                            </div>
                        </main>
                    </div>
                )}
            </div>
        );
    }
};

export default CheckoutPage;
