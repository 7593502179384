import React, { FC, useEffect, useId } from 'react';
import Heading from './Heading/Heading';
import img1 from 'images/products/rabo-de-cavalo.png';
import img2 from 'images/products/rainha-luxury-prof.png';
import img3 from 'images/products/papoco-de-liso.png';
import img4 from 'images/products/botox-mylove.png';
import { Product, PRODUCTS } from 'data/data';

import CardCategory3, {
  CardCategory3Props
} from './CardCategories/CardCategory3';
import Glide from '@glidejs/glide';
import { useTranslation } from 'react-i18next';
import { useProdutos } from 'hooks/useQuery';
import ProductCard from './ProductCard';

export const CATS_DISCOVER: CardCategory3Props[] = [
  {
    name: 'Rabo de Cavalo',
    desc: `t('Components.DiscoverMoreSlider.labelCardCategorySubtitle')`,
    featuredImage: img1,
    color: 'bg-gray-100'
  },
  {
    name: 'Rainha Luxury',
    desc: 'A experiência <br /> da Realeza Capilar',
    featuredImage: img2,
    color: 'bg-gray-100'
  },
  {
    name: 'Papoco de Liso',
    desc: 'Cabelos leves, <br /> maleáveis e brilhosos',
    featuredImage: img3,
    color: 'bg-gray-100'
  },
  {
    name: 'Botox My Love',
    desc: 'Poder de <br /> Regeneração dos fios',
    featuredImage: img4,
    color: 'bg-gray-100'
  }
];

export interface DiscoverMoreSliderProps {
  data?: Product[];
}

const DiscoverMoreSlider: FC<DiscoverMoreSliderProps> = ({}) => {
  const { t } = useTranslation();
  const id = useId();
  const UNIQUE_CLASS = 'glidejs' + id.replace(/:/g, '_');
  const { data: products } = useProdutos();

  useEffect(() => {
    let slider: Glide;
    if (products && products.length > 0) {
      const OPTIONS = {
        perView: 2.8,
        gap: 32,
        bound: true,
        breakpoints: {
          1280: {
            gap: 28,
            perView: 2.5
          },
          1279: {
            gap: 20,
            perView: 2.15
          },
          1023: {
            gap: 20,
            perView: 1.6
          },
          768: {
            gap: 20,
            perView: 1.2
          },
          500: {
            gap: 20,
            perView: 1
          }
        }
      };

      slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
      slider.mount();
    }

    return () => {
      if (slider) {
        slider.destroy();
      }
    };
  }, [products, UNIQUE_CLASS]);

  const DescArray: { [key: number]: string } = {
    1: t('Components.DiscoverMoreSlider.labelCardCategorySubtitle'),
    2: t('Components.DiscoverMoreSlider.labelCardCategorySubtitle1'),
    3: t('Components.DiscoverMoreSlider.labelCardCategorySubtitle2'),
    4: t('Components.DiscoverMoreSlider.labelCardCategorySubtitle3')
  };

  return (
    <div className={`nc-DiscoverMoreSlider nc-p-l-container ${UNIQUE_CLASS} `}>
      <Heading
        className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50 nc-p-r-container "
        desc=""
        rightDescText={t('Components.DiscoverMoreSlider.labelRightDescText')}
        hasNextPrev
      >
        {t('Components.DiscoverMoreSlider.labelHighlight')}
      </Heading>
      <div className="" data-glide-el="track">
        <ul className="glide__slides">
          {products &&
            products.map((item, index) => (
              <li key={index} className={'glide__slide'}>
                <CardCategory3
                  name={item.name}
                  desc={DescArray[index + 1]}
                  featuredImage={item.image}
                  data={item}
                />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default DiscoverMoreSlider;
