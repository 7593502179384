import { Product } from 'data/data';
import { IProduto } from 'services/produtos/types';

export function transformProdutos(produtos: IProduto[] ): Product[] {
    const pathIMG = process.env.REACT_APP_URL_IMAGE;

    return produtos.map((produto) => {
        const price = parseFloat(produto.preco_venda);
        const price_venda_dolar = parseFloat(produto.preco_venda_dolar);
        const price_venda_euro = parseFloat(produto.preco_venda_euro);
        const category = mapCategoryIdToName(produto.categoria_id);
        const tags = extractTags(produto);
        const phrase = cutPhrase(produto.seo_meta_tag_descricao);

        const capaImage = produto.imagens.find(
            (imagem) => imagem.img_capa === 1
        );
        const otherImages = produto.imagens
            .filter((imagem) => imagem.img_capa !== 1)
            .map((imagem) => pathIMG + imagem.path);
        return {
            id: produto.id,
            name: produto.nome,
            price: isNaN(price) ? 0 : (price.toFixed(2) as unknown as number),
            price_venda_dolar: isNaN(price_venda_dolar)
                ? 0
                : (price_venda_dolar.toFixed(2) as unknown as number),
            price_venda_euro: isNaN(price_venda_euro)
                ? 0
                : (price_venda_euro.toFixed(2) as unknown as number),
            image: capaImage ? pathIMG + capaImage.path : '',
            images: otherImages ?? [],
            description: produto.descricao,
            shortDescription: phrase,
            category: category,
            seo_tag_titulo: produto.seo_tag_titulo,
            seo_meta_tag_descricao: produto.seo_meta_tag_descricao,
            tags: tags,
            link: '/produto/' + produto.id
            // add more fields mapping if necessary
        };
    });
}

function mapCategoryIdToName(categoryId: number): string {
    // Add your mapping logic here, e.g., a switch statement or a lookup table
    switch (categoryId) {
        case 1:
            return 'Finalizadores';
        case 2:
            return 'Tratamento';
        case 3:
            return 'Transformação';
        case 4:
            return 'Equipamentos';
        case 5:
            return 'Acessorios';
        case 6:
            return 'Moveis';
        case 7:
            return 'AnaAutentic';
        case 8:
            return 'HomeCare';
        default:
            return 'Tratamento';
    }
}

function extractTags(produto: IProduto): string[] {
    const tags: string[] = [];
    if (produto.destaque === 'yes') tags.push('Highlight');
    if (produto.situacao === 'new') tags.push('New');
    if (produto.variacao) tags.push(produto.variacao);
    // Add more tag extraction logic if necessary
    return tags;
}

function cutPhrase(descricao: string): string {
    let resultado;
    if (descricao.length > 70) {
        let truncated = descricao.substring(0, 70);
        let lastSpaceIndex = truncated.lastIndexOf(' ');

        if (lastSpaceIndex > -1) {
            resultado = `${truncated.substring(0, lastSpaceIndex)}...`;
        } else {
            resultado = `${truncated}...`;
        }
    } else {
        return (resultado = descricao);
    }
    return resultado;
}
