import React, { FC, useEffect, useState } from 'react';
import PixMethod from './PaymentPix';
import { CHECKOUTDATA } from '../CheckoutPage';
import ExpiredOrder from './expiredOrder';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface OrderConfirmationProps {
    className?: string;
}

const OrderConfirmation: FC<OrderConfirmationProps> = () => {
    const [status, setStatus] = useState<'qrCode' | 'expired' | 'paid'>(
        'qrCode'
    );
    const userEmail = CHECKOUTDATA.contactInfo.email;
    const { t } = useTranslation();

    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (status === 'qrCode') {
                setStatus('expired'); // pagamento não efetuado
            }
        }, 36000000);
        return () => clearTimeout(timer);
    }, [status]);

    if (status === 'qrCode') {
        return <PixMethod />;
    } else if (status === 'expired') {
        return <ExpiredOrder />;
    } else {
        return (
            <div className="h-[65vh] md:h-[60vh]">
                <main className="block min-h-[75vh] flex-col items-center justify-between px-4">
                    <div className="container mx-auto px-4">
                        <div className="w-full h-full justify-center flex flex-col items-center md:mt-10 py-10">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-28"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                                />
                            </svg>

                            <h6 className="text-[24px] md:text-[55px] mt-8 md:mt-12 font-sans text-black font-bold">
                                {t(
                                    'Containers.PageCheckout.ExpiredOrder.labelOrderConfirm'
                                )}
                            </h6>
                            <p className="text-base md:text-lg max-w-[620px] text-center font-sans text-black font-semibold mt-4 md:mt-12">
                                {t(
                                    'Containers.PageCheckout.ExpiredOrder.labelOrderConfirmInstructionsInit'
                                )}
                                <strong> {userEmail}</strong>
                                {t(
                                    'Containers.PageCheckout.ExpiredOrder.labelOrderConfirmInstructionsInit'
                                )}
                            </p>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
};

export default OrderConfirmation;
