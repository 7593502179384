import avatar1 from 'images/avatars/Image.png';

// acessorios
import product1 from 'images/products_appro/4e5a437e20.jpg';
import product20 from 'images/products_appro/kit-hair-styler-brown.jpg';
import product21 from 'images/products_appro/kit-hair-styler-red-.png';
import product22 from 'images/products_appro/kit-hair-styler2-blue-.png';

import product3 from 'images/products_appro/chapinha_gold1.png';
import product4 from 'images/products_appro/col-infinity-blond-.png';
import product5 from 'images/products_appro/col-infinity-pro-.png';
import product6 from 'images/products_appro/col-infinity-pro2.jpg';
import product7 from 'images/products_appro/col-liso-lambido.png';
import product8 from 'images/products_appro/liso-lambido-1.png';
import product9 from 'images/products_appro/col-ll-supreme2.png';
import product10 from 'images/products_appro/col-pantera-negra-black2.png';
import product11 from 'images/products_appro/col-pantera-negra-gold.png';
import product12 from 'images/products_appro/col-rica-tratamento.png';

//Equipamentos
import productEquipament from 'images/products_appro/chapinha_gold1.png';
import productEquipament1 from 'images/products_appro/chapinha-gold.png';
import productEquipament2 from 'images/products_appro/chapinha-gold-2_1.png';
import productEquipament3 from 'images/products_appro/chapinha-gold-3_1.png';
import productEquipament4 from 'images/products_appro/chapinha-luxury-1_1.png';
import productEquipament5 from 'images/products_appro/chapinha-luxury-2.png';
import productEquipament6 from 'images/products_appro/chapinha-luxury-3.png';
import productEquipament7 from 'images/products_appro/chapinha-luxury-4.png';
import productEquipament8 from 'images/products_appro/ring-1.png';

//tratamento
import product2 from 'images/products_appro/botox-mylove.png';
import product23 from 'images/products_appro/sos-tratamento.png';
import product24 from 'images/products_appro/rica-tratamento.png';
import product25 from 'images/products_appro/supreme-tratamento.png';

//finalizadores
import product13 from 'images/products_appro/serum-sem-lagrimas2.jpg';
import product14 from 'images/products_appro/leave-in-mainha-hidrata2.png';
import product15 from 'images/products_appro/leave-in-mainha-nutre2.png';
import product16 from 'images/products_appro/leave-in-mainha-reconstroi2.png';

//home-care
import product17 from 'images/products_appro/rainha-luxury-homecare.png';
import product18 from 'images/products_appro/mainha-hidrata-profissional-home.png';
import product19 from 'images/products_appro/mainha-nutre-profissional-home.png';

// Moveis
import productFurniture1 from 'images/products_appro/cadeira-gray.png';
import productFurniture2 from 'images/products_appro/cadeira-black.png';
import productFurniture3 from 'images/products_appro/cadeira-salao.png';
import productFurniture4 from 'images/products_appro/detalhes-cadeira-cinza1.png';
import productFurniture5 from 'images/products_appro/detalhes-cadeira-cinza2.png';
import productFurniture6 from 'images/products_appro/detalhes-cadeira-cinza3.png';
import productFurniture7 from 'images/products_appro/detalhes-carrinh2--.png';
import productFurniture8 from 'images/products_appro/detalhes-carrinh2-.png';
import productFurniture9 from 'images/products_appro/detalhes-carrinho2-.png';
import productFurniture10 from 'images/products_appro/detalhes-lavatorio-1-.png';
import productFurniture11 from 'images/products_appro/detalhes-lavatorio-1.png';
import productFurniture12 from 'images/products_appro/detalhes-lavatorio1--.png';
import productFurniture13 from 'images/products_appro/poltrona-1.png';
import productFurniture14 from 'images/products_appro/poltrona-2.png';
import productFurniture15 from 'images/products_appro/lavatorio-massagem-1.png';
import productFurniture16 from 'images/products_appro/lavatorio.jpg';
import productFurniture17 from 'images/products_appro/lavatorio-2.png';

export const productImgs = [
    product1, //0
    product2, //1
    product3, //2
    product4, //3
    product5, //4
    product6, //5
    product7, //6
    product8, //7
    product9, //8
    product10, //9
    product11, //10
    productFurniture1, //11
    productFurniture2, //12
    productFurniture3, //13
    productFurniture4, //14
    productFurniture5, //15
    productFurniture6, //16
    productFurniture7, //17
    productFurniture8, //18
    productFurniture9, //19
    productFurniture13, //20
    productFurniture14, //21
    productFurniture15, //22
    productEquipament1, //23
    productEquipament2, //24
    productEquipament3, //25
    productEquipament4, //26
    productEquipament5, //27
    productEquipament6, //28
    productEquipament7, //29
    product13, //30
    product14, //31
    product15, //32
    product16, //33
    product17, //34
    product18, //35
    product19, //36
    product20, //37
    product21, //38
    product22, //39
    product23, //40
    product24, //41
    product25, //42
    productFurniture15, //43
    productFurniture16, //44
    productFurniture17, //45,
    productEquipament8 //46
];

export const productFurniture = [
    productFurniture1,
    productFurniture2,
    productFurniture3,
    productFurniture4,
    productFurniture5,
    productFurniture6,
    productFurniture7,
    productFurniture8,
    productFurniture9,
    productFurniture10,
    productFurniture11,
    productFurniture12,
    productFurniture13,
    productFurniture14,
    productFurniture15,
    productFurniture16,
    productFurniture17
];

export const nftsAbstracts = [
    'https://images.pexels.com/photos/3631430/pexels-photo-3631430.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    'https://images.pexels.com/photos/5022849/pexels-photo-5022849.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    'https://images.pexels.com/photos/2179483/pexels-photo-2179483.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    'https://images.pexels.com/photos/380337/pexels-photo-380337.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    'https://images.unsplash.com/photo-1557672172-298e090bd0f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
    'https://images.unsplash.com/photo-1556139966-56c3df1ddc63?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDd8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1581985673473-0784a7a44e39?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDl8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1557264305-7e2764da873b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE3fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1560015534-cee980ba7e13?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80',
    'https://images.unsplash.com/photo-1573096108468-702f6014ef28?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1483792879322-696964487a67?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDd8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1545898679-1d7a7701e2fb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEwfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1622547748225-3fc4abd2cca0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80',
    'https://images.unsplash.com/photo-1617791160588-241658c0f566?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1629946832022-c327f74956e0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDd8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1627037558426-c2d07beda3af?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE4fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.pexels.com/photos/4800161/pexels-photo-4800161.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    'https://images.pexels.com/photos/4765691/pexels-photo-4765691.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    'https://images.pexels.com/photos/2441454/pexels-photo-2441454.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    'https://images.unsplash.com/photo-1618172193622-ae2d025f4032?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDZ8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1620121684840-edffcfc4b878?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEzfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1643916861364-02e63ce3e52f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8NXwxMjI1NTc4Nnx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1532965119518-c0450e1bb4da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTF8MTIyNTU3ODZ8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1597423244036-ef5020e83f3c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE4fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1620503374956-c942862f0372?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE3fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1621193793262-4127d9855c91?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDExfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1518640467707-6811f4a6ab73?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE2fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1626282874430-c11ae32d2898?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDF8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    'https://images.unsplash.com/photo-1625527575307-616f0bb84ad2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEyfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60'
];

export const avatarImgs = [avatar1];

export const personNames = [
    'Kailey Greer',
    'Karli Costa',
    'Camren Banes',
    'Belinda Rer',
    'Jameson Dick',
    'Giada Mann',
    'Evie Osborn',
    'Juliet Macp',
    'Charlize Ray',
    'Amaris Pitt',
    'Arnav Moris',
    'Malakai Cey',
    'Nevaeh Henry',
    'Mireya Roman',
    'Anthony Wyat',
    'Mike Orr',
    'Azul Hull',
    'Derick Hub'
];

export const tagNames = [
    'Life',
    'Travel',
    'Music',
    'Beauty',
    'Beach',
    'Hotdog',
    'Car',
    'Bike',
    'Wordpress',
    'Php',
    'Javascript',
    'Vue',
    'Reactjs',
    'Androind'
];

export const featuredImgs = [
    'https://images.pexels.com/photos/10343244/pexels-photo-10343244.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load',
    'https://images.pexels.com/photos/5843990/pexels-photo-5843990.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load',
    'https://images.pexels.com/photos/10343245/pexels-photo-10343245.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load',
    'https://images.pexels.com/photos/3579484/pexels-photo-3579484.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load',
    'https://images.pexels.com/photos/7655908/pexels-photo-7655908.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load',
    'https://images.pexels.com/photos/13270364/pexels-photo-13270364.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load',
    'https://images.pexels.com/photos/12699167/pexels-photo-12699167.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load'
];

export const imgHigtQualitys = [
    'https://images.pexels.com/photos/5913391/pexels-photo-5913391.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    'https://images.pexels.com/photos/5913170/pexels-photo-5913170.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    'https://images.pexels.com/photos/4617820/pexels-photo-4617820.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    'https://images.pexels.com/photos/6136086/pexels-photo-6136086.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    'https://images.pexels.com/photos/4443477/pexels-photo-4443477.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    'https://images.pexels.com/photos/4273989/pexels-photo-4273989.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    'https://images.pexels.com/photos/4806731/pexels-photo-4806731.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    'https://images.pexels.com/photos/5499131/pexels-photo-5499131.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
];

const aTitles = [
    'Adipiscing bibendum est ultricies integer quis auctor elit sed vulputate',
    'in arcu cursus euismod quis viverra nibh cras pulvinar mattis',
    'natoque penatibus et magnis dis parturient montes nascetur ridiculus mus',
    'et leo duis ut diam quam nulla porttitor massa id',
    'turpis cursus in hac habitasse platea dictumst quisque sagittis purus',
    'ut faucibus pulvinar elementum integer enim neque volutpat ac tincidunt',
    'interdum velit euismod in pellentesque massa placerat duis ultricies lacus',
    'fringilla ut morbi tincidunt augue interdum velit euismod in pellentesque',
    'sagittis vitae et leo duis ut diam quam nulla porttitor',
    'in mollis nunc sed id semper risus in hendrerit gravida',
    'tellus integer feugiat scelerisque varius morbi enim nunc faucibus a',
    'eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci',
    'non sodales neque sodales ut etiam sit amet nisl purus'
];

function _getTitleRd() {
    return aTitles[Math.floor(Math.random() * aTitles.length)];
}
function _getPersonNameRd() {
    return personNames[Math.floor(Math.random() * personNames.length)];
}

function _getImgRd() {
    return featuredImgs[Math.floor(Math.random() * featuredImgs.length)];
}

function _getImgHightQualityRd() {
    return imgHigtQualitys[Math.floor(Math.random() * imgHigtQualitys.length)];
}

function _getTagNameRd() {
    return tagNames[Math.floor(Math.random() * tagNames.length)];
}
function _getAvatarRd() {
    return avatarImgs[Math.floor(Math.random() * avatarImgs.length)];
}

export {
    _getImgRd,
    _getTagNameRd,
    _getAvatarRd,
    _getImgHightQualityRd,
    _getTitleRd,
    _getPersonNameRd
};
