import Logo from 'shared/Logo/Logo';
import SocialsList1 from 'shared/SocialsList1/SocialsList1';
import { CustomLink } from 'data/types';
import React from 'react';
import { t } from 'i18next';

export interface WidgetFooterMenu {
    id: string;
    title: string;
    menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
    {
        id: '5',
        title: 'CATEGORIAS',
        menus: [
            { href: '#', label: 'Ana Autêntic' },
            { href: '#', label: 'Transformação' },
            { href: '#', label: 'Tratamento' },
            { href: '#', label: 'Finalizadores' },
            { href: '#', label: 'Home Care' },
            { href: '#', label: 'Acessórios' },
            { href: '#', label: 'Equipamentos' },
            { href: '#', label: 'Móveis para Salão' }
        ]
    },
    {
        id: '1',
        title: 'CONTEÚDO',
        menus: [
            { href: '#', label: 'Fale Conosco' },
            { href: '#', label: 'Como Funciona' },
            { href: '#', label: 'Formas de Envio' },
            { href: '#', label: 'Formas de Pagamento' },
            { href: '#', label: 'Política de Privacidade' },
            { href: '#', label: 'Sobre nós' }
        ]
    },
    {
        id: '2',
        title: 'ATENDIMENTO',
        menus: [
            { href: '#', label: 'Telefone: (86) 99953.0884' },
            { href: '#', label: 'Whatsapp: (86) 99953.0884' },
            { href: '#', label: 'E-mail: vendas.anapaulacarvalho@gmail.com' }
        ]
    }
    // {
    //   id: "4",
    //   title: "Community",
    //   menus: [
    //     { href: "#", label: "Discussion Forums" },
    //     { href: "#", label: "Code of Conduct" },
    //     { href: "#", label: "Contributing" },
    //     { href: "#", label: "API Reference" },
    //   ],
    // },
];

const Footer: React.FC = () => {
    const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
        return (
            <div key={index} className="text-sm space-x-16">
                <h2 className="text-center font-semibold text-slate-50 dark:text-neutral-200">
                    {menu.title}
                </h2>
                <ul className="mt-5 space-y-4">
                    {menu.menus.map((item, index) => (
                        <li key={index}>
                            <a
                                key={index}
                                className="text-gray-300 dark:text-neutral-300 hover:text-gray-700 dark:hover:text-white"
                                href={item.href}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {item.label}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <div className="nc-Footer relative bg-black py-20 lg:pt-28 lg:pb-24 border-t dark:border-neutral-700">
            <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
                <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
                    <div className="col-span-2">
                        <Logo />
                        <div className="mt-6 mb-4 text-sm text-start text-gray-300 hover:text-gray-700 ">
                            <p>{t('Footer.labelTestFooter')}</p>
                        </div>
                    </div>
                    <div className="col-span-2 flex items-center md:col-span-3">
                        <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
                    </div>
                </div>
                {widgetMenus.map(renderWidgetMenuItem)}
            </div>
        </div>
    );
};

export default Footer;
