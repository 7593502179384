
import React, { FC, useEffect, useId, useState } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import bannerChapinha from "images/banners/banner-chapinhas.jpg"
import rightImgDemo from 'images/marcas/ap-chapinha.png';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface SectionPromo1Props {
    className?: string;
}

const SectionPromo1: FC<SectionPromo1Props> = ({ className = '' }) => {
    const { t } = useTranslation();

    return (
        <div className={`nc-SectionPromo1 ${className}`}>
            <div className="relative rounded-2xl sm:rounded-[40px]">
                <Link to={"/page-collection"}>
                    <NcImage
                    className='rounded-2xl w-[1250px]'
                    src={bannerChapinha} 
                    />
                    <div className='absolute lg:bottom-20 lg:ml-16 lg:block hidden'>
                        <ButtonPrimary
                            href="/page-collection"
                            className="border border-amber-900 text-white bg-amber-800 hover:bg-amber-700"
                            >
                            {t('Components.SectionPromo1.labelLearnMore')}
                        </ButtonPrimary>
                    </div>
                </Link>
                
                {/* <div className="relative max-w-lg lg:w-[45%]">
                    <h1 className="font-bold uppercase text-3xl sm:text-4xl xl:text-4xl 2xl:text-5xl mt-2 !leading-[1.2] tracking-wide text-amber-900">
                        Luxury Titanium <br />
                    </h1>
                    <h2 className="font-semibold text-xl sm:text-4xl xl:text-2xl 2xl:text-3xl mt-6 tracking-tight text-amber-900">
                    Chapinha Profissional de Alta Performance. <br />
                    </h2>
                    <span className="text-xl block mt-6 text-slate-500 dark:text-slate-400 ">
                        {t('Components.SectionPromo1.labelSectionPromo1ProdutDesc')}
                    </span>
                    <div className="flex max-md:justify-center space-x-2 sm:space-x-5 mt-6 sm:mt-12">
                        <ButtonPrimary
                        href="/page-collection"
                        className="border border-amber-900 text-white bg-amber-800 hover:bg-amber-700"
                        >
                        {t('Components.SectionPromo1.labelLearnMore')}
                        </ButtonPrimary>
                    </div>
                </div>
                <NcImage
                containerClassName="relative block lg:absolute lg:right-0 lg:bottom-0 mt-10"
                src={rightImgDemo}
                /> */}
            </div>
        </div>
    );
};

export default SectionPromo1;
