import React, { FC, useId } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import { Link, useParams } from 'react-router-dom';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import { CATS_DISCOVER } from 'components/DiscoverMoreSlider';
import { useTranslation } from 'react-i18next';
import { Product, PRODUCTS } from 'data/data';

export interface CardCategory3Props {
  className?: string;
  featuredImage?: string;
  name?: string;
  desc?: string;
  color?: string;
  href?: string;
  data?: Product;
}

const CardCategory3: FC<CardCategory3Props> = ({
  className = '',
  href,
  featuredImage = CATS_DISCOVER[2].featuredImage,
  name = '',
  desc = '',
  color = 'bg-gray-100',
  data = PRODUCTS[0]
}) => {
  const { t } = useTranslation();
  const id = useParams();

  return (
    <Link
      to={`/page-collection`}
      className={`nc-CardCategory3 block ${className}`}
      data-nc-id="CardCategory3"
    >
      <div
        className={`relative w-full aspect-w-16 aspect-h-10 sm:aspect-h-9 h-0 rounded-2xl overflow-hidden group ${color}`}
      >
        <div>
          <NcImage
            src={featuredImage}
            containerClassName="absolute inset-5 sm:inset-8"
            className="absolute right-0 w-1/2 max-w-[260px] h-full object-contain drop-shadow-xl rounded-[1rem]"
          />
        </div>
        <span className="opacity-0 group-hover:opacity-40 absolute inset-0 bg-black/10 transition-opacity"></span>

        <div>
          <div className="absolute inset-5 sm:inset-8 flex flex-col">
            <div className="max-w-[11rem]">
              <span className={`block mb-2 text-sm text-slate-700`}>
                {name}
              </span>
              {desc && (
                <h2
                  className={`text-xl md:text-2xl text-slate-600 font-semibold`}
                  dangerouslySetInnerHTML={{ __html: desc }}
                ></h2>
              )}
            </div>
            <div className="mt-auto">
              <ButtonSecondary
                sizeClass="py-3 px-4 sm:py-3.5 sm:px-6"
                fontSize="text-sm font-medium"
                className="nc-shadow-lg"
              >
                {t('CardCategories.CardCategory3.labelShowAll')}
              </ButtonSecondary>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardCategory3;
