import React, { useEffect, useState } from 'react';
import { CurrencyOptions, useCurrency } from 'context/CurrencyContext'; // Supondo que você tenha um contexto para moeda
import {
  HeaderCurrency,
  headerCurrencyInitial
} from 'components/Header/CurrencyDropdown';

export interface SwitchCurrencyModeProps {
  className?: string;
}

const SwitchCurrencyMode: React.FC<SwitchCurrencyModeProps> = ({
  className = ''
}) => {
  const [currencySelected, setCurrencySelected] = useState('€');
  const { currency, changeCurrency } = useCurrency();
  const [headerCurrency, setHeaderCurrency] = useState<HeaderCurrency[]>(
    headerCurrencyInitial
  );

  useEffect(() => {
    if (localStorage.selectedCurrency) {
      setCurrencySelected(localStorage.selectedCurrency);
    } else {
      setCurrencySelected('€');
    }
  }, []);

  useEffect(() => {
    const newHeaderCurrency = headerCurrency.map((item) => {
      if (item.id === currency) {
        return { ...item, active: true };
      }
      return { ...item, active: false };
    });
    setHeaderCurrency(newHeaderCurrency);
  }, [currency]);

  const getIconForCurrency = (currency: string) => {
    const selectedCurrency =
      headerCurrency.find((item) => item.id === currency) ?? headerCurrency[0];
    return (
      <>
        {selectedCurrency?.id === 'R$' ? (
          <img
            src={selectedCurrency.icon}
            alt={selectedCurrency.name}
            className="h-6 w-6 dark:invert"
          />
        ) : (
          <selectedCurrency.icon className={`w-[18px] h-[18px] `} />
        )}
      </>
    );
  };

  const handleCurrencyChange = () => {
    const currentIndex = headerCurrency.findIndex(
      (item) => item.id === currencySelected
    );
    const nextIndex = (currentIndex + 1) % headerCurrency.length;
    const nextCurrency = headerCurrency[nextIndex];
    setCurrencySelected(nextCurrency.id);
    changeCurrency(nextCurrency.id as CurrencyOptions);
    localStorage.setItem('selectedCurrency', nextCurrency.id);
  };

  const SelectedIcon = getIconForCurrency(currencySelected);

  return (
    <button
      onClick={handleCurrencyChange}
      className={`text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center ${className}`}
    >
      <span className="sr-only">Enable currencies</span>
      <span className="">{SelectedIcon}</span>
    </button>
  );
};

export default SwitchCurrencyMode;
