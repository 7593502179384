import React, { useEffect, useState } from 'react';
import ptFlag from 'images/flags/pt.svg';
import enFlag from 'images/flags/en.svg';
import { useLanguage } from 'context/LanguageContext';

interface HeaderLanguage {
  id: string;
  name: string;
  description: string;
  href: string;
  active?: boolean;
}

export const headerLanguageInitial: HeaderLanguage[] = [
  {
    id: 'pt',
    name: 'Português',
    description: 'Português',
    href: '##',
    active: true
  },
  {
    id: 'en',
    name: 'English',
    description: 'United State',
    href: '##'
  }
  // {
  //   id: "es",
  //   name: "Español",
  //   description: "Español",
  //   href: "##",
  // },
];

export interface SwitchLangModeProps {
  className?: string;
}

const SwitchLangMode: React.FC<SwitchLangModeProps> = ({ className = '' }) => {
  const [langSelected, setLangSelected] = useState('pt');
  const { lang, changeLanguage } = useLanguage();
  const [headerLanguage, setHeaderLanguage] = useState<HeaderLanguage[]>(
    headerLanguageInitial
  );

  useEffect(() => {
    if (localStorage.selectedLanguage) {
      setLangSelected(localStorage.selectedLanguage);
    } else {
      setLangSelected('pt');
    }
  }, []);

  useEffect(() => {
    const newHeaderLanguage = headerLanguage.map((item) => {
      if (item.id === lang) {
        return { ...item, active: true };
      }
      return { ...item, active: false };
    });
    setHeaderLanguage(newHeaderLanguage);
  }, [lang]);

  const getImageForLanguage = (lang: string) => {
    switch (lang) {
      case 'pt':
        return ptFlag;
      case 'en':
        return enFlag;
      // case 'es':
      //   return esFlag;
      default:
        return ptFlag; // Default to Portuguese flag
    }
  };

  const handleLanguageChange = () => {
    const currentIndex = headerLanguage.findIndex(
      (item) => item.id === langSelected
    );
    const nextIndex = (currentIndex + 1) % headerLanguage.length;
    const nextLanguage = headerLanguage[nextIndex];
    setLangSelected(nextLanguage.id);
    changeLanguage(nextLanguage.id);
    localStorage.setItem('selectedLanguage', nextLanguage.id);
  };

  return (
    <button
      onClick={handleLanguageChange}
      className={`text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center ${className}`}
    >
      <span className="sr-only">Enable langs</span>
      <span className="">
        <img
          src={getImageForLanguage(langSelected)}
          alt={langSelected}
          className="w-4 h-4"
        />
      </span>
    </button>
  );
};

export default SwitchLangMode;
