import React, { FC } from 'react';
import Heading from 'components/Heading/Heading';
import ProductCard from 'components/ProductCard';
import { PRODUCTS } from 'data/data';
import { useTranslation } from 'react-i18next';
import { useProdutos } from 'hooks/useQuery';

export interface PageAccessoriesProps {
    className?: string;
}

const SectionAccessoriesPage: FC<PageAccessoriesProps> = ({
    className = ''
}) => {
    const { data: products } = useProdutos();
    const filteredProducts =
        products?.filter((products) => products.category === 'Acessorios') ||
        [];

    const { t } = useTranslation();
    const renderHeading = () => {
        return (
            <div className="pt-20 mb-56">
                <Heading
                    className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50"
                    fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
                    isCenter
                    desc=""
                >
                    {t(
                        `Components.SectionGridMoreExplore.labelSectionNavAccessories`
                    )}
                </Heading>

                <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
                    <div className="space-y-10 lg:space-y-14">
                        <hr className="border-slate-200 dark:border-slate-700" />
                        <main>
                            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
                                {filteredProducts.map((item, index) => (
                                    <ProductCard data={item} key={index} />
                                ))}
                            </div>
                            <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center"></div>
                        </main>
                    </div>
                </div>
            </div>
        );
    };

    return <>{renderHeading()}</>;
};

export default SectionAccessoriesPage;
