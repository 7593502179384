import React, { FC, useEffect, useId } from 'react';
import Heading from 'components/Heading/Heading';
import Glide from '@glidejs/glide';
import CollectionCard from './CollectionCard';
import CollectionCard2 from './CollectionCard2';
import { Link } from 'react-router-dom';
import { Product } from 'data/data';
import { useProdutos } from 'hooks/useQuery';
import { useTranslation } from 'react-i18next';

export interface SectionSliderLargeProductProps {
  className?: string;
  itemClassName?: string;
  cardStyle?: 'style1' | 'style2';
  data?: Product[];
}

const SectionSliderLargeProduct: FC<SectionSliderLargeProductProps> = ({
  className = '',
}) => {
  const id = useId();
  const UNIQUE_CLASS = 'glidejs' + id.replace(/:/g, '_');

  const { data: products } = useProdutos()
  const FURNITURE_PRODUCTS = products;
  useEffect(() => {
    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 3,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          gap: 28,
          perView: 2.5
        },
        1024: {
          gap: 20,
          perView: 2.15
        },
        768: {
          gap: 20,
          perView: 1.5
        },

        500: {
          gap: 20,
          perView: 1
        }
      }
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [UNIQUE_CLASS]);

  // const { data: products } = useProdutos();
  const { t } = useTranslation();

  return (
    <div className={`nc-SectionSliderLargeProduct ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading isCenter={false} hasNextPrev>
          {t(
            'Components.SectionSliderProduct.labelSectionSliderProductCardTitle'
          )}
          <span className="text-gray-500">
            {t(
              'Components.SectionSliderProduct.labelSectionSliderProductCardSubtitle'
            )}
          </span>
        </Heading>

        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {FURNITURE_PRODUCTS &&
              FURNITURE_PRODUCTS.map((item, index) => (
                <li className={`glide__slide`} key={index}>
                  <CollectionCard2 data={item} />
                </li>
              ))}
            <li className={`glide__slide   `}>
              <Link to={'/busca'} className="block relative group">
                <div className="relative rounded-2xl overflow-hidden h-[410px]">
                  <div className="h-[410px] bg-black/5 dark:bg-neutral-800"></div>
                  <div className="absolute inset-y-6 inset-x-10  flex flex-col items-center justify-center">
                    <div className="flex items-center justify-center relative">
                      <span className="text-xl font-semibold">More items</span>
                      <svg
                        className="absolute left-full w-5 h-5 ml-2 rotate-45 group-hover:scale-110 transition-transform"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.0701 9.57L12.0001 3.5L5.93005 9.57"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 20.4999V3.66992"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <span className="text-sm mt-1">Show me more</span>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderLargeProduct;
