import { productImgs } from 'contains/fakeData';
import {productFurniture} from 'contains/fakeData';
import productVariantImg2 from 'images/products/v2.jpg';
import productVariantImg3 from 'images/products/v3.jpg';
import productVariantImg4 from 'images/products/v4.jpg';
import productVariantImg5 from 'images/products/v5.jpg';


//

export interface ProductVariant {
    id: number;
    name: string;
    thumbnail?: string;
    color?: string;
    featuredImage: string;
}
export interface Product {
    id: number;
    name: string;
    price: number;
    price_venda_dolar: number;
    price_venda_euro: number;
    image: string;
    images: string[];
    description: string;
    shortDescription?: string;
    seo_tag_titulo?: string;
    seo_meta_tag_descricao?: string;
    category: string;
    tags: string[];
    link: string;
    variants?: ProductVariant[];
    variantType?: 'color' | 'image';
    sizes?: string[];
    allOfSizes?: string[];
    status?: 'New in' | 'limited edition' | 'Sold Out' | '50% Discount';
}

const DEMO_VARIANTS: ProductVariant[] = [
    {
        id: 2,
        name: 'White',
        thumbnail: productVariantImg2,
        featuredImage: productImgs[1]
    },
    {
        id: 3,
        name: 'Orange',
        thumbnail: productVariantImg3,
        featuredImage: productImgs[2]
    },
    {
        id: 4,
        name: 'Sky Blue',
        thumbnail: productVariantImg4,
        featuredImage: productImgs[3]
    },
    {
        id: 5,
        name: 'Natural',
        thumbnail: productVariantImg5,
        featuredImage: productImgs[4]
    }
];
const DEMO_VARIANT_COLORS: ProductVariant[] = [
    {
        id: 1,
        name: 'Violet',
        color: 'bg-violet-400',
        featuredImage: productImgs[0]
    },
    {
        id: 2,
        name: 'Yellow',
        color: 'bg-yellow-400',
        featuredImage: productImgs[1]
    },
    {
        id: 3,
        name: 'Orange',
        color: 'bg-orange-400',
        featuredImage: productImgs[2]
    },
    {
        id: 4,
        name: 'Sky Blue',
        color: 'bg-sky-400',
        featuredImage: productImgs[3]
    },
    {
        id: 5,
        name: 'Green',
        color: 'bg-green-400',
        featuredImage: productImgs[4]
    }
];

export const PRODUCTS: Product[] = [
    //acessorios
    {
        id: 1,
        name: 'kit HairStyle ',
        description:
            'Kit hair style - Kit hair styler Combuca + pincel + espatula + pente.',
        seo_meta_tag_descricao:
            'Foi desenvolvido especialmente para nossas mainhas profissionais da beleza.',
        price: 49.9,
        image: productImgs[0],
        category: 'acessorios',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANTS,
        variantType: 'image',
        sizes: ['XS', 'S', 'M', 'L', 'XL'],
        allOfSizes: ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'],
        status: 'New in',
        images: [],
        price_venda_dolar: 8.96,
        price_venda_euro: 8.06
    },
    {
        id: 2,
        name: 'kit HairStyle - Brown ',
        description:
            'Kit hair style - Kit hair styler Combuca + pincel + espatula + pente.',
        seo_meta_tag_descricao:
            'Foi desenvolvido especialmente para nossas mainhas profissionais da beleza.',
        price: 49.9,
        image: productImgs[37],
        category: 'acessorios',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANTS,
        variantType: 'image',
        sizes: ['XS', 'S', 'M', 'L', 'XL'],
        allOfSizes: ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'],
        status: 'New in',
        images: [],
        price_venda_dolar: 8.96,
        price_venda_euro: 8.06
    },
    {
        id: 3,
        name: 'kit HairStyle - Red',
        description:
            'Kit hair style - Kit hair styler Combuca + pincel + espatula + pente.',
        seo_meta_tag_descricao:
            'Foi desenvolvido especialmente para nossas mainhas profissionais da beleza.',
        price: 49.9,
        image: productImgs[38],
        category: 'acessorios',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANTS,
        variantType: 'image',
        sizes: ['XS', 'S', 'M', 'L', 'XL'],
        allOfSizes: ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'],
        status: 'New in',
        images: [],
        price_venda_dolar: 8.96,
        price_venda_euro: 8.06
    },
    {
        id: 4,
        name: 'kit HairStyle - Blue',
        description:
            'Kit hair style - Kit hair styler Combuca + pincel + espatula + pente.',
        seo_meta_tag_descricao:
            'Foi desenvolvido especialmente para nossas mainhas profissionais da beleza.',
        price: 49.9,
        image: productImgs[39],
        category: 'acessorios',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANTS,
        variantType: 'image',
        sizes: ['XS', 'S', 'M', 'L', 'XL'],
        allOfSizes: ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'],
        status: 'New in',
        images: [],
        price_venda_dolar: 8.96,
        price_venda_euro: 8.06
    },
    //tratamento
    {
        id: 5,
        name: 'My Love',
        description:
            '<strong>FILCORTEX:</strong>  é um ativo utilizado no tratamento de cabelos frageis e danificados, uma vez que atua na reposição de aminoacidos, entre eles glicina e tirosina, impostantes para formação da proteina KAP-GHT. Desta forma, auxilia na recomposição do córtex capilar e aumenta o volume e a resistência dos cabelos. </br> <strong>EXTRATO DE BABOSA</strong>  tem ação emoliente, cicatrizante, tonificante, anti-inflamatória, suavizante, lenitiva, refrescante, hidratante, protetora e restauradora de tercidos.</br> <strong>EXTRATO DE ALGAS:</strong>  Ajudam a remover as celulas mortas do couro cabeludo e a equilibrar os níveis de oliosidade, o que potealiza sua ação anticaspa.',
        seo_meta_tag_descricao:
            'O MY LOVE CAPILAR Ana Paula Carvalho, vem com uma proposta ousada com alto poder de regeneração dos fios, elimina o frizz, devolve brilho e maciez, deixando os cabelos sedosos, leves, soltos e com aspecto natural.  </br> Foi desenvolvido para prolongar o efeito liso e restaurar os fios de forma rápida e saudável. É a combinação perfeita de um produto incrível associado a uma técnica diferente para que nossas Mainhas alcancem um resultado Surreal.',
        price: 279.9,
        image: productImgs[1],
        category: 'tratamento',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANTS,
        variantType: 'image',
        status: 'New in',
        images: [],
        price_venda_dolar: 50.27,
        price_venda_euro: 45.22
    },
    {
        id: 6,
        name: 'S.O.S PRO',
        seo_meta_tag_descricao:
            '<strong>S.O.S-PRO:</strong>  </br> <strong>LINHA DE TRATAMENTO PROFISSIONAL</strong></br> Recupere em instantes os cabelos elásticos e emborrachados através da tecnologia exclusiva S.O.S PRO tornando os cabelos saudáveis, macios e recuperados. Sua formulação contém proteínas e aminoácidos que repõe a massa cortical perdida, agindo e reparando os fios de forma rápida e profunda',
        description:
            '<strong>Shampoo Equilibrio</strong></br> promove limpeza suave e auxilia no fortalecimento dos cabelos. Suas propriedades contam com uma ação altamente antioxidante capaz de retardar os danos provocados por radicais livres e proporcionar o rejuvenescimento dos fios. <strong></br>Sinergia entre ativos:</strong> Óleo de Semente de Uva, Fiotex, Óleo de Abacate.<strong></br>Modo de uso:</strong> Aplique o Shampoo sobre os cabelos molhados. Massageie até formar uma espuma e enxágüe bem. Repita o processo conforme necessário.',
        price: 439.5,
        image: productImgs[40],
        category: 'tratamento',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANTS,
        variantType: 'image',
        status: 'New in',
        images: [],
        price_venda_dolar: 79.4,
        price_venda_euro: 71.15
    },
    {
        id: 7,
        name: 'Coleção Rica Tratamento Reconstrutor de Uso Profissional',
        description:
            '<strong>A Coleção Rica</strong> foi cuidadosamente desenvolvida para restaurar a saúde e a beleza dos cabelos que enfrentam os danos mais intensos. Projetada para tratar cabelos extremamente ressecados e danificados por processos químicos, essa linha utiliza uma combinação de ativos poderosos para restaurar a massa capilar, reconstruindo os fios de dentro para fora. Com uma tecnologia exclusiva que incorpora um complexo de aminoácidos, Queratina, Elastina, Manteiga de Murumuru, Óleo de Ojon e Vitamina E, a <strong>Linha Rica</strong> reestrutura e reconstrói as fibras capilares, devolvendo a resistência e a condição original dos cabelos, ao mesmo tempo que proporciona uma reparação profunda.',
        seo_meta_tag_descricao:
            '<strong>COLEÇÃO RICA Linha de Tratamento Profissional </strong></br> Elixir Brilho Extremo | Leave-in Finalizador Supreme </br> Indicado para: Cabelos extremamente ressecados, quebradiços, emborrachados e quimicamente danificados.',
        price: 439.5,
        image: productImgs[41],
        category: 'tratamento',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANTS,
        variantType: 'image',
        status: 'New in',
        images: [],
        price_venda_dolar: 79.4,
        price_venda_euro: 71.15
    },
    {
        id: 8,
        name: 'Protect Supreme - Proteção Térmica | Reposição de 14 Aminoácidos | Brilho Intenso',
        description:
            'O <strong>Protect Supreme </strong> é um protetor térmico de alta performance, formulado com uma poderosa combinação de ativos que oferecem proteção completa para seus cabelos. Desenvolvido para reconstruir e nutrir profundamente os fios, o <strong>Protect Supreme </strong>  cria uma barreira protetora que defende os cabelos contra os danos causados por altas temperaturas, enquanto repõe até 14 aminoácidos essenciais, garantindo cabelos mais fortes, saudáveis e brilhantes.',
        seo_meta_tag_descricao:
            '<strong>Protect Supreme - Protetor Térmico de Alta Performance</strong>  </br> <strong>Proteção Térmica | Reposição de 14 Aminoácidos | Brilho Intenso</strong></br>',
        price: 58.0,
        image: productImgs[42],
        category: 'tratamento',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANTS,
        variantType: 'image',
        status: 'New in',
        images: [],
        price_venda_dolar: 10.48,
        price_venda_euro: 9.41
    },
    //transformacao
    {
        id: 9,
        name: 'Coleção Infinity Blond',
        description:
            'O Infinity Blond é um produto inovador que não desbota os cabelos brancos, ruivos, loiros ou platinados, desenvolvido para todos os tipos de cabelo é um produto 100% livre de formol que proporciona cabelos alinhados, maleáveis, brilhosos e sedosos.Enriquecido com uma exclusiva tecnologia + Ativos que tratam os fios de forma profunda - sistema inteligente que promove reestruturação da fibra capilar - O Infinity Blond elimina o volume dos cabelos enquanto trata e reconstrói os fios danificados devido a ação do tempo.',
        seo_meta_tag_descricao:
            '<strong>Infinity Blond + Protect Blond ÁCIDOS ORGÂNICOS</strong> </br> Manteiga de Karitê | Whey Protein | Óleo de Linhaça | Ômega 3 | Ceramidas | Vitamina E',
        price: 414.8,
        image: productImgs[3],
        category: 'transformacao',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANTS,
        variantType: 'image',
        images: [],
        price_venda_dolar: 75.55,
        price_venda_euro: 67.13
    },
    {
        id: 10,
        name: 'Coleção Infinity Pro',
        seo_meta_tag_descricao:
            '<strong>Combo Infinity Pro ÁCIDOS ORGÂNICOS </strong> </br>Vitamina E | Manteiga de Murumuru | Óleo de Algodão ',
        description:
            'O Infinity Pro foi desenvolvido para proporcionar cabelos mais lisos, saudaveis, alinhados, maleáveis e brilhosos. Tecnologia exclusiva que proporciona resultados ainda mais satisfatorios com a concentração dos ativos que tratam, sistema inteligente que promove reestruturação dos fios. O Infinity Pro elimina o volume dos cabelos enquanto trata e reconstrói os fios danificados de forma profunda.',
        price: 407.9,
        image: productImgs[4],
        category: 'transformacao',
        tags: ['tag1', 'tag2'],
        variants: DEMO_VARIANT_COLORS,
        variantType: 'color',
        link: '/product-detail/',
        status: 'Sold Out',
        images: [],
        price_venda_dolar: 73.46,
        price_venda_euro: 66.02
    },
    {
        id: 11,
        name: 'Kit Liso Lambido',
        seo_meta_tag_descricao:
            '<strong>LISO LAMBIDO ÁCIDOS ORGÂNICOS </strong> </br> OLÉO DE MACADÂMIA I MANTEIGA DE CUPUAÇU I VITAMINA E',
        description:
            'O Liso Lambido é um produto inovador de Uso Profissional, 100% livre de formol que proporciona cabelos alinhados, maleáveis, brilhosos e sedosos. Enriquecido com uma exclusiva tecnologia + Ativos que tratam os fios de forma profunda - sistema inteligente que promove reestruturação da fibra capilar - O Liso Lambido elimina o volume dos cabelos enquanto trata e reconstrói os fios danificados devido a ação do tempo. </br> RESULTADO - Cabelos saudáveis, hidratados, macios e altamente maleáveis, com brilho intenso e livres de frizz Com o Liso Lambido é fácil e rápido conseguir o realinhamento dos sonh+os. Clientes e profissionais satisfeitos com os resultados incríveis obtidos através do Liso Lambido Ana Paula Carvalho.',
        price: 354.9,
        image: productImgs[6],
        category: 'transformacao',
        tags: ['tag1', 'tag2'],
        variants: DEMO_VARIANTS,
        variantType: 'image',
        link: '/product-detail/',
        images: [],
        price_venda_dolar: 63.92,
        price_venda_euro: 57.42
    },
    {
        id: 12,
        name: 'Liso Lambido',
        seo_meta_tag_descricao:
            '<strong>LISO LAMBIDO ÁCIDOS ORGÂNICOS </strong> </br> OLÉO DE MACADÂMIA I MANTEIGA DE CUPUAÇU I VITAMINA E',
        description:
            'O Liso Lambido é um produto inovador de Uso Profissional, 100% livre de formol que proporciona cabelos alinhados, maleáveis, brilhosos e sedosos. Enriquecido com uma exclusiva tecnologia + Ativos que tratam os fios de forma profunda - sistema inteligente que promove reestruturação da fibra capilar - O Liso Lambido elimina o volume dos cabelos enquanto trata e reconstrói os fios danificados devido a ação do tempo. </br> RESULTADO - Cabelos saudáveis, hidratados, macios e altamente maleáveis, com brilho intenso e livres de frizz Com o Liso Lambido é fácil e rápido conseguir o realinhamento dos sonh+os. Clientes e profissionais satisfeitos com os resultados incríveis obtidos através do Liso Lambido Ana Paula Carvalho.',
        price: 299.9,
        image: productImgs[7],
        category: 'transformacao',
        tags: ['tag1', 'tag2'],
        variants: DEMO_VARIANTS,
        variantType: 'image',
        link: '/product-detail/',
        images: [],
        price_venda_dolar: 54.02,
        price_venda_euro: 48.52
    },
    {
        id: 13,
        name: 'Coleção LL Supreme',
        seo_meta_tag_descricao:
            '<strong>LL SUPREME. </strong> </br> O QUE JÁ ERA BOM FICOU AINDA MELHOR! MAIOR DURABILIDADE DO EFEITO LISO + BRILHO + MACIEZ + EMOLIENCIA.',
        description:
            ' O LL Supreme veio para surpreender! O que já era bom ficou ainda melhor, com alto poder de alinhamento, brilho e durabilidade nos resultados, cabelos mais lisos, fortes e saudáveis com a nova formulação do LL Supreme, com tecnologia exclusiva e alta concentração de ativos reconstrutores para proporcionar cabelos mais saldaveis, lisos, fortes e brilhosos. O Liso Lambido SUPREME chegou! </br> <strong>RESULTADO: </strong> Cabelos saudáveis, hidratados, macios, mais maleáveis e com um brilho de surpreender, o LL Supreme veio para deixar as clientes e profissionais de beleza apaixonadas. ',
        price: 407.0,
        image: productImgs[8],
        category: 'transformacao',
        tags: ['tag1', 'tag2'],
        variants: DEMO_VARIANTS,
        variantType: 'image',
        link: '/product-detail/',
        status: 'New in',
        images: [],
        price_venda_dolar: 73.31,
        price_venda_euro: 65.77
    },
    {
        id: 14,
        name: 'Coleção Pantera Negra - Realinhamento Capilar de Alta Performance',
        description:
            ' Inspirado na força e elegância da majestosa Pantera Negra, este tratamento foi especialmente formulado para mulheres que exigem o melhor. </br><strong>Alisamento Potente:</strong> O Pantera Negra proporciona um alisamento duradouro e de alta performance, deixando os cabelos lisos, disciplinados e com um movimento natural deslumbrante.</br><strong> Versatilidade:</strong> Eficaz em todos os tipos de cabelos, dos afros aos ondulados, garantindo um alisamento perfeito e uniforme.</br><strong>Nutrição Profunda:</strong> Ingredientes exóticos como ceramidas e aminoácidos nutrem profundamente os fios, restaurando a vitalidade e o brilho natural dos cabelos.</br><strong>Proteção e Reparação:</strong> O Protetor Térmico e o Óleo Reparador atuam como uma armadura contra os danos térmicos e ambientais, garantindo cabelos saudáveis e protegidos em todas as situações.</br><strong>Brilho Intenso:</strong> Cada produto da linha Pantera Negra é formulado para maximizar o brilho dos cabelos, resultando em fios radiantes e cheios de vida',
        seo_meta_tag_descricao:
            '<strong>Pantera Negra - Realinhamento Capilar de Alta Performance Luxo, Poder e Sofisticação para Seus Cabelos</strong> </br> Descubra o poder transformador do Pantera Negra, o realinhamento capilar que redefine o conceito de alisamento de alta performance.',
        price: 577.2,
        image: productImgs[10],
        category: 'transformacao',
        tags: ['tag1', 'tag2'],
        variants: DEMO_VARIANTS,
        variantType: 'image',
        link: '/product-detail/',
        status: 'limited edition',
        images: [],
        price_venda_dolar: 103.83,
        price_venda_euro: 93.27
    },
    //moveis
    {
        id: 15,
        name: 'Poltrona Salão',
        description: 'Poltrona Salão Luxo',
        seo_meta_tag_descricao: '',
        price: 4.999,
        image: productImgs[11],
        category: 'moveis',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANT_COLORS,
        variantType: 'color',
        status: '50% Discount',
        images: [],
        price_venda_dolar: 899.23,
        price_venda_euro: 808.49
    },
    {
        id: 16,
        name: 'Poltrona para Salões de Beleza Luxo By Ana Paula Carvalho',
        description:
            'Poltrona Salão LuxoOs móveis de luxo para salões exigentes agora têm um novo padrão de sofisticação e conforto com a Cadeira para Salões de Beleza Luxo By Ana Paula Carvalho. Ideal para salões de altíssimo padrão de qualidade, esta cadeira proporciona uma experiência única tanto para a profissional quanto para a cliente. Com design exclusivo e materiais de alta qualidade, a cadeira oferece um ambiente de conforto e estilo, tornando o momento no salão uma experiência inesquecível',
        seo_meta_tag_descricao:
            'A Cadeira para Salões de Beleza Luxo By Ana Paula Carvalho foi desenvolvida para atender salões de beleza que buscam alinhar conforto e luxo em seus serviços. Com um design elegante e sofisticado, esta cadeira é perfeita para proporcionar uma experiência de conforto superior para o cliente e praticidade para o profissional.',
        price: 4.999,
        image: productImgs[20],
        category: 'moveis',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANT_COLORS,
        variantType: 'color',
        status: '50% Discount',
        images: [],
        price_venda_dolar: 899.23,
        price_venda_euro: 808.49
    },
    {
        id: 17,
        name: 'Lavatório Tecnológico com Massageador, Luxury By Ana Paula Carvalho',
        description:
            '<strong>Cor:</strong> Preto com detalhes em couro e madeira. </br><strong>Detalhes:</strong> Acabamentos dourados que remetem ao luxo e sofisticação  </br><strong>Cuba: </strong>Preta com filetes em ouro</br><strong>Ducha: </strong> Metálica com ponteira em plástico para maior durabilidade  </br><strong>Registro: </strong>  Metálico cromado, garantindo um acabamento refinado</br><strong>Descanso de pés:</strong> Em couro com detalhe dourado para maior conforto e estilo',
        seo_meta_tag_descricao:
            '<strong>Lavatório Confort Luxury By Ana Paula Carvalho</strong> </br> Os móveis de luxo para salões exigentes agora têm um novo padrão de sofisticação e conforto com o Lavatório Confort Luxury By Ana Paula Carvalho. Ideal para salões de altíssimo padrão de qualidade, este lavatório proporciona uma experiência única tanto para a profissional quanto para a cliente. Com design exclusivo e materiais de alta qualidade, o lavatório oferece um ambiente de relaxamento e bem-estar, tornando o momento de lavagem dos cabelos uma experiência inesquecível.',
        price: 24999.99,
        image: productImgs[43],
        category: 'moveis',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANT_COLORS,
        variantType: 'color',
        status: '50% Discount',
        images: [],
        price_venda_dolar: 4514.22,
        price_venda_euro: 4055.3
    },
    {
        id: 18,
        name: 'Lavatório Slões de Beleza Luxo By Ana Paula Carvalho',
        description:
            ' </br><strong> </strong>  </br><strong> </strong>  </br><strong> </strong> ',
        seo_meta_tag_descricao: '',
        price: 6499.0,
        image: productImgs[44],
        category: 'moveis',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANT_COLORS,
        variantType: 'color',
        status: '50% Discount',
        images: [],
        price_venda_dolar: 1173.52,
        price_venda_euro: 1056.49
    },
    //finalizadores
    {
        id: 19,
        name: 'Sem Lágrimas - Sérum Reparador',
        description:
            'Esse sistema exclusivo combina múltiplos ativos e tecnologias para oferecer um tratamento de finalização completo. Combinando hidratação, nutrição, e reconstrução, o Leave-in Mainha Nutre proporciona 10 benefícios em um único produto, tornando-se essencial para qualquer rotina de cuidados capilares.',
        seo_meta_tag_descricao:
            'O Leave-in Mainha Nutre é fruto de pesquisas científicas avançadas que resultaram na criação da revolucionária Tecnologia All-in-One. ',
        price: 64.9,
        image: productImgs[30],
        category: 'finalizadores',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANT_COLORS,
        variantType: 'color',
        status: '50% Discount',
        images: [],
        price_venda_dolar: 11.7,
        price_venda_euro: 10.5
    },
    {
        id: 20,
        name: 'Leave-In Mainha Hidrata 10 em 1',
        description:
            'O Leave-in Mainha Hidrata é o resultado de pesquisas científicas avançadas que culminaram na criação da inovadora Tecnologia All-in-One. Este sistema tecnológico exclusivo combina diversos ativos e tecnologias para oferecer um tratamento de finalização completo. Combinando hidratação, nutrição, e reconstrução, este produto multifuncional proporciona 10 benefícios em um só, tornando-se indispensável para a rotina de cuidados capilares.',
        seo_meta_tag_descricao:
            ' <strong>Leave-in Mainha Hidrata</strong> </br><strong> Tecnologia All-in-One</strong> </br><strong>Indicado para:</strong> Todos os tipos de cabelos',

        price: 65,
        image: productImgs[31],
        category: 'finalizadores',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANT_COLORS,
        variantType: 'color',
        status: '50% Discount',
        images: [],
        price_venda_dolar: 11.72,
        price_venda_euro: 10.51
    },
    {
        id: 21,
        name: 'Leave-In Mainha Nutre 10 em 1',
        seo_meta_tag_descricao:
            ' <strong>Leave-in Mainha Nutre</strong> </br><strong> Tecnologia All-in-One</strong> </br><strong>Indicado para:</strong> Todos os tipos de cabelos',
        description:
            'O Leave-in Mainha Nutre é fruto de pesquisas científicas avançadas que resultaram na criação da revolucionária Tecnologia All-in-One. Esse sistema exclusivo combina múltiplos ativos e tecnologias para oferecer um tratamento de finalização completo. Combinando hidratação, nutrição, e reconstrução, o Leave-in Mainha Nutre proporciona 10 benefícios em um único produto, tornando-se essencial para qualquer rotina de cuidados capilares.',
        price: 65,
        image: productImgs[32],
        category: 'finalizadores',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANT_COLORS,
        variantType: 'color',
        status: '50% Discount',
        images: [],
        price_venda_dolar: 11.72,
        price_venda_euro: 10.51
    },
    {
        id: 22,
        name: 'Leave-In Mainha Reconstrói 10 em 1',
        description:
            'O Leave-in Mainha Reconstrói é o resultado de pesquisas científicas avançadas que culminaram na criação da inovadora Tecnologia All-in-One. Esse sistema exclusivo combina múltiplos ativos e tecnologias para oferecer um tratamento de finalização completo. Unindo hidratação, nutrição e reconstrução em um único produto poderoso, o Leave-in Mainha Reconstrói proporciona 10 benefícios em um só passo, tornando-se essencial para qualquer rotina de cuidados capilares.',
        seo_meta_tag_descricao:
            ' <strong>Leave-in Mainha Reconstrói</strong> </br><strong> Tecnologia All-in-One</strong> </br><strong>Indicado para:</strong> Todos os tipos de cabelos',
        price: 65,
        image: productImgs[33],
        category: 'finalizadores',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANT_COLORS,
        variantType: 'color',
        status: '50% Discount',
        images: [],
        price_venda_dolar: 11.72,
        price_venda_euro: 10.51
    },

    //home care
    {
        id: 23,
        name: 'Rainha Luxury Home Care - Tratamento de Umectação Profissional em Casa',
        description:
            '<strong> Componentes do Kit:</strong></br><strong> Shampoo Profissional Rainha Luxury (300ml):</strong></br> Este shampoo foi especialmente formulado para uma limpeza suave e eficaz, ideal para preparar os fios para o tratamento de umectação. Ele limpa profundamente, removendo impurezas enquanto preserva a hidratação natural dos cabelos.<strong></br> Máscara de Tratamento Rainha Luxury (300g):</strong> Enriquecida com óleos naturais e ativos poderosos, esta máscara oferece uma nutrição intensa e uma umectação profunda. É a escolha perfeita para revitalizar cabelos secos e danificados, devolvendo a maciez, o brilho e a elasticidade natural dos fios.',
        seo_meta_tag_descricao:
            'Transforme seu ritual de cuidados capilares em uma experiência de luxo com o Rainha Luxury Home Care, um tratamento de umectação premium desenvolvido pela renomada AP Pro by Ana Paula Carvalho. Este kit foi criado para atender as necessidades de clientes exigentes que desejam manter a saúde e a beleza dos cabelos com um tratamento de salão na conveniência de sua casa.',
        price: 149.9,
        image: productImgs[34],
        category: 'homecare',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANT_COLORS,
        variantType: 'color',
        status: '50% Discount',
        images: [],
        price_venda_dolar: 27.02,
        price_venda_euro: 24.25
    },
    {
        id: 24,
        name: 'Mainha Pro Hidrata',
        description:
            'O Mainha Hidrata contém em sua formulação, complexo Pró-Collagen Synergy, fórmula exclusiva com ativos que estimulam a síntese do Colágeno, tem o poder de hidratar profundamente os cabelos, controlando a absorção de água, eliminando imediatamente a sensação de ressecamento, reduzindo o frizz e promovendo alta maciez. Composto de bioativos de Aloe Vera e Broto de Bambu, agentes naturais altamente hidratantes. Com silicones aminofuncionais com ação anti-frizz e doadora de brilho intenso. Efeito calmante e hidratante durante o uso com aromaterapia integrada exclusiva a partir do Óleo Essencial de Lavanda. Modo de Uso: Aplicar nos cabelos úmidos e massageie suavemente em direção circular ate formar uma espuma, em seguida enxague. Repita o processo se necessário.',

        seo_meta_tag_descricao:
            '<strong>MAINHA HIDRATA PROFISSIONAL </strong></br> Indicado para: Todos os tipos de cabelo, em especial aqueles que estão mais ressecados ou danificados por uso constante de secador, escova ou chapinha.',
        price: 257.0,
        image: productImgs[35],
        category: 'homecare',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANT_COLORS,
        variantType: 'color',
        status: '50% Discount',
        images: [],
        price_venda_dolar: 46.33,
        price_venda_euro: 41.61
    },
    {
        id: 25,
        name: 'Mainha Pro Nutre',
        description:
            'O Shampoo Mainha Nutre foi desenvolvido para cabelos secos, quebradiços e quimicamente danificados, com a tecnologia exclusiva Pure Vitamin Plex, complexo de vitaminas que nutre os fios da raiz às pontas, devolvendo a maciez e brilho natural aos cabelos. Limpa profundamente sem ressecar, com a finalidade de promover nutrição de cabelos secos, quebradiços e quimicamente danificados. Com fragrância exclusiva de autoria Ana Paula Carvalho. Com Óleo Essencial de Tea Tree, que controla a oleosidade da raiz às pontas. ',
        seo_meta_tag_descricao:
            '<strong>Mainha Nutre Profissional</strong></br> Indicado para: Cabelos secos, quebradiços e quimicamente danificados.',

        price: 257.0,
        image: productImgs[36],
        category: 'homecare',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANT_COLORS,
        variantType: 'color',
        status: '50% Discount',
        images: [],
        price_venda_dolar: 46.33,
        price_venda_euro: 41.61
    },
    //equipamentos
    {
        id: 26,
        name: 'Chapinha Luxury Titanium Gold - 110/220',
        description:
            'A <strong>Luxury Titanium Gold </strong> é a escolha perfeita para quem busca um alisamento de qualidade profissional, seja em casa ou no salão. Combinando tecnologia de ponta e design sofisticado, esta chapinha oferece placas de titânio de alta qualidade e um sistema de íons negativos, garantindo um alisamento uniforme e saudável para todos os tipos de cabelo.',
        seo_meta_tag_descricao:
            ' <strong>Luxury Titanium Gold - Chapinha Profissional de Alta Performance</strong> </br><strong>Indicado para:</strong> Todos os tipos de cabelos',
        price: 973.50,
        image: productImgs[25],
        category: 'equipamentos',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANT_COLORS,
        variantType: 'color',
        status: '50% Discount',
        images: [],
        price_venda_dolar: 175.94,
        price_venda_euro: 158.16
    },
    {
        id: 27,
        name: 'Chapinha Luxury Titanium Black - 110/220V',
        description:
            'A <strong>Luxury Titanium Black </strong> A Luxury Titanium Black é a escolha perfeita para quem busca um alisamento de qualidade profissional, seja em casa ou no salão. Combinando tecnologia de ponta e design sofisticado, esta chapinha oferece placas de titânio de alta qualidade e um sistema de íons negativos, garantindo um alisamento uniforme e saudável para todos os tipos de cabelo. ',
        seo_meta_tag_descricao:
            ' <strong>Luxury Titanium Black - Chapinha Profissional de Alta Performance</strong> </br><strong>Indicado para:</strong> Todos os tipos de cabelos',
        price: 855,
        image: productImgs[28],
        category: 'equipamentos',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANT_COLORS,
        variantType: 'color',
        status: '50% Discount',
        images: [],
        price_venda_dolar: 154.58,
        price_venda_euro: 138.91
    },
    {
        id: 28,
        name: 'Ring Light AP - 18P Profissional',
        description:
            'O RING APPRO 18P foi projetado para atender profissionais da beleza que buscam uma iluminação de alta qualidade, perfeita para realçar os detalhes dos procedimentos capilares, como alisamentos, colorações e finalizações. Com um diâmetro de 18 polegadas (45 cm) e uma potência de 55W, este anel de luz LED oferece uma iluminação suave e uniforme, ajudando a descalçar melhor os cabelos e a destacar o brilho e a textura dos fios. </br> </br> Ideal para salões de beleza, estúdios ou gravações de vídeos, o RING APPRO 18P permite ajustar a temperatura de cor entre 3000K e 6000K, alternando entre luz branca, quente e neutra, de acordo com o efeito desejado. O controle remoto torna o ajuste de brilho e temperatura simples e rápido, sem a necessidade de interromper o processo. ',
        seo_meta_tag_descricao:
            ' <strong>RING APPRO 18P</strong> </br> Anel de Luz LED 18 Polegadas com Controle Remoto e Tripé de 2,1m.',
        price: 569.9,
        image: productImgs[46],
        category: 'equipamentos',
        tags: ['tag1', 'tag2'],
        link: '/product-detail/',
        variants: DEMO_VARIANT_COLORS,
        variantType: 'color',
        status: '50% Discount',
        images: [],
        price_venda_dolar: 103.04,
        price_venda_euro: 92.64
    }
    //
];


