import { useCart } from 'context/CartContext';
import { useCurrency } from 'context/CurrencyContext';
import QRCodeComponent from 'hooks/useQrCode';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface PixProps {
    isPix?: boolean;
    IsWeb?: boolean;
    seconds?: number;
}

const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
        remainingSeconds
    ).padStart(2, '0')}`;
};

const PixMethod: React.FC<PixProps> = () => {
    const valorQR =
        '00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/51186888-20a3-4ec3-9d0d-8ffc35dbb90e5204000053039865802BR5906APPMAX6012PORTO ALEGRE62070503***6304D402';
    const { totalPrice } = useCart();
    const navigate = useNavigate();
    const amount = Number(totalPrice) || 0;
    const { t } = useTranslation();
    const [timeLeft, setTimeLeft] = useState(3600);
    useEffect(() => {
        if (timeLeft > 0) {
            const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timerId);
        }
    }, [timeLeft]);
    const { currency } = useCurrency();
    return (
        <div className="container mx-auto px-4">
            <div className="mt-[82px] mb-[142px]">
                <div className="flex w-full items-center">
                    <div className="flex flex-col md:flex-column w-full items-center md:items-center gap-[64px] md:gap-0">
                        <div className="w-[100%] md:w-[100%]  flex flex-col items-center md:items-center ">
                            <div className="max-w-[275px]">
                                <div className="py-3 px-4 flex items-center gap-2 rounded bg-black text-white">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        width={'50'}
                                        height={'50'}
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                        />
                                    </svg>

                                    <p className="text-sm font-semibold font-sans">
                                        {t(
                                            'Containers.PageCheckout.PaymentPix.labelAwaiting'
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <h2
                                    id="index"
                                    className="text-[16px] text-center md:text-center md:text-[30px] mt-9 text-black font-bold font-sans"
                                >
                                    {t(
                                        'Containers.PageCheckout.PaymentPix.labelSecureOrderInit'
                                    )}
                                    <span className="bg-">
                                        <span className="text-[#000] font-bold">
                                            {' '}
                                            {formatTime(timeLeft)}{' '}
                                            {t(
                                                'Containers.PageCheckout.PaymentPix.labelSecureOrderMinuts'
                                            )}{' '}
                                        </span>
                                    </span>

                                    {t(
                                        'Containers.PageCheckout.PaymentPix.labelSecureOrderEnd'
                                    )}
                                </h2>
                            </div>

                            <div className="w-full mt-8 flex flex-col items-center md:items-center gap-3">
                                <p className="text-sm laptop:text-base text-center laptop:text-start laptop:text-lg text-black font-[500] font-sans laptop:leading-[45px]">
                                    {t(
                                        'Containers.PageCheckout.PaymentPix.labelPixInstructions'
                                    )}
                                </p>
                                <p className="text-sm laptop:text-base text-center laptop:text-start laptop:text-lg text-black font-[500] font-sans laptop:leading-[45px]">
                                    {t(
                                        'Containers.PageCheckout.PaymentPix.labelOpt1'
                                    )}
                                </p>
                                <p className="text-sm laptop:text-base text-center laptop:text-start laptop:text-lg text-black font-[500] font-sans laptop:leading-[45px]">
                                    {t(
                                        'Containers.PageCheckout.PaymentPix.labelOpt2'
                                    )}
                                </p>
                                <p className="text-sm laptop:text-base text-center laptop:text-start laptop:text-lg text-black font-[500] font-sans laptop:leading-[45px]">
                                    {t(
                                        'Containers.PageCheckout.PaymentPix.labelOpt3'
                                    )}
                                </p>
                                <p className="text-sm laptop:text-base text-center laptop:text-start laptop:text-lg text-black font-[500] font-sans laptop:leading-[45px]">
                                    {t(
                                        'Containers.PageCheckout.PaymentPix.labelOpt4'
                                    )}
                                </p>
                                <p className="text-sm laptop:text-base text-center laptop:text-start laptop:text-lg text-black font-[500] font-sans laptop:leading-[45px]">
                                    {t(
                                        'Containers.PageCheckout.PaymentPix.labelOpt5'
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className="w-[100%] md:w-[70%] md:mt-[40px] md:max-w-full">
                            <div className="py-6 md:py-8 px-4 md:px-6 shadow w-100 bg-white rounded-md ">
                                <div className="w-full flex flex-col text-center justify-center">
                                    <h4 className="text-lg font-sans text-black font-bold ">
                                        {' '}
                                        <strong className="font-bold text-black">
                                            {t(
                                                'Containers.PageCheckout.PaymentPix.labelPixAmount'
                                            )}{' '}
                                            {currency} {' '}
                                            {amount.toFixed(2)}
                                        </strong>
                                    </h4>
                                    <div className="flex mt-[14px] flex-col items-center">
                                        <div className="flex gap-2 items-center">
                                            <span className="text-black">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="size-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                                                    />
                                                </svg>
                                            </span>
                                            <p className="ffont-sans text-base text-black font-[500]">
                                                {t(
                                                    'Containers.PageCheckout.PaymentPix.labelInstructionCamera'
                                                )}
                                            </p>
                                        </div>
                                        <div className="mt-8 p-4 border-gray-300 border-[1px] rounded-lg">
                                            <QRCodeComponent
                                                value={valorQR}
                                                size={256}
                                            />
                                        </div>
                                        <div className="mt-8 flex w-full items-center gap-2">
                                            <div className="w-full border-gray-300 border-t-[1px]"></div>
                                            <p className="text-base font-sans font-[500] text-black">
                                                {t(
                                                    'Containers.PageCheckout.PaymentPix.labelInstructionOR'
                                                )}
                                            </p>
                                            <div className="w-full border-gray-300 border-t-[1px]"></div>
                                        </div>
                                        <button
                                            onClick={() => {
                                                navigate('/confirm-order');
                                                navigator.clipboard.writeText(
                                                    valorQR
                                                );
                                            }}
                                            className="bg-[#F7F7F7] hover:border-gray-400 mt-6 w-full border-[1px] border-gray-300 flex items-center py-4 justify-center gap-2 rounded-lg text-base font-sans font-[500] text-black uppercase"
                                        >
                                            <span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="size-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                                                    />
                                                </svg>
                                            </span>{' '}
                                            {t(
                                                'Containers.PageCheckout.PaymentPix.labelCpyCode'
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PixMethod;
