import React, { InputHTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = '',
      sizeClass = 'h-11 px-4 py-3',
      fontClass = 'text-sm font-normal',
      rounded = 'rounded-2xl',
      placeholder,
      type = 'text',
      defaultValue,
      ...args
    },
    ref
  ) => {
    const [value, setValue] = useState(defaultValue);
    const { i18n } = useTranslation();

    const formatPhoneNumber = (inputValue: string) => {
      let formattedValue = inputValue.replace(/\D/g, '');

      if (i18n.language === 'pt') {
        formattedValue = formattedValue
          .replace(/^(\d)/, '+$1')
          .replace(/(.{3})(\d)/, '$1 ($2')
          .replace(/(.{7})(\d)/, '$1) $2')
          .replace(/(.{10})(\d)/, '$1 $2');
      } else {
        formattedValue = formattedValue
          .replace(/^(\d)/, '+$1')
          .replace(/(.{4})(\d)/, '$1 ($2')
          .replace(/(.{8})(\d)/, '$1) $2')
          .replace(/(.{11})(\d)/, '$1 $2');
      }

      const length = formattedValue.length;
      if (length >= 16 && length <= 19) {
        formattedValue = formattedValue.replace(/(.{length-15})$/, '-$1');
      } else if (length >= 20) {
        formattedValue = formattedValue.replace(/(.{5})$/, '-$1').slice(0, 20);
      }

      return formattedValue;
    };

    const formatCPF = (inputValue: string) => {
      return inputValue
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    };

    const handleChange = (event: { target: { value: any } }) => {
      const { value: inputValue } = event.target;
      let formattedValue = inputValue;

      if (type === 'tel') {
        formattedValue = formatPhoneNumber(inputValue);
      } else if (type === 'cpf') {
        formattedValue = formatCPF(inputValue);
      }

      setValue(formattedValue);
    };

    return (
      <input
        ref={ref}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 ${rounded} ${fontClass} ${sizeClass} ${className}`}
        {...args}
      />
    );
  }
);

export default Input;
