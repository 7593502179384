import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Checkbox from 'shared/Checkbox/Checkbox';
import Input from 'shared/Input/Input';
import Label from 'components/Label/Label';
import { IContactInfoState } from './CheckoutPage';
import InputPhoneDDI from 'shared/InputPhoneDDI/InputPhoneDDI';

interface Props {
    isActive: boolean;
    onOpenActive: () => void;
    onCloseActive: () => void;
    contact: IContactInfoState;
    onChange: (contact: IContactInfoState) => void;
    errors: Record<string, string>;
}

const ContactInfo: FC<Props> = ({
    isActive,
    onCloseActive,
    onOpenActive,
    contact,
    onChange,
    errors
}) => {
    const { t, i18n } = useTranslation();
    const [contactInfo, setContactInfo] = useState<IContactInfoState>(contact);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setContactInfo((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    const handleChangeBoolean = (e: boolean) => {
        setContactInfo((prevState) => ({
            ...prevState,
            receiveOffers: e
        }));
    };
    const handleChangePhone = (value: string) => {
        setContactInfo((prevState) => ({
            ...prevState,
            phoneNumber: value
        }));
    };
    const handleChangeCPF = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const cpf = formatCPF(value);
        setContactInfo((prevState) => ({
            ...prevState,
            cpf: cpf
        }));
    };

    const formatCPF = (inputValue: string) => {
        return inputValue
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    };

    const formatPhoneNumber = (inputValue: string) => {
        let formattedValue = inputValue.replace(/\D/g, '');

        if (i18n.language === 'pt') {
            formattedValue = formattedValue
                .replace(/^(\d{2})(\d)/, '($1) $2')
                .replace(/(\d{1})(\d{4})(\d{4})$/, '$1 $2-$3');
        } else {
            formattedValue = formattedValue
                .replace(/^(\d)/, '+$1')
                .replace(/(.{4})(\d)/, '$1 ($2')
                .replace(/(.{8})(\d)/, '$1) $2')
                .replace(/(.{11})(\d)/, '$1 $2');
        }

        return formattedValue;
    };
    useEffect(() => {
        onChange(contactInfo);
    }, [contactInfo]);

    const renderAccount = () => {
        return (
            <div className="border border-green-100 dark:border-slate-700 rounded-xl overflow-hidden z-0">
                <div className="flex flex-col sm:flex-row items-start p-6">
                    <span className="hidden sm:block">
                        <svg
                            className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.50998C8.71997 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                    <div className="sm:ml-8">
                        <h3 className=" text-slate-700 dark:text-slate-300 flex">
                            <span className="uppercase tracking-tight">
                                {t(
                                    'Containers.PageCheckout.ContactInfo.labelContactInfo'
                                )}
                            </span>
                        </h3>
                        <div className="font-semibold mt-1 text-sm">
                            <span className="">{contactInfo.email}</span>
                            <span className="ml-3 tracking-tighter">
                                {contactInfo.phoneNumber}
                            </span>
                        </div>
                    </div>
                    <ButtonSecondary
                        sizeClass="py-2 px-4"
                        fontSize="text-sm font-medium"
                        className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
                        onClick={onOpenActive}
                    >
                        {t('Containers.PageCheckout.ContactInfo.labelChange')}
                    </ButtonSecondary>
                </div>
                <div
                    className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
                        isActive ? 'block' : 'hidden'
                    }`}
                >
                    <div className="flex justify-between flex-wrap items-baseline">
                        <span className="block text-sm my-1 md:my-0">
                            {t(
                                'Containers.PageCheckout.ContactInfo.labelCreateAccount'
                            )}
                            {` `}
                            <a
                                href="##"
                                className="text-primary-500 font-medium"
                            >
                                {t(
                                    'Containers.PageCheckout.ContactInfo.labelLogin'
                                )}
                            </a>
                        </span>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                        <div>
                            <Label className="text-sm">
                                {t(
                                    'Containers.PageCheckout.ContactInfo.labelName'
                                )}
                            </Label>
                            <Input
                                className="mt-1.5"
                                name="name"
                                value={contactInfo.name}
                                onChange={handleChange}
                            />
                            {errors.name && (
                                <p className="text-red-600 text-sm mt-1">
                                    {errors.name}
                                </p>
                            )}
                        </div>

                        <div>
                            <Label className="text-sm">
                                {t(
                                    'Containers.PageCheckout.ContactInfo.labelCPF'
                                )}
                            </Label>
                            <Input
                                className="mt-1.5 border"
                                value={contactInfo.cpf}
                                type="cpf"
                                name="cpf"
                                onChange={handleChangeCPF}
                            />
                            {errors.cpf && (
                                <p className="text-red-600 text-sm mt-1">
                                    {errors.cpf}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                        <div>
                            <Label className="text-sm">
                                {t(
                                    'Containers.PageCheckout.ContactInfo.labelNumber'
                                )}
                            </Label>
                            <InputPhoneDDI
                                className="mt-1.5"
                                name="phoneNumber"
                                value={contactInfo.phoneNumber}
                                change={(value) => {
                                    handleChangePhone(value);
                                }}
                            />
                            {errors.phoneNumber && (
                                <span className="text-red-500 text-sm">
                                    {errors.phoneNumber}
                                </span>
                            )}
                        </div>

                        <div className="max-w-xs">
                            <Label className="text-sm">
                                {t(
                                    'Containers.PageCheckout.ContactInfo.labelEmailAddress'
                                )}
                            </Label>
                            <Input
                                className="mt-1.5"
                                type="email"
                                value={contactInfo.email}
                                name="email"
                                onChange={handleChange}
                            />
                            {errors.email && (
                                <span className="text-red-500 text-sm">
                                    {errors.email}
                                </span>
                            )}
                        </div>
                    </div>
                    <div>
                        <Checkbox
                            className="!text-sm"
                            name="receiveOffers"
                            label={t(
                                'Containers.PageCheckout.ContactInfo.labelEmailOffers'
                            )}
                            checked={contactInfo.receiveOffers}
                            onChange={(e) => handleChangeBoolean(e)}
                        />
                    </div>

                    <div className="flex flex-col sm:flex-row pt-6">
                        <ButtonPrimary
                            className="sm:!px-7 shadow-none"
                            onClick={onCloseActive}
                        >
                            {t('Containers.PageCheckout.ContactInfo.labelSave')}
                        </ButtonPrimary>
                        <ButtonSecondary
                            className="mt-3 sm:mt-0 sm:ml-3"
                            onClick={onCloseActive}
                        >
                            {t(
                                'Containers.PageCheckout.ContactInfo.labelCancel'
                            )}
                        </ButtonSecondary>
                    </div>
                </div>
            </div>
        );
    };

    return renderAccount();
};

export default ContactInfo;
