import Label from 'components/Label/Label';
import React, { FC, useRef, useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
import Select from 'shared/Select/Select';
import Textarea from 'shared/Textarea/Textarea';
import CommonLayout from './CommonLayout';
import { Helmet } from 'react-helmet-async';
import { avatarImgs } from 'contains/fakeData';
import { useTranslation } from 'react-i18next';
import { useProfile } from 'context/ProfileContext';
import axios from 'axios';
import { set, z } from 'zod';

export interface AccountPageProps {
    className?: string;
}

const genderOpt = [
    { value: 'Male', label: 'Masculino' },
    { value: 'Female', label: 'Feminino' },
    { value: 'Other', label: 'Outro' }
];

const formatPhoneNumber = (inputValue: string) => {
    let formattedValue = inputValue.replace(/\D/g, '');

    // Se o número for brasileiro (11 dígitos)
    if (formattedValue.length === 11) {
        formattedValue = formattedValue
            .slice(0, 11)
            .replace(/^(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d{4})$/, '$1-$2');
    }
    // Se o número for internacional +11 digitos
    else if (formattedValue.length > 11) {
        formattedValue = formattedValue
            .replace(/^(\d)/, '+$1')
            .slice(0, 15)
            .replace(/(.{4})(\d)/, '$1 ($2')
            .replace(/(.{8})(\d)/, '$1) $2')
            .replace(/(.{13})(\d)/, '$1-$2');
    }
    // número brasileiro sem o nono dígito
    else if (formattedValue.length === 10) {
        formattedValue = formattedValue
            .replace(/^(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{4})(\d{4})$/, '$1-$2');
    }

    return formattedValue;
};

const AccountPage: FC<AccountPageProps> = ({ className = '' }) => {
    const { t } = useTranslation();
    const { user, loadProfile } = useProfile();

    const [fullName, setFullName] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [gender, setGender] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState<string>(
        user?.dateOfBirth || ''
    );

    const [errors, setErrors] = useState<Record<string, string>>({});

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [preview, setPreview] = useState<string | null>('');

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            // Verifica se existem arquivos selecionados
            const file = files[0];
            const filePreview = URL.createObjectURL(file);
            setPreview(filePreview);
            if (user) user.avatar = filePreview;
        }
    };

    // Função para fazer o upload do avatar (implementação posterior)
    const handleImgClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = event.target;
        if (name === 'fullName') setFullName(value);
        if (name === 'phoneNumber') {
            const formatedNumber = formatPhoneNumber(value);
            setPhoneNumber(formatedNumber);
        }
        if (name === 'address') setAddress(value);
        if (name === 'gender') setGender(value);
        if (name === 'dateOfBirth') setDateOfBirth(value);
    };

    const handleSubmit = async () => {
        const userData = {
            fullName,
            dateOfBirth,
            address,
            gender,
            phoneNumber
        };

        const formData = new FormData();

        if (preview) {
            // blob transform
            const response = await fetch(preview);
            const blob = await response.blob();
            formData.append('image', blob, 'avatar.png');
        } else {
            console.error('Preview está nulo. Nenhuma imagem para upload.');
        }

        console.log(userData);

        // Adicionar os dados de userData ao FormData
        Object.entries(userData).forEach(([key, value]) => {
            formData.append(key, value);
        });

        formData.forEach((value, key) => {
            console.log(`${key}:`, value);
        });
        console.log('--', formData);

        // try {
        //     const res = await fetch(
        //         `https://api.approsystem.com.br/api/v1/clientes/${id}`,
        //         { method: 'PUT', body: formData }
        //     );

        //     if (!res.ok) {
        //         throw new Error(`Erro na requisição fetch: ${res.statusText}`);
        //     }
        // } catch (error) {
        //     console.error('Erro na requisição:', error);
        // }
    };

    return (
        <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
            <Helmet>
                <title>
                    {t('Containers.AccountPage.AccountPage.labelHeaderName')} ||
                    Ana Paula Professional
                </title>
            </Helmet>
            <CommonLayout>
                <div className="space-y-10 sm:space-y-12">
                    {/* HEADING */}
                    <h2 className="text-2xl sm:text-3xl font-semibold">
                        {t(
                            'Containers.AccountPage.AccountPage.labelHeaderName'
                        )}
                    </h2>
                    <div className="flex flex-col md:flex-row">
                        <div className="flex-shrink-0 flex items-start">
                            {/* AVATAR */}
                            <div className="relative rounded-full overflow-hidden flex">
                                {preview ? (
                                    <img
                                        src={preview}
                                        alt=""
                                        className="w-32 h-32 rounded-full object-cover z-0"
                                    />
                                ) : (
                                    <div onClick={handleImgClick}>
                                        <img
                                            src={avatarImgs[2]}
                                            alt=""
                                            className="w-32 h-32 rounded-full object-cover z-0"
                                        />

                                        <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                                            <svg
                                                width="30"
                                                height="30"
                                                viewBox="0 0 30 30"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                                                    stroke="currentColor"
                                                    strokeWidth={1.5}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>

                                            <span className="mt-1 text-xs">
                                                {t(
                                                    'Containers.AccountPage.AccountPage.labelChangeImage'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                )}

                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={(event) =>
                                        handleImageChange(event)
                                    }
                                    className="absolute inset-0 opacity-0 cursor-pointer none"
                                />
                            </div>
                        </div>
                        <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
                            {/* ---- */}
                            <div>
                                <Label>
                                    {t(
                                        'Containers.AccountPage.AccountPage.labelFullName'
                                    )}{' '}
                                </Label>
                                <Input
                                    className="mt-1.5"
                                    name="fullName"
                                    value={fullName}
                                    placeholder="Nome Completo"
                                    onChange={handleChange}
                                />
                            </div>

                            {/* ---- */}
                            <div>
                                <Label>
                                    {t(
                                        'Containers.AccountPage.AccountPage.labelEmail'
                                    )}
                                </Label>
                                <div className="mt-1.5 flex">
                                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                        <i className="text-2xl las la-envelope"></i>
                                    </span>
                                    <Input
                                        className="!rounded-l-none"
                                        placeholder="example@email.com"
                                        defaultValue={user?.email}
                                        value={user?.email}
                                        disabled
                                    />
                                </div>
                            </div>

                            {/* ---- */}
                            <div className="max-w-lg">
                                <Label>
                                    {t(
                                        'Containers.AccountPage.AccountPage.labelDateOfBirth'
                                    )}{' '}
                                </Label>
                                <div className="mt-1.5 flex">
                                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                        <i className="text-2xl las la-calendar"></i>
                                    </span>
                                    <Input
                                        className="!rounded-l-none"
                                        type="date"
                                        name="dateOfBirth"
                                        value={dateOfBirth}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            {/* ---- */}
                            <div>
                                <Label>
                                    {t(
                                        'Containers.AccountPage.AccountPage.labelAddress'
                                    )}
                                </Label>
                                <div className="mt-1.5 flex">
                                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                        <i className="text-2xl las la-map-signs"></i>
                                    </span>
                                    <Input
                                        className="!rounded-l-none"
                                        placeholder="Rua das Flores"
                                        name="address"
                                        value={address}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            {/* ---- */}
                            <div>
                                <Label>
                                    {t(
                                        'Containers.AccountPage.AccountPage.labelGender'
                                    )}
                                </Label>
                                <Select
                                    className="mt-1.5"
                                    name="gender"
                                    value={gender}
                                    onChange={handleChange}
                                >
                                    {genderOpt.map((genders, index) => (
                                        <option
                                            key={index}
                                            value={genders.value}
                                        >
                                            {genders.label}{' '}
                                        </option>
                                    ))}
                                </Select>
                            </div>

                            {/* ---- */}
                            <div>
                                <Label>
                                    {t(
                                        'Containers.AccountPage.AccountPage.labelPhoneNumber'
                                    )}
                                </Label>
                                <div className="mt-1.5 flex">
                                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                        <i className="text-2xl las la-phone-volume"></i>
                                    </span>
                                    <Input
                                        className="!rounded-l-none"
                                        placeholder="003 888 232"
                                        name="phoneNumber"
                                        value={phoneNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            {/* ---- */}

                            <div className="pt-2">
                                <ButtonPrimary onClick={handleSubmit}>
                                    {t(
                                        'Containers.AccountPage.AccountPage.labelUpdateAccount'
                                    )}
                                </ButtonPrimary>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonLayout>
        </div>
    );
};

export default AccountPage;
