import React, { FC, useState, useEffect } from 'react';
import imageRightPng from 'images/liso_lambido-transformed.png';
import videoGif from 'images/video/midia-ap.gif';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Next from 'shared/NextPrev/Next';
import Prev from 'shared/NextPrev/Prev';
import useInterval from 'react-use/lib/useInterval';
import useBoolean from 'react-use/lib/useBoolean';

interface Hero2DataType {
  type: string;
  image: string;
  heading: string;
  subHeading: string;
  btnText: string;
  btnLink: string;
}
export interface SectionHero2Props {
  className?: string;
}

type ContentIntervalType = 'image' | 'midia' | 'text';

const DATA: Hero2DataType[] = [
  {
    type: 'image',
    image:
      'https://cdn.awsli.com.br/1920x1920/1358/1358683/banner/2-ka4w6qeryk.jpg',
    heading: 'Liso Lambido',
    subHeading:
      'Produto inovador de Uso Profissional, 100% livre de formol. Enriquecido com exclusiva tecnologia + Ativos que tratam os fios de forma profunda',
    btnText: 'Saiba mais',
    btnLink: '/'
  },
  {
    type: 'midia',
    image: videoGif,
    heading: 'Liso Lambido',
    subHeading:
      'Sistema inteligente que promove reestruturação da fibra capilar e proporciona cabelos alinhados, maleáveis, brilhosos e sedosos',
    btnText: 'Saiba mais',
    btnLink: '/'
  },
  {
    type: 'image',
    image:
      'https://cdn.awsli.com.br/1920x1920/1358/1358683/banner/4-awikta4e0m.jpg',
    heading: 'Liso Lambido',
    subHeading:
      'Produto inovador de Uso Profissional, 100% livre de formol. Enriquecido com exclusiva tecnologia + Ativos que tratam os fios de forma profunda',
    btnText: 'Saiba mais',
    btnLink: '/'
  },
  {
    type: 'text',
    image: imageRightPng,
    heading: 'Liso Lambido',
    subHeading:
      'Realinhamento que resulta em cabelos saudáveis, hidratados, macios e altamente maleáveis, com brilho intenso e livres de frizz',
    btnText: 'Saiba mais',
    btnLink: '/'
  },
  {
    type: 'image',
    image:
      'https://cdn.awsli.com.br/1920x1920/1358/1358683/banner/7-gw8k1nsav4.jpg',
    heading: 'Liso Lambido',
    subHeading:
      'Produto inovador de Uso Profissional, 100% livre de formol. Enriquecido com exclusiva tecnologia + Ativos que tratam os fios de forma profunda',
    btnText: 'Saiba mais',
    btnLink: '/'
  }
];
let TIME_OUT: NodeJS.Timeout | null = null;

const SectionHero2: FC<SectionHero2Props> = ({ className = '' }) => {
  // =================
  const [indexActive, setIndexActive] = useState(0);
  const [activate, setActivate] = useState(0);
  const [isRunning, toggleIsRunning] = useBoolean(true);
  const currentContent = DATA[indexActive];

  const getDelay = (type: ContentIntervalType): number => {
    switch (type) {
      case 'image':
        return 3000;
      case 'midia':
        return 19000;
      case 'text':
        return 3500;
      default:
        return 3500;
    }
  };

  useInterval(
    () => {
      handleAutoNext();
    },
    isRunning ? getDelay(currentContent.type as ContentIntervalType) : null
  );
  // //
  const handleAutoNext = () => {
    setIndexActive((state) => {
      if (state >= DATA.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= DATA.length - 1) {
        return 0;
      }
      return state + 1;
    });
    handleAfterClick();
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return DATA.length - 1;
      }
      return state - 1;
    });
    handleAfterClick();
  };

  const handleAfterClick = () => {
    toggleIsRunning(false);
    if (TIME_OUT) {
      clearTimeout(TIME_OUT);
    }
    TIME_OUT = setTimeout(() => {
      toggleIsRunning(true);
    }, 1000);
  };
  // //

  //intervall Image
  const intervalProgressImg = () => {
    setActivate((prev) => (prev >= 100 ? 0 : prev + 10));
  };
  useInterval(intervalProgressImg, 3000);

  //intervall Midia
  const intervalProgressMidia = () => {
    setActivate((prev) => (prev >= 100 ? 0 : prev + 10));
  };
  useInterval(intervalProgressMidia, 10000);

  //intervall Image + Btn
  const intervalProgressText = () => {
    setActivate((prev) => (prev >= 100 ? 0 : prev + 10));
  };
  useInterval(intervalProgressText, 3500);

  const renderItem = (index: number) => {
    const isActive = indexActive === index;
    const item = DATA[index];

    if (!isActive) {
      return null;
    }
    {
      switch (item.type) {
        case 'image':
          return (
            <div
              className={`h-[35hv] lg:h-[40vh] xl:h-[60vh] 2xl:h-[75vh] w-full  nc-SectionHero2Item nc-SectionHero2Item--animation flex justify-center relative overflow-hidden ${className}`}
              key={index}
            >
              <div className="absolute bottom-2 lg:bottom-4  left-1/2 -translate-x-1/2 z-20 flex justify-center">
                {DATA.map((_, index) => {
                  const isActive = indexActive === index;
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        // setIndexActive(index);
                        handleAfterClick();
                      }}
                      className={`relative px-1 py-1.5 cursor-pointer`}
                    >
                      <div
                        className={`relative w-8 lg:w-20 h-0.5 lg:h-1 shadow-sm rounded-md bg-white`}
                      >
                        {isActive && (
                          <div
                            className={`nc-SectionHero2Item__dot w-[${intervalProgressImg}] absolute inset-0  bg-slate-900 rounded-md ${
                              isActive ? ' ' : ' '
                            }`}
                          ></div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <img
                className="mx-auto object-fill lg:w-full sm:object-contain md:object-scale-down lg:object-fill  xl:object-center nc-SectionHero2Item__image"
                src={item.image}
                alt={item.heading}
              />
              <Prev
                className="absolute left-1 sm:left-5 lg:top-3/4 xl:top-2/4 md:top-1/4 top-2/4 sm:-translate-y-1/2 z-10 !text-slate-700"
                btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                svgSize="w-6 h-6"
                onClickPrev={handleClickPrev}
              />
              <Next
                className="absolute right-1 sm:right-5 lg:top-3/4  xl:top-2/4 md:top-1/4 top-2/4 sm:-translate-y-1/2 z-10 !text-slate-700"
                btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                svgSize="w-6 h-6"
                onClickNext={handleAutoNext}
              />
            </div>
          );
        case 'midia':
          return (
            <div
              className={`h-[35hv] lg:h-[40vh] xl:h-[60vh] 2xl:h-[75vh]  nc-SectionHero2Item  nc-SectionHero2Item--animation flex justify-center relative overflow-hidden ${className}`}
              key={index}
            >
              <div className="absolute bottom-4  xl:bottom-4 left-1/2-translate-x-1/2 z-20 flex justify-center">
                {DATA.map((_, index) => {
                  const isActive = indexActive === index;
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setIndexActive(index);
                        handleAfterClick();
                      }}
                      className={`relative px-1 py-1.5 cursor-pointer`}
                    >
                      <div
                        className={`relative w-8 lg:w-20 h-0.5 lg:h-1 shadow-sm rounded-md bg-white`}
                      >
                        {isActive && (
                          <div
                            className={`nc-SectionHero2Item__dot  w-[${intervalProgressMidia}] absolute inset-0 bg-slate-900 rounded-md ${
                              isActive ? ' ' : ' '
                            }`}
                          ></div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              <img
                className="sm:w-full object-fill nc-SectionHero2Item__imagel xl:object-center"
                src={item.image}
                alt={item.heading}
              />
              <Prev
                className="absolute left-1 sm:left-5 lg:top-3/4 xl:top-2/4 md:top-1/4 top-2/4 sm:-translate-y-1/2 z-10 !text-slate-700"
                btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                svgSize="w-6 h-6"
                onClickPrev={handleClickPrev}
              />
              <Next
                className="absolute right-1 sm:right-5 lg:top-3/4 xl:top-2/4 md:top-1/4 top-2/4 sm:-translate-y-1/2 z-10 !text-slate-700"
                btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                svgSize="w-6 h-6"
                onClickNext={handleAutoNext}
              />
            </div>
          );
        case 'text':
          return (
            <div
              className={`h-[35hv] lg:h-[40vh] xl:h-[60vh] 2xl:h-[75vh]  nc-SectionHero2Item nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
              key={index}
            >
              <div className="absolute bottom-2 xl:bottom-4 left-1/2 -translate-x-1/2 z-20 flex justify-center">
                {DATA.map((_, index) => {
                  const isActive = indexActive === index;
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setIndexActive(index);
                        handleAfterClick();
                      }}
                      className={`relative px-1 py-1.5 cursor-pointer`}
                    >
                      <div
                        className={`relative w-8 lg:w-20 h-0.5 lg:h-1 shadow-sm rounded-md bg-white`}
                      >
                        {isActive && (
                          <div
                            className={`nc-SectionHero2Item__dot w-[${intervalProgressText}] absolute inset-0 bg-slate-900 rounded-md ${
                              isActive ? ' ' : ' '
                            }`}
                          ></div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              <Prev
                className="absolute left-1 ssm:left-5 lg:top-3/4 xl:top-2/4 md:top-1/4 top-2/4 sm:-translate-y-1/2 z-10 !text-slate-700"
                btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                svgSize="w-6 h-6"
                onClickPrev={handleClickPrev}
              />
              <Next
                className="absolute right-1 sm:right-5 lg:top-3/4  xl:top-2/4 md:top-1/4 top-2/4 sm:-translate-y-1/2 z-10 !text-slate-700"
                btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                svgSize="w-6 h-6"
                onClickNext={handleAutoNext}
              />

              <div className="relative container pb-0 pt-14 sm:pt-20 lg:py-44">
                <div
                  className={`relative z-[1] w-full lg:w-3/5 xl:w-1/2 lg:-mt-20 xl:-mt-10 max-w-3xl max-md:text-center space-y-8 sm:space-y-14 nc-SectionHero2Item__left`}
                >
                  <div className="space-y-5 sm:space-y-6">
                    <h1 className="nc-SectionHero2Item__heading font-bold text-4xl sm:text-4xl md:text-5xl max-md:text-center xl:text-6xl 2xl:text-7xl !leading-[114%] text-neutral-900 dark:text-slate-50">
                      {item.heading}
                    </h1>
                    <span className="nc-SectionHero2Item__subheading block text-sm md:text-xl max-md:text-center font-light text-neutral-600 dark:text-neutral-50">
                      {item.subHeading}
                    </span>
                  </div>

                  <ButtonPrimary
                    className="nc-SectionHero2Item__button bg-black dark:bg-slate-900 border border-solid "
                    sizeClass="py-3 px-6 sm:py-4 sm:px-9"
                    href={item.btnLink as any}
                  >
                    <span>{item.btnText}</span>
                    <span>
                      <svg
                        className="w-5 h-5 ml-2.5"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M22 22L20 20"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </ButtonPrimary>
                </div>

                <div className="mt-10 lg:mt-0 lg:absolute lg:left-2/4 right-0 bottom-0 top-0 w-full max-w-2xl xl:max-w-3xl 2xl:max-w-4xl">
                  <img
                    className="w-full lg:w-3/4 xl:w-4/5 h-full ml-8 lg:object-contain xl:object-fill object-top xl:object-center nc-SectionHero2Item__image"
                    src={item.image}
                    alt={item.heading}
                  />
                </div>
              </div>
            </div>
          );
        default:
          return (
            <div
              className={`h-[35hv] lg:h-[40vh] xl:h-[60vh] 2xl:h-[75vh] w-full  nc-SectionHero2Item nc-SectionHero2Item--animation flex justify-center relative overflow-hidden ${className}`}
              key={index}
            >
              <div className="absolute bottom-2 lg:bottom-4  left-1/2 -translate-x-1/2 z-20 flex justify-center">
                {DATA.map((_, index) => {
                  const isActive = indexActive === index;
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        // setIndexActive(index);
                        handleAfterClick();
                      }}
                      className={`relative px-1 py-1.5 cursor-pointer`}
                    >
                      <div
                        className={`relative w-8 lg:w-20 h-0.5 lg:h-1 shadow-sm rounded-md bg-white`}
                      >
                        {isActive && (
                          <div
                            className={`nc-SectionHero2Item__dot w-[${intervalProgressImg}] absolute inset-0  bg-slate-900 rounded-md ${
                              isActive ? ' ' : ' '
                            }`}
                          ></div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <img
                className="mx-auto object-fill lg:w-full sm:object-contain md:object-scale-down lg:object-fill  xl:object-center nc-SectionHero2Item__image"
                src={item.image}
                alt={item.heading}
              />
              <Prev
                className="absolute left-1 sm:left-5 lg:top-3/4 xl:top-2/4 md:top-1/4 top-2/4 sm:-translate-y-1/2 z-10 !text-slate-700"
                btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                svgSize="w-6 h-6"
                onClickPrev={handleClickPrev}
              />
              <Next
                className="absolute right-1 sm:right-5 lg:top-3/4  xl:top-2/4 md:top-1/4 top-2/4 sm:-translate-y-1/2 z-10 !text-slate-700"
                btnClassName="w-12 h-12 hover:border-slate-400 dark:hover:border-slate-400"
                svgSize="w-6 h-6"
                onClickNext={handleAutoNext}
              />
            </div>
          );
      }
    }
  };

  return <>{DATA.map((_, index) => renderItem(index))}</>;
};

export default SectionHero2;
