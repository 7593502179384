import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { ProdutoService } from 'services/produtos';
import { IProduto, IProdutosResult } from 'services/produtos/types';
import { transformProdutos } from 'utils/transformToProduct';
import { Product } from 'data/data';
import { TabFiltersState } from 'components/TabFilters';
import { CategoriaService } from 'services/categorias';
import { ICategorias } from 'services/categorias/types';
import { IPageRequest } from 'types/pagination';
import { transformLinks } from 'utils/transformLinks';
import { IFavorites } from 'services/favorites/types';
import { FavoriteService } from 'services/favorites';

interface IProdutosByIdResult {
    produtos: Product[];
    links: any;
}
export const useProdutos = (props?: any) => {
    return useQuery<Product[], Error>(
        ['produtos', props],

        async (): Promise<Product[]> => {
            const response: AxiosResponse<IProdutosResult> =
                await ProdutoService.getAll(props);
            return transformProdutos(response.data.data);
        },

        {
            refetchInterval: 10000, // Revalidar a cada 10 segundos
            staleTime: 10000 // Tempo em que os dados são considerados "frescos"
        }
    );
};

// Hook para buscar um produto pelo ID
export const useProdutoById = (id: string) => {
    return useQuery<AxiosResponse<IProduto>>(['produto', id], () =>
        ProdutoService.getById({ id })
    );
};

export const useProdutosSearch = (
    searchName: string,
    filters: TabFiltersState,
    pagination: IPageRequest
) => {
    return useQuery<IProdutosByIdResult, Error>(
        ['produtos', searchName, filters, pagination],
        async () => {
            const response: AxiosResponse<IProdutosResult> =
                await ProdutoService.getAll({
                    search: searchName,
                    ...filters,
                    ...pagination
                });
            response.data.links.shift();
            response.data.links.pop();
            return {
                produtos: transformProdutos(response.data.data),
                links: transformLinks(response.data.links, searchName)
            };
        },
        {
            refetchInterval: 10000, // Revalidar a cada 10 segundos
            staleTime: 10000 // Tempo em que os dados são considerados "frescos"
        }
    );
};

export const useCategory = () => {
    return useQuery<ICategorias[], Error>(
        ['categorias'],
        async () => {
            const response: AxiosResponse<ICategorias[]> =
                await CategoriaService.getAll();
            return response.data;
        },
        {
            refetchInterval: 10000, // Revalidar a cada 10 segundos
            staleTime: 10000 // Tempo em que os dados são considerados "frescos"
        }
    );
};

export const useFavorites = (id: string) => {
    return useQuery<AxiosResponse<IProduto>>(['produto', id], () =>
        FavoriteService.getAll({ id })
    );
};
