import Label from 'components/Label/Label';
import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useProfile } from 'context/ProfileContext';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import Checkbox from 'shared/Checkbox/Checkbox';
import Select from 'shared/Select/Select';
import { IPaymentMethod } from './CheckoutPage';
import { useCart } from 'context/CartContext';

interface Props {
    isActive: boolean;
    onCloseActive: () => void;
    onOpenActive: () => void;
    payment: IPaymentMethod;
    onChange: (payment: IPaymentMethod) => void;
    errors: Record<string, string>;
}

const PaymentMethod: FC<Props> = ({
    isActive,
    onCloseActive,
    onOpenActive,
    payment,
    onChange,
    errors
}) => {
    const { t } = useTranslation();
    const [mothodActive, setMethodActive] = useState<
        'Credit-Card' | 'Internet-banking' | 'Wallet'
    >('Credit-Card');

    const { user } = useProfile();
    const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod>(payment);
    const [selectedOption, setSelectOption] = useState<string>('Credit-Card');
    const { totalPrice } = useCart();

    const formatCPF = (inputValue: string) => {
        return inputValue
            .replace(/\D/g, '')
            .slice(0, 15)
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked, id } = event.target;

        if (mothodActive === 'Credit-Card') {
            setPaymentMethod((prevState) => ({
                ...prevState,
                [name]: type === 'checkbox' ? checked : value
            }));
        }
    };

    const handleChangeCPF = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        const formatedCPF = formatCPF(value);
        if (mothodActive === 'Credit-Card') {
            setPaymentMethod((prevState) => ({
                ...prevState,
                cardCpf: formatedCPF
            }));
        }
    };

    const valueInstallments = (valor: number) => {
        const amount = Number(valor) || 0;
        const parts = [
            { name: `1x de ${amount.toFixed(2)} sem juros` },
            { name: `2x de ${(amount / 2).toFixed(2)} sem juros` },
            { name: `3x de ${(amount / 3).toFixed(2)} sem juros` },
            { name: `4x de ${(amount / 4).toFixed(2)} sem juros` },
            { name: `5x de ${(amount / 5).toFixed(2)} sem juros` },
            { name: `6x de ${(amount / 6).toFixed(2)} sem juros` },
            { name: `7x de ${(amount / 7).toFixed(2)} sem juros` },
            { name: `8x de ${(amount / 8).toFixed(2)} sem juros` },
            { name: `9x de ${(amount / 9).toFixed(2)} sem juros` },
            { name: `10x de ${(amount / 10).toFixed(2)} sem juros` },
            { name: `11x de ${(amount / 11).toFixed(2)} sem juros` },
            { name: `12x de ${(amount / 12).toFixed(2)} sem juros` }
        ];

        return parts;
    };

    const parts = valueInstallments(totalPrice);

    const handleChangeBoolean = (e: boolean) => {
        if (mothodActive === 'Credit-Card') {
            setPaymentMethod((prevState) => ({
                ...prevState,
                saveCard: e
            }));
        }
    };

    const handleChangeData = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { value } = event.target;
        const cardDate = formatDateCard(value);
        const [month, year] = cardDate.split('/').map(Number);
        if (month < 1 || month > 12) console.log('invalido');
        if (mothodActive === 'Credit-Card') {
            setPaymentMethod((prevData) => ({
                ...prevData,
                cardExpiration: cardDate
            }));
        }
        // }
    };

    const handleMethodPayment = (opt: string) => {
        if (opt === selectedOption) {
            setSelectOption('');
            setPaymentMethod((prevState) => ({
                ...prevState,
                cardType: '',
                pix: ''
            }));
        } else if (opt === 'Credit-Card') {
            setMethodActive(opt);
            setSelectOption(opt);
            setPaymentMethod((prevState) => ({
                ...prevState,
                cardType: opt,
                pix: ''
            }));
        } else {
            setMethodActive('Internet-banking');
            setSelectOption(opt);
            setPaymentMethod((prevState) => ({
                ...prevState,
                cardType: '',
                pix: opt
            }));
        }
    };

    const formatDateCard = (inputValue: string) => {
        return inputValue
            .replace(/\D/g, '')
            .slice(0, 4)
            .replace(/(\d{2})(\d{1,2})$/, '$1/$2');
    };

    const handleChangeCVC = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { value } = event.target;

        const cardSecurityCode = formatCVC(value);
        if (mothodActive === 'Credit-Card') {
            setPaymentMethod((prevData) => ({
                ...prevData,
                cardSecurityCode: cardSecurityCode
            }));
        }
    };

    const formatCVC = (inputValue: string) => {
        return inputValue.replace(/\D/g, '').slice(0, 5);
    };

    const handleChangeCardNumber = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { value } = event.target;
        const cardNumber = formatCardNumber(value);
        if (mothodActive === 'Credit-Card') {
            setPaymentMethod((prevData) => ({
                ...prevData,
                cardNumber: cardNumber
            }));
        }
    };

    const formatCardNumber = (inputValue: string) => {
        return inputValue
            .replace(/\D/g, '')
            .replace(/(\d{4})(\d)/, '$1.$2')
            .replace(/(\d{4})(\d)/, '$1.$2')
            .replace(/(\d{4})(\d)/, '$1.$2')
            .replace(/(\d{4})(\d)/, '$1.$2')
            .slice(0, 23);
    };

    const handleChangeInstallment =
        (field: keyof IPaymentMethod) =>
        (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const { value } = event.target;

            if (mothodActive === 'Credit-Card') {
                setPaymentMethod((prevData) => ({
                    ...prevData,
                    [field]: value
                }));
            }
        };

    useEffect(() => {
        onChange(paymentMethod);
    }, [paymentMethod]);

    const renderDebitCredit = () => {
        const active = mothodActive === 'Credit-Card';
        return (
            <div className="flex items-start space-x-4 sm:space-x-6">
                <label
                    className="relative flex items-center rounded-full cursor-pointer"
                    htmlFor="customStyle"
                >
                    <input
                        type="checkbox"
                        id="Credit-Card"
                        name="payment-method"
                        className={`focus:ring-action-primary text-gray-500 rounded-full border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-700 pt-3.5`}
                        onClick={() => setSelectOption(selectedOption)}
                        checked={selectedOption === 'Credit-Card'}
                        onChange={() => handleMethodPayment('Credit-Card')}
                    />
                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <div className="h-1 w-1 bg-white rounded-full"></div>
                    </span>
                </label>

                <div className="flex-1">
                    <label
                        htmlFor="Credit-Card"
                        className="flex items-center space-x-4 sm:space-x-6"
                    >
                        <div
                            className={`p-2.5 rounded-xl border-2 ${
                                active
                                    ? 'border-slate-600 dark:border-slate-300'
                                    : 'border-gray-200 dark:border-slate-600'
                            }`}
                        >
                            <svg
                                className="w-4 h-4 sm:w-4 sm:h-4"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M2 12.6101H19"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M19 10.28V17.43C18.97 20.28 18.19 21 15.22 21H5.78003C2.76003 21 2 20.2501 2 17.2701V10.28C2 7.58005 2.63 6.71005 5 6.57005C5.24 6.56005 5.50003 6.55005 5.78003 6.55005H15.22C18.24 6.55005 19 7.30005 19 10.28Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M22 6.73V13.72C22 16.42 21.37 17.29 19 17.43V10.28C19 7.3 18.24 6.55 15.22 6.55H5.78003C5.50003 6.55 5.24 6.56 5 6.57C5.03 3.72 5.81003 3 8.78003 3H18.22C21.24 3 22 3.75 22 6.73Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M5.25 17.8101H6.96997"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M9.10986 17.8101H12.5499"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>

                        <p className="font-medium">
                            <label>
                                {t(
                                    'Containers.PageCheckout.PaymentMethod.labelCardType'
                                )}{' '}
                            </label>
                        </p>
                    </label>

                    <div
                        className={`mt-6 mb-4 space-y-3 sm:space-y-5 ${
                            active ? 'block' : 'hidden'
                        }`}
                    >
                        <div className="max-w-lg">
                            <Label className="text-sm">
                                {t(
                                    'Containers.PageCheckout.PaymentMethod.labelCardCPF'
                                )}
                            </Label>
                            <Input
                                className="mt-1.5 rounded-md"
                                placeholder=""
                                name="cardCPF"
                                value={paymentMethod.cardCpf}
                                onChange={handleChangeCPF}
                            />
                            {errors.cardCpf && (
                                <p className="text-red-600 text-sm mt-1">
                                    {errors.cardCpf}
                                </p>
                            )}
                        </div>
                        <div className="max-w-lg">
                            <Label className="text-sm">
                                {t(
                                    'Containers.PageCheckout.PaymentMethod.labelCardNumber'
                                )}
                            </Label>
                            <Input
                                className="mt-1.5 rounded-md"
                                placeholder=". . . .   . . . .   . . . .   . . . ."
                                name="cardNumber"
                                value={paymentMethod.cardNumber}
                                onChange={handleChangeCardNumber}
                            />
                            {errors.cardNumber && (
                                <p className="text-red-600 text-sm mt-1">
                                    {errors.cardNumber}
                                </p>
                            )}
                        </div>
                        <div className="max-w-lg">
                            <Label className="text-sm">
                                {t(
                                    'Containers.PageCheckout.PaymentMethod.labelCardName'
                                )}{' '}
                            </Label>
                            <span className="text-xs">
                                (
                                {t(
                                    'Containers.PageCheckout.PaymentMethod.labelCardNameSpecification'
                                )}
                                )
                            </span>
                            <Input
                                className="mt-1.5 rounded-md"
                                placeholder=""
                                name="cardName"
                                value={paymentMethod.cardName}
                                onChange={handleChange}
                            />
                            {errors.cardName && (
                                <p className="text-red-600 text-sm mt-1">
                                    {errors.cardName}
                                </p>
                            )}
                        </div>
                        <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-1">
                            <div className="">
                                <Label className="text-sm">
                                    {t(
                                        'Containers.PageCheckout.PaymentMethod.labelCardExpiration'
                                    )}
                                </Label>
                                <Input
                                    className="mt-1.5 w-40 rounded-md"
                                    placeholder="MM/YY"
                                    name="cardExpiration"
                                    value={paymentMethod.cardExpiration}
                                    onChange={handleChangeData}
                                />
                                {errors.cardExpiration && (
                                    <p className="text-red-600 text-sm mt-1">
                                        {errors.cardExpiration}
                                    </p>
                                )}
                            </div>
                            <div className="">
                                <Label className="text-sm ml-2">
                                    {t(
                                        'Containers.PageCheckout.PaymentMethod.labelCardSecurityCode'
                                    )}
                                </Label>
                                <Input
                                    className="mt-1.5 w-40 rounded-md"
                                    placeholder="CVC"
                                    name="cardSecurityCode"
                                    value={paymentMethod.cardSecurityCode}
                                    onChange={handleChangeCVC}
                                />
                                {errors.cardSecurityCode && (
                                    <p className="text-red-600 text-sm mt-1">
                                        {errors.cardSecurityCode}
                                    </p>
                                )}
                            </div>
                            <div className="flex-2">
                                <Label className="text-sm ml-2">
                                    {t(
                                        'Containers.PageCheckout.PaymentMethod.labelCardInstallment'
                                    )}
                                </Label>
                                <Select
                                    className="mt-1.5 w-[185px] rounded-md"
                                    value={paymentMethod.cardInstallment}
                                    onChange={handleChangeInstallment(
                                        'cardInstallment'
                                    )}
                                >
                                    {parts.map((part, index) => (
                                        <option key={index} value={part.name}>
                                            {part.name}
                                        </option>
                                    ))}
                                </Select>
                                {errors.cardInstallment && (
                                    <p className="text-red-600 text-sm mt-1">
                                        {errors.cardInstallment}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="mt-8 grid grid-cols-1 gap-2 sm:gap-3">
                            <Checkbox
                                className="!text-xs font-medium"
                                name="saveCard"
                                label={`${t('Containers.PageCheckout.PaymentMethod.labelSaveCart')}`}
                                checked={paymentMethod.saveCard}
                                onChange={(e) => handleChangeBoolean(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderInterNetBanking = () => {
        const active = mothodActive === 'Internet-banking';
        return (
            <div className="flex items-start space-x-4 sm:space-x-6">
                <div
                    className={`flex items-center text-sm sm:text-base pt-3.5`}
                >
                    <label
                        className="relative flex items-center rounded-full cursor-pointer"
                        htmlFor="customStyle"
                    >
                        <input
                            type="checkbox"
                            id="Internet-banking"
                            name="payment-method"
                            onClick={() => setSelectOption(selectedOption)}
                            checked={selectedOption === 'Internet-banking'}
                            onChange={() =>
                                handleMethodPayment('Internet-banking')
                            }
                            className={`focus:ring-action-primary text-gray-500 rounded-full border-slate-400 hover:border-slate-700 bg-transparent dark:border-slate-700 dark:hover:border-slate-500 dark:checked:bg-primary-500 focus:ring-primary-700 pt-3.5`}
                        />
                        <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                            <div className="h-1 w-1 bg-white rounded-full"></div>
                        </span>
                    </label>
                </div>
                <div className="flex-1">
                    <label
                        htmlFor="Internet-banking"
                        className="flex items-center space-x-4 sm:space-x-6"
                    >
                        <div
                            className={`p-2.5 rounded-xl border-2 ${
                                active
                                    ? 'borQRCodeComponentder-slate-600 dark:border-slate-300'
                                    : 'border-gray-200 dark:border-slate-600'
                            }`}
                        >
                            <svg
                                className="w-4 h-4 sm:w-4 sm:h-4"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M7.99998 3H8.99998C7.04998 8.84 7.04998 15.16 8.99998 21H7.99998"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M15 3C16.95 8.84 16.95 15.16 15 21"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                        <p className="font-medium">Pix</p>
                    </label>
                    <div className={`mt-6 mb-4 ${active ? 'block' : 'hidden'}`}>
                        <p className="text-sm dark:text-slate-300">
                            <label>{`${t('Containers.PageCheckout.PaymentMethod.labelCardActiveInput')}`}</label>
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const renderPaymentMethod = () => {
        return (
            <div className="border border-slate-100 shadow-lg dark:border-slate-700 rounded-xl ">
                <div className="p-6 flex sm:flex-row items-center justify-between">
                    <span className="hidden sm:block">
                        <svg
                            className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.92969 15.8792L15.8797 3.9292"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M11.1013 18.2791L12.3013 17.0791"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M13.793 15.5887L16.183 13.1987"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M3.60127 10.239L10.2413 3.599C12.3613 1.479 13.4213 1.469 15.5213 3.569L20.4313 8.479C22.5313 10.579 22.5213 11.639 20.4013 13.759L13.7613 20.399C11.6413 22.519 10.5813 22.529 8.48127 20.429L3.57127 15.519C1.47127 13.419 1.47127 12.369 3.60127 10.239Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M2 21.9985H22"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                    <div className="sm:ml-8">
                        <h3 className=" text-slate-700 dark:text-slate-400 flex ">
                            <span className="uppercase tracking-tight">
                                {t(
                                    'Containers.PageCheckout.PaymentMethod.labelPaymentMethod'
                                )}
                            </span>
                        </h3>
                    </div>
                    <ButtonSecondary
                        sizeClass="py-2 px-4 "
                        fontSize="text-sm font-medium"
                        className="bg-slate-100 shadow dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
                        onClick={onOpenActive}
                    >
                        {t('Containers.PageCheckout.PaymentMethod.labelChange')}
                    </ButtonSecondary>
                </div>

                <div
                    className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-6 ${
                        isActive ? 'block' : 'hidden'
                    }`}
                >
                    {/* ==================== */}
                    <div>{renderDebitCredit()}</div>

                    {/* ==================== */}
                    <div>{renderInterNetBanking()}</div>

                    {/* ==================== */}
                    {/* <div>{renderWallet()}</div> */}

                    <div className="flex pt-6">
                        <ButtonPrimary
                            className="sm:!px-7 shadow-none"
                            onClick={onCloseActive}
                        >
                            {t(
                                'Containers.PageCheckout.PaymentMethod.labelConfirmOrder'
                            )}
                        </ButtonPrimary>
                        <ButtonSecondary
                            className="ml-3 border border-slate-100 shadow"
                            onClick={onCloseActive}
                        >
                            {t(
                                'Containers.PageCheckout.PaymentMethod.labelCancel'
                            )}
                        </ButtonSecondary>
                    </div>
                </div>
            </div>
        );
    };

    return renderPaymentMethod();
};

export default PaymentMethod;
