import React, { FC, useState } from 'react';
import facebookSvg from 'images/socials/facebook.svg';
import twitterSvg from 'images/socials/twitter.svg';
import googleSvg from 'images/socials/Google.svg';
import { Helmet } from 'react-helmet-async';
import Input from 'shared/Input/Input';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthService } from 'services/auth';
import notifyToaster from 'shared/Toaster/NotifyToaster';

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = '' }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const loginSocials = [
    {
      name: t('Containers.PageSignUp.labelContinueWithFacebook'),
      href: '#',
      icon: facebookSvg
    },
    {
      name: t('Containers.PageSignUp.labelContinueWithTwitter'),
      href: '#',
      icon: twitterSvg
    },
    {
      name: t('Containers.PageSignUp.labelContinueWithGoogle'),
      href: '#',
      icon: googleSvg
    }
  ];

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };
  const validateName = (name: string) => {
    return name.split(' ').length >= 2;
  };
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validatePassword = (password: string) => {
    return password.length >= 6;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    let hasError = false;
    e.preventDefault();
    setNameError('');
    setEmailError('');
    setPasswordError('');

    if (!validateName(name)) {
      setNameError(t('Containers.PageSignUp.labelInvalidName'));
      hasError = true;
    }

    if (!validateEmail(email)) {
      setEmailError(t('Containers.PageSignUp.labelInvalidEmail'));
      hasError = true;
    }
    if (!validatePassword(password)) {
      setPasswordError(t('Containers.PageSignUp.labelInvalidPassword'));
      hasError = true;
    }

    if (password !== confirmPassword) {
      setPasswordError(t('Containers.PageSignUp.labelPasswordsDoNotMatch'));
      hasError = true;
    }

    if (hasError) return;

    AuthService.register({
      name,
      email,
      password,
      password_confirmation: confirmPassword
    })
      .then((res) => {
        notifyToaster('success', res.data);
        navigate('/login');
      })
      .catch((err) => {
        notifyToaster('error', err.response.data);
      });
    // Proceed with form submission
  };

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>
          {t('Containers.PageSignUp.labelSignup')} || Ana Paula Professional
        </title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t('Containers.PageSignUp.labelSignup')}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              {t('Containers.PageSignUp.labelOR')}
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('Containers.PageSignUp.labelName')}
              </span>
              <Input
                type="text"
                placeholder={t('Containers.PageSignUp.labelPlaceholderName')}
                className="mt-1"
                value={name}
                onChange={handleNameChange}
              />
              {nameError && <div className="text-red-600">{nameError}</div>}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('Containers.PageSignUp.labelEmail')}
              </span>
              <Input
                type="email"
                placeholder={t('Containers.PageSignUp.labelPlaceholderEmail')}
                className="mt-1"
                value={email}
                onChange={handleEmailChange}
              />
              {emailError && <div className="text-red-600">{emailError}</div>}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t('Containers.PageSignUp.labelPassword')}
              </span>
              <Input
                type="password"
                className="mt-1"
                value={password}
                onChange={handlePasswordChange}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t('Containers.PageSignUp.labelConfirmPassword')}
              </span>
              <Input
                type="password"
                className="mt-1"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
            </label>
            {passwordError && (
              <div className="text-red-600">{passwordError}</div>
            )}
            <ButtonPrimary type="submit">
              {t('Containers.PageSignUp.labelContinue')}
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t('Containers.PageSignUp.labelAlreadyHaveAccount')} {` `}
            <Link className="text-green-600" to="/login">
              {t('Containers.PageSignUp.labelSignIn')}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
