import { SocialType } from 'shared/SocialsShare/SocialsShare';
import React, { FC } from 'react';
import facebook from 'images/socials/facebook.svg';
import telegram from 'images/socials/instagram.png';
import youtube from 'images/socials/youtube.svg';

export interface SocialsList1Props {
  className?: string;
}

const socials: SocialType[] = [
  {
    name: 'Instagram',
    icon: telegram,
    href: 'https://www.instagram.com/approfessionalof/'
  },
  {
    name: 'Facebook',
    icon: facebook,
    href: 'https://www.facebook.com/slanapaulacarvalhoapc'
  },
  {
    name: 'Youtube',
    icon: youtube,
    href: 'https://www.youtube.com/@anapaulacarvalho8435'
  }
];

const SocialsList1: FC<SocialsList1Props> = ({ className = 'space-y-3' }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className="flex items-center text-2xl text-gray-300 hover:text-gray-700 dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
      >
        <div className="flex-shrink-0 w-5 ">
          <img src={item.icon} alt="" />
        </div>
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {socials.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
