import React from 'react';
import SectionHowItWork from 'components/SectionHowItWork/SectionHowItWork';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionPromo1 from 'components/SectionPromo1';
import SectionHero2 from 'components/SectionHero/SectionHero2';
import SectionSliderLargeProduct from 'components/SectionSliderLargeProduct';
import SectionSliderProductCard from 'components/SectionSliderProductCard1';
import SectionSliderProductCard2 from 'components/SectionSliderProductCard2';
import DiscoverMoreSlider from 'components/DiscoverMoreSlider';
import SectionGridMoreExplore from 'components/SectionGridMoreExplore/SectionGridMoreExplore';
import SectionPromo2 from 'components/SectionPromo2';
import SectionGridFeatureItems from './SectionGridFeatureItems';
import SectionPromo3 from 'components/SectionPromo3';
import SectionMagazine5 from 'containers/BlogPage/SectionMagazine5';
import Heading from 'components/Heading/Heading';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Newsletter from 'shared/Newsletter/Newsletter';
import { PRODUCTS } from 'data/data';
import SectionVideos from './SectionVideos';

function PageHome() {
    return (
        <div className="nc-PageHome relative overflow-hidden">
            {/* SECTION SLIDE CAROUSEL*/}
            <SectionHero2 />
            {/* SECTION NEW REALESES */}
            <div className="mt-10 lg:mt-16">
                <DiscoverMoreSlider />
            </div>
            <div className="container relative space-y-16 my-20 lg:py-10 lg:my-2">
                {/* SECTION  SLIDE CARD SOLD*/}
                <SectionSliderProductCard
                    className="lg:py-10"
                    data={[PRODUCTS[4], PRODUCTS[7], PRODUCTS[6]]}
                />
                {/* SECTION */}
                <div className="relative py-6 lg:py-12">
                    <SectionPromo1 />
                </div>

                {/* SECTION SLIDE CARD */}

                <div className="relative py-2 lg:py-10">
                    <SectionSliderProductCard2
                        data={[PRODUCTS[8], PRODUCTS[7], PRODUCTS[6]]}
                    />
                </div>

                {/* SECTION CATEGORIES*/}
                <div className="relative py-6 lg:py-12">
                    <BackgroundSection />
                    <SectionGridMoreExplore />
                </div>

                {/* SECTION PRODUCTS*/}
                <div className="relative py-6 lg:py-24">
                    <SectionGridFeatureItems />
                </div>

                {/*  */}
                <div className="relative py-6 lg:py-12">
                    <SectionPromo2 />
                    <SectionVideos />
                </div>
                {/* SECTION 3 */}
                <div className="relative py-6 lg:py-12">
                    <SectionSliderLargeProduct cardStyle="style2" />
                </div>
                {/* SECTION */}
                <div className="relative py-6 lg:py-12">
                    <SectionPromo3 />
                </div>

                <div className="relative py-6 lg:py-8">
                    <SectionSliderProductCard
                        heading="Destaques"
                        subHeading="Os queridinhos da loja"
                    />
                </div>

                {/*SECTION PASSO A PASSO */}
                {/* <div className="relative py-24 lg:py-20">
                    <BackgroundSection />
                    <div>
                        <Heading rightDescText="Siga as técnicas para ter resultados incríveis">
                            Passo a passo
                        </Heading>
                        <SectionMagazine5 />
                    </div>
                </div>
                <div className="py-4 lg:py-2 dark:border-slate-700">
                </div> */}
                <Newsletter />
            </div>
        </div>
    );
}
export default PageHome;
