import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Input from 'shared/Input/Input';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useTranslation } from 'react-i18next';
import { AuthService } from 'services/auth';
import notifyToaster from 'shared/Toaster/NotifyToaster';
import { useSearchParams } from 'react-router-dom';

export interface PageResetPasswordProps {
  className?: string;
}

const PageResetPassword: FC<PageResetPasswordProps> = ({ className = '' }) => {
  const { token } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('key');
  let decodedKey = '';
  if (paramValue) {
    try {
      decodedKey = atob(paramValue); // Decodifica de base64
    } catch (e) {
      console.error('Erro ao decodificar base64:', e);
    }
  }
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const validatePassword = (password: string) => {
    return password.length >= 6;
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let hasError = false;

    if (!validatePassword(password)) {
      setPasswordError(t('Containers.PageResetPassword.labelInvalidPassword'));
      hasError = true;
    }

    if (password !== confirmPassword) {
      setPasswordError(
        t('Containers.PageResetPassword.labelPasswordsDoNotMatch')
      );
      hasError = true;
    }

    if (hasError) return;

    setPasswordError('');
    setEmailError('');
    token &&
      password &&
      AuthService.resetPassword({
        token,
        key: paramValue as string,
        password,
        password_confirmation: confirmPassword
      })
        .then((response) => {
          console.log(response);
          notifyToaster('success', response.data);
          navigate('/login');
        })
        .catch((error) => {
          notifyToaster('error', error.response.data);
        });
  };

  return (
    <div
      className={`nc-PageResetPassword ${className}`}
      data-nc-id="PageResetPassword"
    >
      <Helmet>
        <title>
          {t('Containers.PageResetPassword.labelResetPassword')} || Ana Paula
          Professional
        </title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t('Containers.PageResetPassword.labelResetPassword')}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('Containers.PageResetPassword.labelEmail')}
              </span>
              <Input
                type="email"
                placeholder={t(
                  'Containers.PageResetPassword.labelPlaceholderEmail'
                )}
                className="mt-1"
                value={decodedKey}
                disabled
              />
              {emailError && <div className="text-red-600">{emailError}</div>}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t('Containers.PageResetPassword.labelNewPassword')}
              </span>
              <Input
                type="password"
                placeholder={t(
                  'Containers.PageResetPassword.labelPlaceholderNewPassword'
                )}
                className="mt-1"
                value={password}
                onChange={handlePasswordChange}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t('Containers.PageResetPassword.labelConfirmPassword')}
              </span>
              <Input
                type="password"
                className="mt-1"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
            </label>
            {passwordError && (
              <div className="text-red-600">{passwordError}</div>
            )}
            <ButtonPrimary type="submit">
              {t('Containers.PageResetPassword.labelReset')}
            </ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageResetPassword;
