import React from 'react';
import MyRouter from 'routers/index';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>AP Professional</title>
        <meta name="description" content="www.anapaulacarvalho.com" />
      </Helmet>

      {/* MAIN APP */}
      <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200 scroll-smooth">
        <MyRouter />
      </div>
    </HelmetProvider>
  );
}

export default App;
