import { AxiosResponse } from 'axios';
import { IEndereco, IEnderecoProps } from './types';

import { axiosApi as enderecoApi } from '../index';

const baseUrlEndereco = '/api/v1/endereco';

export class EnderecoService {
  static async get(props?: IEnderecoProps): Promise<AxiosResponse<IEndereco>> {
    const url = baseUrlEndereco;
    return enderecoApi.get(url, { params: { ...props } });
  }
}
