import { Product } from 'data/data';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { FreteService } from 'services/frete';
import { useProfile } from './ProfileContext';
import { IFrete } from 'services/frete/types';
import { useCurrency } from './CurrencyContext';

export interface Item {
  id: number;
  product: Product;
  quantity?: number; // A quantidade é opcional, pois pode ser adicionada dinamicamente durante as operações do carrinho
  cupom?: string;
  discount?: number;
  shipping?: number;
}

type CartContextType = {
  cart: Item[];
  totalPrice: number;
  discount: number;
  totalWithDiscounts: number;
  shipping: number;
  shippingOptions?: IFrete[];
  loadCart: () => void;
  addOneProductToCart: (product: Item) => void;
  removeOneProductToCart: (product: Item) => void;
  removeManyProductToCart: (products: Item[]) => void;
  addManyProductToCart: (products: Item[]) => void;
  cleanCart: () => void;
  addDiscount: (discount: number) => void;
  setShipping: (value: number) => void;
};

const CartContext = createContext<CartContextType | undefined>(undefined);
export const CartProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { switchCurrency } = useCurrency();
  const { zipCode } = useProfile();
  const [cart, setCart] = useState([] as Item[]);
  const [discount, setDiscount] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [shippingOptions, setShippingOptions] = useState<IFrete[]>();
  const totalPrice = cart
    .reduce(
      (acc, item) => acc + switchCurrency(item.product) * (item?.quantity ?? 1),
      0
    )
    .toFixed(2) as unknown as number;
  const totalWithDiscounts = (totalPrice - discount + shipping).toFixed(
    2
  ) as unknown as number;

  useEffect(() => {
    loadCart();
  }, []);

  useEffect(() => {
    zipCode &&
      cart &&
      cart.length > 0 &&
      FreteService.get({
        cep: zipCode.replace(/\D/g, ''),
        produto_id: cart.map((item) => item.product.id)
      }).then((response) => {
        setShippingOptions(response.data);
      });
  }, [cart, zipCode]);

  const addOneProductToCart = (product: Item) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === product.id);
      if (existingProduct) {
        const newCart = prevCart.map((item) =>
          item.id === product.id
            ? {
                ...item,
                quantity: (item?.quantity ?? 0) + (product?.quantity ?? 0)
              }
            : item
        );
        localStorage.setItem('cart', JSON.stringify(newCart));
        return newCart;
      } else {
        // Se o produto não existe no carrinho, adiciona-o com quantidade 1
        const newCart = [
          ...prevCart,
          { ...product, quantity: product?.quantity ?? 1 }
        ];
        localStorage.setItem('cart', JSON.stringify(newCart));
        return newCart;
      }
    });
  };

  const removeOneProductToCart = (product: Item) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === product.id);
      if (existingProduct) {
        // Se o produto já existe no carrinho, diminui a quantidade
        const newCart = prevCart
          .map((item) =>
            item.id === product.id
              ? { ...item, quantity: (item?.quantity ?? 0) - 1 }
              : item
          )
          .filter((item) => item.quantity !== 0);
        localStorage.setItem('cart', JSON.stringify(newCart));
        return newCart;
      } else {
        // Se o produto não existe no carrinho, não faz nada
        return prevCart;
      }
    });
  };

  const cleanCart = () => {
    setCart([]);
  };

  const removeManyProductToCart = (products: Item[]) => {
    setCart((prevCart) => {
      // Cria um conjunto de IDs dos produtos a serem removidos
      const productsToRemoveIds = new Set(
        products.map((product) => product.id)
      );
      // Filtra os produtos do carrinho para remover aqueles cujo ID está no conjunto de IDs a serem removidos
      const newCart = prevCart.filter(
        (item) => !productsToRemoveIds.has(item.id)
      );
      localStorage.setItem('cart', JSON.stringify(newCart));
      return newCart;
    });
  };

  const addManyProductToCart = (products: Item[]) => {
    setCart((prevCart) => {
      // Cria um mapa de produtos existentes no carrinho para facilitar a busca por ID
      const cartMap = new Map(prevCart.map((item) => [item.id, item]));
      // Atualiza a quantidade dos produtos existentes no carrinho ou adiciona novos produtos
      products.forEach((product) => {
        const existingProduct = cartMap.get(product.id);
        if (existingProduct) {
          // Se o produto já existe no carrinho, atualiza a quantidade
          cartMap.set(product.id, {
            ...existingProduct,
            quantity:
              (existingProduct?.quantity ?? 0) + (product?.quantity ?? 0)
          });
        } else {
          // Se o produto não existe no carrinho, adiciona-o com a quantidade fornecida
          cartMap.set(product.id, { ...product });
        }
      });
      // Retorna o novo array de produtos no carrinho
      const newCart = Array.from(cartMap.values());
      setCart(newCart);
      localStorage.setItem('cart', JSON.stringify(newCart));
      return newCart;
    });
  };

  const loadCart = () => {
    const cart = localStorage.getItem('cart');
    if (cart) {
      setCart(JSON.parse(cart));
    }
  };

  const addDiscount = (discount: number) => {
    setDiscount(discount);
  };
  return (
    <CartContext.Provider
      value={{
        cart,
        totalPrice,
        discount,
        totalWithDiscounts,
        shippingOptions,
        shipping,
        loadCart,
        addOneProductToCart,
        removeOneProductToCart,
        cleanCart,
        removeManyProductToCart,
        addManyProductToCart,
        addDiscount,
        setShipping
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error(
      'useLanguage deve ser utilizado dentro de um LanguageProvider'
    );
  }
  return context;
};
