import React, { useState } from 'react';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import Logo from 'shared/Logo/Logo';
import facebookSvg from 'images/socials/facebook.svg';
import twitterSvg from 'images/socials/twitter.svg';
import googleSvg from 'images/socials/Google.svg';
import SwitchDarkMode from 'shared/SwitchDarkMode/SwitchDarkMode';
import { useTranslation } from 'react-i18next';
import SwitchLangMode from 'shared/SwitchLangMode/SwitchLangMode';
import SwitchCurrencyMode from 'shared/SwitchCurrencyMode/SwitchCurrencyMode';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import { avatarImgs } from 'contains/fakeData';
import { useProfile } from 'context/ProfileContext';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
import { AuthService } from 'services/auth';
import saveOrUpdateToken, { deleteToken } from 'utils/authorizarionToken';
import notifyToaster from 'shared/Toaster/NotifyToaster';

export interface NavMobileProps {
  onClickClose?: () => void;
}

const loginSocials = [
  {
    name: 'Facebook',
    href: '#',
    icon: facebookSvg
  },
  {
    name: 'Twitter',
    href: '#',
    icon: twitterSvg
  },
  {
    name: 'Google',
    href: '#',
    icon: googleSvg
  }
];

const NavMobile: React.FC<NavMobileProps> = ({ onClickClose }) => {
  const { t } = useTranslation();
  const { user, changeUser } = useProfile();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const _renderMenuChild = (
  //   item: NavItemType,
  //   itemClass = " pl-3 text-neutral-900 dark:text-neutral-200 font-medium "
  // ) => {
  //   return (
  //     <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
  //       {item.children?.map((i, index) => (
  //         <Disclosure key={i.href + index} as="li">
  //           <NavLink
  //             to={{
  //               pathname: i.href || undefined,
  //             }}
  //             className={({ isActive }) =>
  //               `flex text-sm rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 ${itemClass} ${isActive ? "text-secondary" : ""
  //               }`
  //             }
  //           >
  //             <span
  //               className={`py-2.5 ${!i.children ? "block w-full" : ""}`}
  //               onClick={onClickClose}
  //             >
  //               {i.name}
  //             </span>
  //             {i.children && (
  //               <span
  //                 className="flex items-center flex-grow"
  //                 onClick={(e) => e.preventDefault()}
  //               >
  //                 <Disclosure.Button
  //                   as="span"
  //                   className="flex justify-end flex-grow"
  //                 >
  //                   <ChevronDownIcon
  //                     className="ml-2 h-4 w-4 text-slate-500"
  //                     aria-hidden="true"
  //                   />
  //                 </Disclosure.Button>
  //               </span>
  //             )}
  //           </NavLink>
  //           {i.children && (
  //             <Disclosure.Panel>
  //               {_renderMenuChild(
  //                 i,
  //                 "pl-3 text-slate-600 dark:text-slate-400 "
  //               )}
  //             </Disclosure.Panel>
  //           )}
  //         </Disclosure>
  //       ))}
  //     </ul>
  //   );
  // };

  // const _renderItem = (item: NavItemType, index: number) => {
  //   return (
  //     <Disclosure
  //       key={index}
  //       as="li"
  //       className="text-slate-900 dark:text-white"
  //     >
  //       <NavLink
  //         className={({ isActive }) =>
  //           `flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg ${isActive ? "text-secondary" : ""
  //           }`
  //         }
  //         to={{
  //           pathname: item.href || undefined,
  //         }}
  //       >
  //         <span
  //           className={!item.children ? "block w-full" : ""}
  //           onClick={onClickClose}
  //         >
  //           {item.name}
  //         </span>
  //         {item.children && (
  //           <span
  //             className="block flex-grow"
  //             onClick={(e) => e.preventDefault()}
  //           >
  //             <Disclosure.Button
  //               as="span"
  //               className="flex justify-end flex-grow"
  //             >
  //               <ChevronDownIcon
  //                 className="ml-2 h-4 w-4 text-neutral-500"
  //                 aria-hidden="true"
  //               />
  //             </Disclosure.Button>
  //           </span>
  //         )}
  //       </NavLink>
  //       {item.children && (
  //         <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
  //       )}
  //     </Disclosure>
  //   );
  // };

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    AuthService.login({ email, password })
      .then((response) => {
        saveOrUpdateToken(response.data.authorization.token);
        changeUser({
          fullName: response.data.user.name,
          email: response.data.user.email,
          dateOfBirth: Date.now().toString(),
          address: 'Teresina, PI',
          gender: 'Male',
          phone: '',
          about: 'Teste de descrição do usuário'
        });
        setEmail('');
        setPassword('');
        navigate('/');
      })
      .catch((error) => {
        notifyToaster('error', error.response.data);
      });
  };
  const handleLogout = () => {
    changeUser(undefined);
    deleteToken();
    navigate('/');
  };
  const renderSearchForm = () => {
    return (
      <form
        action=""
        method="POST"
        className="flex-1 text-slate-900 dark:text-slate-200"
      >
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1 py-2 px-4 rounded-xl h-full">
          {renderMagnifyingGlassIcon()}
          <input
            type="search"
            placeholder={t('Header.SearchDropdown.labelPlaceholder')} // TODO: REPLACE THE PATH AFTER
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-sm "
          />
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };
  const close = () => {
    // Add your code here to handle the close action
  };

  return (
    <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="flex flex-col gap-4 py-6 px-5">
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
        <Logo className="invert dark:invert-0" />
        <div className="pb-2">{renderSearchForm()}</div>
        <ul className="flex flex-col px-2 space-y-1">
          <div className="relative grid grid-cols-1 gap-6">
            <div className="flex items-center space-x-3">
              {user && (
                <>
                  <Avatar imgUrl={avatarImgs[7]} sizeClass="w-12 h-12" />
                  <div className="flex-grow">
                    <h4 className="font-semibold dark:text-white">
                      {user.fullName}
                    </h4>
                    <p className="text-xs mt-0.5 dark:text-white">
                      {user.address}
                    </p>
                  </div>
                </>
              )}
              <SwitchDarkMode className="w-[40px] h-[40px] bg-neutral-100 dark:bg-neutral-800" />
              <SwitchLangMode className="w-[40px] h-[40px] bg-neutral-100 dark:bg-neutral-800" />
              <SwitchCurrencyMode className="w-[40px] h-[40px] bg-neutral-100 dark:bg-neutral-800" />
            </div>
            {user ? (
              <>
                <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />

                {/* ------------------ 1 --------------------- */}
                <Link
                  to={'/account'}
                  className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                  onClick={() => close()}
                >
                  <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="ml-4">
                    <p className="text-sm font-medium dark:text-white">
                      {t('Header.AvatarDropdown.labelAccount')}
                    </p>
                  </div>
                </Link>

                {/* ------------------ 2 --------------------- */}
                <Link
                  to={'/checkout'}
                  className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                  onClick={() => close()}
                >
                  <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M8 12.2H15"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 16.2H12.38"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 6H14C16 6 16 5 16 4C16 2 15 2 14 2H10C9 2 8 2 8 4C8 6 9 6 10 6Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 4.02002C19.33 4.20002 21 5.43002 21 10V16C21 20 20 22 15 22H9C4 22 3 20 3 16V10C3 5.44002 4.67 4.20002 8 4.02002"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="ml-4">
                    <p className="text-sm font-medium dark:text-white ">
                      {t('Header.AvatarDropdown.labelOrders')}
                    </p>
                  </div>
                </Link>

                {/* ------------------ 2 --------------------- */}
                <Link
                  to={'/account-savelists'}
                  className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                  onClick={() => close()}
                >
                  <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="ml-4">
                    <p className="text-sm font-medium dark:text-white ">
                      {t('Header.AvatarDropdown.labelWishlist')}
                    </p>
                  </div>
                </Link>

                <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />

                {/* ------------------ 2 --------------------- */}
                <Link
                  to={'/#'}
                  className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                  onClick={() => close()}
                >
                  <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.97 22C17.4928 22 21.97 17.5228 21.97 12C21.97 6.47715 17.4928 2 11.97 2C6.44715 2 1.97 6.47715 1.97 12C1.97 17.5228 6.44715 22 11.97 22Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.89999 4.92993L8.43999 8.45993"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.89999 19.07L8.43999 15.54"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.05 19.07L15.51 15.54"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.05 4.92993L15.51 8.45993"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="ml-4">
                    <p className="text-sm font-medium dark:text-white ">
                      {t('Header.AvatarDropdown.labelHelp')}
                    </p>
                  </div>
                </Link>

                {/* ------------------ 2 --------------------- */}
                <Link
                  to={'/#'}
                  className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                  onClick={() => {
                    handleLogout();
                    close();
                  }}
                >
                  <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15 12H3.62"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.85 8.6499L2.5 11.9999L5.85 15.3499"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="ml-4">
                    <p className="text-sm font-medium dark:text-white ">
                      {t('Header.AvatarDropdown.labelLogout')}
                    </p>
                  </div>
                </Link>
              </>
            ) : (
              <div className="flex flex-col gap-3 ">
                <form
                  className="grid grid-cols-1 gap-6"
                  onSubmit={handleSubmit}
                >
                  <label className="block ">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      {t('Header.AvatarDropdown.labelEmail')}
                    </span>
                    <Input
                      type="email"
                      placeholder={t(
                        'Header.AvatarDropdown.labelPlaceholderEmail'
                      )}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="mt-1 dark:text-slate-200"
                      tabIndex={1}
                    />
                  </label>
                  <label className="block">
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                      {t('Header.AvatarDropdown.labelPassword')}
                      <Link
                        to="/forgot-pass"
                        className="text-xs text-green-600"
                      >
                        {t('Containers.PageLogin.labelForgotPassword')}
                      </Link>
                    </span>
                    <Input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="mt-1 dark:text-slate-200"
                      tabIndex={2}
                    />
                  </label>
                  <ButtonPrimary type="submit">
                    {t('Header.AvatarDropdown.labelContinue')}
                  </ButtonPrimary>
                </form>

                <div className="flex gap-3 text-center self-center w-full">
                  {loginSocials.map((item, index) => (
                    <a
                      key={index}
                      href={item.href}
                      className="flex w-full justify-center gap-2 rounded-lg bg-primary-50 dark:bg-slate-300 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                    >
                      <img
                        className="flex-shrink-0"
                        src={item.icon}
                        alt={item.name}
                      />
                    </a>
                  ))}
                </div>
                <div className="flex items-center gap-3">
                  <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
                  {t('Header.AvatarDropdown.labelOr')}
                  <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
                </div>
                <div className="flex gap-3 ">
                  <ButtonPrimary href="/signup" className="flex-1">
                    {t('Header.AvatarDropdown.labelRegister')}
                  </ButtonPrimary>
                </div>
              </div>
            )}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default NavMobile;
