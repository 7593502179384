import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ILink } from 'types/pagination';
import twFocusClass from 'utils/twFocusClass';

const DEMO_PAGINATION: ILink[] = [
    {
        label: '1',
        url: '#',
        active: true
    },
    {
        label: '2',
        url: '#',
        active: false
    },
    {
        label: '3',
        url: '#',
        active: false
    },
    {
        label: '4',
        url: '#',
        active: false
    }
];

export interface PaginationProps {
    className?: string;
    links?: ILink[];
    onPageChange?: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
    className = '',
    links,
    onPageChange
}) => {
    const handleClick = (page: number) => {
        if (onPageChange) {
            onPageChange(page);
        }
    };

    const renderItem = (pag: ILink, index: number) => {
        if (pag.active) {
            // RETURN ACTIVE PAGINATION
            return (
                <span
                    key={index}
                    className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-neutral-100 text-black dark:bg-neutral-800 dark:text-white ${twFocusClass()}`}
                >
                    {pag.label}
                </span>
            );
        }
        // RETURN UNACTIVE PAGINATION
        return (
            <a
                key={index}
                className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-[#000] hover:bg-neutral-100 border border-neutral-200 text-white dark:text-black dark:bg-[#fff] dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
                href={pag.url}
                onClick={() => handleClick(index + 1)} // Passa o número da página
            >
                {pag.label}
            </a>
        );
    };

    return (
        <nav
            className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
        >
            {links?.map(renderItem)}
        </nav>
    );
};

export default Pagination;
