import React, { FC, useEffect, useId, useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import NcImage from 'shared/NcImage/NcImage';
import ModalPhotos from './ModalPhotos';
import ReviewItem from 'components/ReviewItem';
import { Product } from 'data/data';
import NcInputNumber from 'components/NcInputNumber';
import BagIcon from 'components/BagIcon';
import Policy from './Policy';
import toast from 'react-hot-toast';
import { StarIcon } from '@heroicons/react/24/solid';
import SectionSliderProductCard from 'components/SectionSliderProductCard1';
import ModalViewAllReviews from './ModalViewAllReviews';
import NotifyAddTocart from 'components/NotifyAddTocart';
import { useCart } from 'context/CartContext';
import { useTranslation } from 'react-i18next';
import { ProdutoService } from 'services/produtos';
import { transformProdutos } from 'utils/transformToProduct';
import { useCurrency } from 'context/CurrencyContext';
import { useParams } from 'react-router-dom';
import './AccordionStyle.css';

export interface ProductDetailPage2Props {
  className?: string;
}

interface RouteParams {
  id: string;
}

const ProductDetailPage2: FC<ProductDetailPage2Props> = ({
  className = ''
}) => {
  const { id } = useParams<Record<string, string>>();
  const { addOneProductToCart } = useCart();
  const { t } = useTranslation();
  const { currency } = useCurrency();
  const [data, setData] = useState<Product>({} as Product);
  const { sizes, variants, allOfSizes, price } = data;
  const LIST_IMAGES_DEMO = data.image
    ? [data.image, data.image, data.image, data.image]
    : [data.image];

  const [variantActive, setVariantActive] = React.useState(0);
  const [sizeSelected, setSizeSelected] = React.useState(sizes ? sizes[0] : '');
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] =
    useState(false);

  useEffect(() => {
    id &&
      ProdutoService.getById({ id }).then((res) => {
        const dataFiltered = transformProdutos([res.data]);
        setData(dataFiltered[0]);
      });
  }, [id]);

  const [isOpen, setIsOpen] = useState(false);
  useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  //
  const renderVariants = () => {
    if (!variants || !variants.length) {
      return null;
    }

    return (
      <div>
        <label htmlFor="">
          <span className="text-sm font-medium">
            Color:
            <span className="ml-1 font-semibold">
              {variants[variantActive].name}
            </span>
          </span>
        </label>
        <div className="flex mt-3">
          {variants.map((variant, index) => (
            <div
              key={index}
              onClick={() => setVariantActive(index)}
              className={`relative flex-1 max-w-[75px] h-10 sm:h-11 rounded-full border-2 cursor-pointer ${
                variantActive === index
                  ? 'border-primary-6000 dark:border-primary-500'
                  : 'border-transparent'
              }`}
            >
              <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
                <img
                  src={variant.thumbnail}
                  alt=""
                  className="absolute w-full h-full object-cover"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const notifyAddTocart = () => {
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={LIST_IMAGES_DEMO[0]}
          qualitySelected={qualitySelected}
          show={t.visible}
          sizeSelected={sizeSelected}
          variantActive={variantActive}
        />
      ),
      { position: 'top-right', id: 'nc-product-notify', duration: 3000 }
    );
  };

  const renderSizeList = () => {
    if (!allOfSizes || !sizes || !sizes.length) {
      return null;
    }
    return (
      <div>
        <div className="flex justify-between font-medium text-sm">
          <label htmlFor="">
            <span className="">
              Size:
              <span className="ml-1 font-semibold">{sizeSelected}</span>
            </span>
          </label>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="##"
            className="text-primary-6000 hover:text-primary-500"
          >
            See sizing chart
          </a>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-3">
          {allOfSizes.map((size, index) => {
            const isActive = size === sizeSelected;
            const sizeOutStock = !sizes.includes(size);
            return (
              <div
                key={index}
                className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${
                  sizeOutStock
                    ? 'text-opacity-20 dark:text-opacity-20 cursor-not-allowed'
                    : 'cursor-pointer'
                } ${
                  isActive
                    ? 'bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000'
                    : 'border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700'
                }`}
                onClick={() => {
                  if (sizeOutStock) {
                    return;
                  }
                  setSizeSelected(size);
                }}
              >
                {size}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  // const renderStatus = () => {
  //   if (!status) {
  //     return null;
  //   }
  //   const CLASSES =
  //     "text-sm flex items-center text-slate-700 text-slate-900 dark:text-slate-300";
  //   if (status === "New in") {
  //     return (
  //       <div className={CLASSES}>
  //         <SparklesIcon className="w-3.5 h-3.5" />
  //         <span className="ml-1 leading-none">{status}</span>
  //       </div>
  //     );
  //   }
  //   if (status === "50% Discount") {
  //     return (
  //       <div className={CLASSES}>
  //         <IconDiscount className="w-3.5 h-3.5" />
  //         <span className="ml-1 leading-none">{status}</span>
  //       </div>
  //     );
  //   }
  //   if (status === "Sold Out") {
  //     return (
  //       <div className={CLASSES}>
  //         <NoSymbolIcon className="w-3.5 h-3.5" />
  //         <span className="ml-1 leading-none">{status}</span>
  //       </div>
  //     );
  //   }
  //   if (status === "limited edition") {
  //     return (
  //       <div className={CLASSES}>
  //         <ClockIcon className="w-3.5 h-3.5" />
  //         <span className="ml-1 leading-none">{status}</span>
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  const renderSectionSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap lg:shadow-lg float-none  lg:float-right">
        <div className="space-y-7 lg:space-y-8">
          {/* price */}
          <div className="">
            {/* ---------- 1 HEADING ----------  */}
            <div className="flex items-center justify-between space-x-5">
              <div className="flex text-2xl font-semibold">
                {currency}
                {price}
              </div>
            </div>

            {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
            <div className="mt-6 space-y-7 lg:space-y-8">
              <div className="">{renderVariants()}</div>
              <div className="">{renderSizeList()}</div>
            </div>
          </div>
          {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
          <div className="flex space-x-3.5">
            <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
              <NcInputNumber
                defaultValue={qualitySelected}
                onChange={setQualitySelected}
              />
            </div>
            <ButtonPrimary
              className="flex-1 flex-shrink-0"
              onClick={() => {
                notifyAddTocart();
                addOneProductToCart({
                  id: data.id,
                  product: data,
                  quantity: qualitySelected
                });
              }}
            >
              <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
              <span className="ml-3">
                {t(
                  'Containers.ProductDetailPage.ProductDetailPage2.labelAddToCart'
                )}
              </span>
            </ButtonPrimary>
          </div>

          {/* SUM */}
          <div className="hidden sm:flex flex-col space-y-4 ">
            <div className="space-y-2.5">
              <div className="flex justify-between text-slate-600 dark:text-slate-300">
                <span className="flex">
                  <span>{`${currency}${price}  `}</span>
                  <span className="mx-2">x</span>
                  <span>{`${qualitySelected} `}</span>
                </span>

                <span>{`${currency} ${price * qualitySelected}`}</span>
              </div>
              <div className="flex justify-between text-slate-600 dark:text-slate-300">
                <span>
                  {t(
                    'Containers.ProductDetailPage.ProductDetailPage2.labelTaxEstimate'
                  )}
                </span>
                <span>{currency}0</span>
              </div>
            </div>
            <div className="border-b border-slate-200 dark:border-slate-700"></div>
            <div className="flex justify-between font-semibold">
              <span>Total</span>
              <span>{`${currency}${(price * qualitySelected).toFixed(2)}`}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const renderSection1 = () => {
  //   return (
  //     <div className="listingSection__wrap !space-y-6">
  //       <div>
  //         <h2 className="text-2xl md:text-3xl font-semibold">
  //           {data.name}
  //         </h2>
  //         <div className="flex items-center mt-4 sm:mt-5">
  //           <a
  //             href="#reviews"
  //             className="hidden sm:flex items-center text-sm font-medium "
  //           >
  //             <div className="">
  //               <StarIcon className="w-5 h-5 pb-[1px] text-slate-800 dark:text-slate-200" />
  //             </div>
  //             <span className="ml-1.5">
  //               <span>4.9</span>
  //               <span className="mx-1.5">·</span>
  //               <span className="text-slate-700 dark:text-slate-400 underline">
  //                 142 reviews
  //               </span>
  //             </span>
  //           </a>
  //           <span className="hidden sm:block mx-2.5">·</span>
  //           {renderStatus()}

  //           <div className="ml-auto">
  //             <LikeSaveBtns />
  //           </div>
  //         </div>
  //       </div>
  //       {/*  */}
  //       <div className="block lg:hidden">{renderSectionSidebar()}</div>

  //       {/*  */}
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/*  */}
  //       <AccordionInfo panelClassName="p-4 pt-3.5 text-slate-600 text-base dark:text-slate-300 leading-7" />
  //     </div>
  //   );
  // };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap !border-b-0 !pb-0">
        <h2 className="text-2xl font-semibold">
          {t(
            'Containers.ProductDetailPage.ProductDetailPage2.labelProductDetails'
          )}
        </h2>
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div> */}
        <div
          className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl custom-panel"
          dangerouslySetInnerHTML={{ __html: data.description }}
        ></div>
        {/* ---------- 6 ----------  */}
        <Policy />
      </div>
    );
  };

  const renderReviews = () => {
    return (
      <div id="reviews" className="scroll-mt-[150px]">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold flex items-center">
          <StarIcon className="w-7 h-7 mb-0.5" />
          <span className="ml-1.5"> 4,87 · 142 Reviews</span>
        </h2>

        {/* comment */}
        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-11 gap-x-28">
            <ReviewItem
              data={{
                comment: t('Components.ReviewItem.labelReviewComent4'),
                date: 'December 22, 2021',
                name: 'Brooke D.',
                starPoint: 5
              }}
            />
            <ReviewItem
              data={{
                comment: t('Components.ReviewItem.labelReviewComent1'),
                date: 'December 22, 2021',
                name: 'Jane A.',
                starPoint: 5
              }}
            />
            <ReviewItem
              data={{
                comment: t('Components.ReviewItem.labelReviewComent2'),
                date: 'August 15, 2022',
                name: 'Sara J.M.',
                starPoint: 5
              }}
            />
            <ReviewItem
              data={{
                comment: t('Components.ReviewItem.labelReviewComent3'),
                date: 'December 12, 2022',
                name: 'Collen H.',
                starPoint: 5
              }}
            />
          </div>

          <ButtonSecondary
            onClick={() => setIsOpenModalViewAllReviews(true)}
            className="mt-10 border border-slate-300 dark:border-slate-700 "
          >
            Show me all 142 reviews
          </ButtonSecondary>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`ListingDetailPage nc-ProductDetailPage2 ${className}`}
      data-nc-id="ProductDetailPage2"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container mt-8 sm:mt-10">
          <div className="relative ">
            <div className="grid grid-cols-2 md:grid-cols-3 gap-3 lg:gap-6">
              <div
                className="col-span-2 md:col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(0)}
              >
                <NcImage
                  containerClassName="aspect-w-3 aspect-h-4 md:absolute md:inset-0"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={LIST_IMAGES_DEMO[0]}
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-40 transition-opacity"></div>
              </div>

              {/*  */}
              <div
                className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(1)}
              >
                <NcImage
                  containerClassName="absolute inset-0"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={LIST_IMAGES_DEMO[0]}
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-40 transition-opacity"></div>
              </div>

              {/*  */}
              {[LIST_IMAGES_DEMO[0], LIST_IMAGES_DEMO[0]].map((item, index) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                    index >= 2 ? 'block' : ''
                  }`}
                >
                  <NcImage
                    containerClassName="aspect-w-6 aspect-h-5 lg:aspect-h-4"
                    className="object-cover w-full h-full rounded-md sm:rounded-xl "
                    src={item || ''}
                  />

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-slate-900 bg-opacity-20 opacity-0 hover:opacity-60 transition-opacity cursor-pointer"
                    onClick={() => handleOpenModal(index + 2)}
                  />
                </div>
              ))}
            </div>
            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-white text-slate-500 cursor-pointer hover:bg-slate-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={LIST_IMAGES_DEMO}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ProductDetailPage2__modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="block lg:w-full container relative z-10 mt-9 sm:mt-11 lg:flex ">
        {/* PRICE | ADD TO CARD*/}
        <div className="flex-grow lg:hidden">
          {' '}
          <div className="block mb-8 lg:mb-0 lg:block sticky top-28">
            {renderSectionSidebar()}
          </div>
        </div>

        {/* CONTENT PRODUCT DETAIL*/}
        <div className="w-full lg:w-full xl:w-2/3 space-y-10 lg:pr-14 lg:space-y-14">
          {renderSection2()}
        </div>

        <div className="flex-grow xm:hidden lg:w-full">
          <div className="block mb-8 lg:mb-0 lg:block top-28">
            {renderSectionSidebar()}
          </div>
        </div>
      </main>

      {/* OTHER SECTION */}
      <div className="container pb-24 lg:pb-28 pt-14 space-y-14">
        <hr className="border-slate-200 dark:border-slate-700" />

        {renderReviews()}

        <hr className="border-slate-200 dark:border-slate-700" />

        <SectionSliderProductCard
          heading={t(
            'Containers.ProductDetailPage.ProductDetailPage2.labelRelatedProducts'
          )}
          subHeading=""
          headingFontClassName="text-2xl font-semibold"
          headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
        />
      </div>

      {/* MODAL VIEW ALL REVIEW */}
      <ModalViewAllReviews
        show={isOpenModalViewAllReviews}
        onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)}
      />
    </div>
  );
};

export default ProductDetailPage2;
