import React, { FC, useState } from 'react';
import facebookSvg from 'images/socials/facebook.svg';
import twitterSvg from 'images/socials/twitter.svg';
import googleSvg from 'images/socials/Google.svg';
import { Helmet } from 'react-helmet-async';
import Input from 'shared/Input/Input';
import { Link, useNavigate } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useTranslation } from 'react-i18next';
import { AuthService } from 'services/auth';
import notifyToaster from 'shared/Toaster/NotifyToaster';
import { useProfile } from 'context/ProfileContext';
import saveOrUpdateToken from 'utils/authorizarionToken';

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = '' }) => {
  const { t } = useTranslation();
  const { changeUser } = useProfile();
  const navigate = useNavigate();

  const loginSocials = [
    {
      name: t('Containers.PageLogin.labelContinueWithFacebook'),
      href: '#',
      icon: facebookSvg
    },
    {
      name: t('Containers.PageLogin.labelContinueWithTwitter'),
      href: '#',
      icon: twitterSvg
    },
    {
      name: t('Containers.PageLogin.labelContinueWithGoogle'),
      href: '#',
      icon: googleSvg
    }
  ];

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEmailError('');

    if (!validateEmail(email)) {
      setEmailError(t('Containers.PageLogin.labelInvalidEmail'));
      return;
    }

    AuthService.login({ email, password })
      .then((response) => {
        saveOrUpdateToken(response.data.authorization.token);
        changeUser({
          fullName: response.data.user.name,
          email: response.data.user.email,
          dateOfBirth: Date.now().toString(),
          address: 'Teresina, PI',
          gender: 'Male',
          phone: '',
          about: 'Teste de descrição do usuário'
        });
        navigate('/');
      })
      .catch((error) => {
        notifyToaster('error', error.response.data);
      });
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>
          {t('Containers.PageLogin.labelLogin')} || Ana Paula Professional
        </title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t('Containers.PageLogin.labelLogin')}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              {t('Containers.PageLogin.labelOR')}
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('Containers.PageLogin.labelEmail')}
              </span>
              <Input
                type="email"
                placeholder={t('Containers.PageLogin.labelPlaceholderEmail')}
                className="mt-1"
                value={email}
                onChange={handleEmailChange}
              />
              {emailError && <div className="text-red-600">{emailError}</div>}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t('Containers.PageLogin.labelPassword')}
                <Link to="/forgot-pass" className="text-sm text-green-600">
                  {t('Containers.PageLogin.labelForgotPassword')}
                </Link>
              </span>
              <Input
                type="password"
                className="mt-1"
                value={password}
                onChange={handlePasswordChange}
              />
            </label>
            <ButtonPrimary type="submit">
              {t('Containers.PageLogin.labelContinue')}
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t('Containers.PageLogin.labelNewUser')} {` `}
            <Link className="text-green-600" to="/signup">
              {t('Containers.PageLogin.labelCreateAccount')}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
