import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 10000, // Revalidar a cada 10 segundos
      staleTime: 10000 // Tempo em que os dados são considerados "frescos"
    }
  }
});

export default queryClient;
