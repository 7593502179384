import { ILink } from 'types/pagination';

export function transformLinks(links: ILink[], searchName?: string): ILink[] {
  return links.map((link) => {
    return {
      ...link,
      url: `/busca?${link.url?.split('?')[1]}${searchName ? `&q=${searchName}` : ''}`
    };
  });
}
