import axios from 'axios';

export const axiosApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // baseURL: process.env.REACT_APP_API_ADM_URL,

    headers: {
        'Content-Type': 'application/json'
    }
});

axiosApi.interceptors.request.use(
    (config) => {
        const localToken = document.cookie
            .split('; ')
            .find((row) => row.startsWith('token='));
        let token = localToken
            ? localToken.split('=')[1].replaceAll('"', '')
            : null;

        if (token && config.headers !== undefined) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },

    (error) => Promise.reject(error)
);
