import QRCodeComponent from 'hooks/useQrCode';
import React, { useEffect, useState } from 'react';
import { useProfile } from 'context/ProfileContext';
import { IShippingAddress } from '../CheckoutPage';
import { CHECKOUTDATA } from '../CheckoutPage';
import { useCurrency } from 'context/CurrencyContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface CreditProps {
    isPix?: boolean;
    IsWeb?: boolean;
    seconds?: number;
    // handleSubmit: (e: any) => void;
}

const CreditMethod: React.FC<CreditProps> = () => {
    const { currency } = useCurrency();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useProfile();
    const cardNumberData = CHECKOUTDATA.paymentMethod.cardNumber;
    const lastFourDigits = cardNumberData ? cardNumberData.slice(-4) : null;
    const delivery = CHECKOUTDATA.shippingAddress.deliveryTime;
    const Addrees = CHECKOUTDATA.shippingAddress.address;
    const CEP = CHECKOUTDATA.shippingAddress.postalCode;
    const city = CHECKOUTDATA.shippingAddress.city;
    const state = CHECKOUTDATA.shippingAddress.state;

    return (
        <div className="max-w-screen-xl mx-auto px-4 mb-8">
            <div className="mt-[82px] mb-[142px]">
                <div className="w-full flex flex-col items-center md:flex-col items-center md:items-center gap-[64px] md:gap-0">
                    <div className="w-[100%] flex flex-col items-center md:items-center lg:w-[68%] pr-[0px] md:pr-[82px] lg:pr-[0]">
                        <div className="max-w-[300px]">
                            <div className="py-3 px-4 flex items-center gap-2 rounded bg-black text-white">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    width={'50'}
                                    height={'50'}
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                    />
                                </svg>

                                <p className="text-sm font-semibold font-sans">
                                    {t(
                                        'Containers.PageCheckout.CreditMethod.labelProcessingPay'
                                    )}
                                </p>
                            </div>
                        </div>
                        <h2 className=" leading-normal text-[16px] max-w-[520px] text-center lg:max-w-full  lg:text-[30px] mt-9 text-black font-[700] font-sans">
                            {t(
                                'Containers.PageCheckout.CreditMethod.labelThaksInit'
                            )}
                            {user?.fullName}!
                            {t(
                                'Containers.PageCheckout.CreditMethod.labelThaksFinish'
                            )}
                        </h2>

                        <div className="mt-9 flex flex-col items-center">
                            <p className="text-base font-sans font-[700] text-black">
                                {t(
                                    'Containers.PageCheckout.CreditMethod.labelShipping'
                                )}
                            </p>
                            <span className="text-base font-sans font-[400] text-black">
                                {currency} {delivery}
                            </span>
                        </div>

                        <div className="mt-8 flex flex-col items-center">
                            <p className="text-base font-sans font-[700] text-black">
                                {t(
                                    'Containers.PageCheckout.CreditMethod.labelAddress'
                                )}
                            </p>
                            <div className="flex flex-col items-center ">
                                <span className="text-base font-sans font-[400] text-black">
                                    {Addrees}, {CEP} - {city}, {state}
                                </span>
                            </div>
                        </div>

                        <div className="mt-8 flex flex-col gap-2 items-center ">
                            <p className="text-base font-sans font-[700] text-black">
                                {t(
                                    'Containers.PageCheckout.CreditMethod.labelPayment'
                                )}
                            </p>
                            <div className="flex items-end gap-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
                                    />
                                </svg>

                                <span className="text-base font-sans font-[400] text-black">
                                    {t(
                                        'Containers.PageCheckout.CreditMethod.labelCardEnding'
                                    )}{' '}
                                    {lastFourDigits}
                                </span>
                            </div>
                        </div>
                        <button
                            onClick={() => navigate('/cart')}
                            className="py-[14px] w-[60%] px-3 text-white font-sans text-base font-semibold rounded-3xl flex items-center justify-center gap-2 hover:brightness-70 duration-300 relative bg-black mt-6 disabled:cursor-not-allowed"
                        >
                            {t(
                                'Containers.PageCheckout.CreditMethod.labelContinueShopping'
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreditMethod;
