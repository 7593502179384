import React from 'react';

const Policy = () => {
  const A_FEATURES = [
    {
      color: 'bg-gray-200',
      name: 'Free shipping',
      desc: '',
      svg: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 14H13C14.1 14 15 13.1 15 12V2H6C4.5 2 3.19001 2.82999 2.51001 4.04999" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2 17C2 18.66 3.34 20 5 20H6C6 18.9 6.9 18 8 18C9.1 18 10 18.9 10 20H14C14 18.9 14.9 18 16 18C17.1 18 18 18.9 18 20H19C20.66 20 22 18.66 22 17V14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29L18.58 6.01001C18.22 5.39001 17.56 5 16.84 5H15V12C15 13.1 14.1 14 13 14H12" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 22C9.10457 22 10 21.1046 10 20C10 18.8954 9.10457 18 8 18C6.89543 18 6 18.8954 6 20C6 21.1046 6.89543 22 8 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 22C17.1046 22 18 21.1046 18 20C18 18.8954 17.1046 18 16 18C14.8954 18 14 18.8954 14 20C14 21.1046 14.8954 22 16 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22 12V14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29L22 12Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2 8H8" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2 11H6" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2 14H4" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `
    },
    {
      color: 'bg-gray-200',
      name: 'Up to 12x on the card',
      desc: '',
      svg: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 15C22 18.87 18.87 22 15 22L16.05 20.25" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2 9C2 5.13 5.13 2 9 2L7.95 3.75" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.7 4.44995L17.6799 6.74994L21.6199 4.45996" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.6799 10.82V6.73999" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.74 2.21L14.34 3.53996C13.8 3.83996 13.35 4.59995 13.35 5.21995V7.75999C13.35 8.37999 13.79 9.13998 14.34 9.43998L16.74 10.77C17.25 11.06 18.09 11.06 18.61 10.77L21.01 9.43998C21.55 9.13998 22 8.37999 22 7.75999V5.21995C22 4.59995 21.56 3.83996 21.01 3.53996L18.61 2.21C18.1 1.93 17.26 1.93 16.74 2.21Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2.34998 15.45L6.31998 17.7499L10.27 15.46" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.31995 21.82V17.74" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.39 13.21L2.99001 14.54C2.45001 14.84 2 15.5999 2 16.2199V18.76C2 19.38 2.44001 20.14 2.99001 20.44L5.39 21.77C5.9 22.06 6.73999 22.06 7.25999 21.77L9.66 20.44C10.2 20.14 10.65 19.38 10.65 18.76V16.2199C10.65 15.5999 10.21 14.84 9.66 14.54L7.25999 13.21C6.73999 12.93 5.9 12.93 5.39 13.21Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      `
    },
    {
      color: 'bg-gray-200',
      name: '100% secure purchase',
      desc: '',
      svg: `<svg id="Outline" width="24" height="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:#262626;}</style></defs><path class="cls-1" d="M439.84,178.43a11.62,11.62,0,0,0,.27-1.2,469.83,469.83,0,0,0,6.27-77.39,33.76,33.76,0,0,0-33.73-33.72,11.73,11.73,0,0,0-2.13.2L256,96,101.56,66.32a11.73,11.73,0,0,0-2.13-.2A33.76,33.76,0,0,0,65.7,99.84c0,56.93,9.65,110.46,28.68,159.1a383,383,0,0,0,63.24,106.8c45.59,54.39,90.89,77.92,92.8,78.9a11.32,11.32,0,0,0,5.15,1.24l.46,0,.48,0a11.35,11.35,0,0,0,5.15-1.24c1.5-.77,36.81-19.09,76.43-60.62,2.51.15,5,.24,7.54.24a124.44,124.44,0,0,0,94.21-205.83ZM256,421.78c-12.38-7.34-47.26-30.06-81.71-71.41-39.21-47.06-86-128.72-86-250.53a11.06,11.06,0,0,1,10.15-11l155.19,29.76a12.92,12.92,0,0,0,4.64,0L413.54,88.83a11.07,11.07,0,0,1,10.16,11,454.43,454.43,0,0,1-3.92,60c-48.71-36.15-118-32.16-162.18,12a124.45,124.45,0,0,0,53.18,207.52C286.47,402.64,265.16,416.38,256,421.78Zm161.59-90a101.77,101.77,0,1,1,0-144A101.93,101.93,0,0,1,417.63,331.82Z"/><path class="cls-1" d="M391,212.18l-70.76,74.93-29-30.73A11.34,11.34,0,0,0,274.69,272L312,311.46a11.33,11.33,0,0,0,16,.46,10.74,10.74,0,0,0,1.27-1.44l.06-.05,78.08-82.68A11.34,11.34,0,0,0,391,212.18Z"/></svg>
      `
    }
    // {
    //   color: "bg-amber-50",
    //   name: "Refunds policy",
    //   desc: "60 days return for any reason",
    //   svg: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M9.5 13.75C9.5 14.72 10.25 15.5 11.17 15.5H13.05C13.85 15.5 14.5 14.82 14.5 13.97C14.5 13.06 14.1 12.73 13.51 12.52L10.5 11.47C9.91 11.26 9.51001 10.94 9.51001 10.02C9.51001 9.17999 10.16 8.48999 10.96 8.48999H12.84C13.76 8.48999 14.51 9.26999 14.51 10.24" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    //   <path d="M12 7.5V16.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    //   <path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    //   <path d="M17 3V7H21" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    //   <path d="M22 2L17 7" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    //   </svg>
    //   `,
    // },
  ];

  return (
    <div className="grid sm:grid-cols-3 gap-3 relative">
      {A_FEATURES.map((item, index) => {
        return (
          <div
            key={index}
            className={`flex flex-col p-4 rounded-2xl ${item.color} dark:bg-opacity-90`}
          >
            <div dangerouslySetInnerHTML={{ __html: item.svg }}></div>
            <div className="mt-2.5">
              <p className="font-semibold text-slate-900">{item.name}</p>
              <p className="text-slate-500 mt-0.5 text-sm">{item.desc}</p>
            </div>
          </div>
        );
      })}

      {/* <div className="absolute inset-x-0 border-t top-1/2 -translate-y-1/2 border-slate-300"></div>
      <div className="absolute inset-y-0 border-l left-1/2 -translate-x-1/2 border-slate-300"></div> */}
    </div>
  );
};

export default Policy;
