import { CheckIcon, NoSymbolIcon } from '@heroicons/react/24/outline';
import NcInputNumber from 'components/NcInputNumber';
import Prices from 'components/Prices';
import { Item, useCart } from 'context/CartContext';
import { useCurrency } from 'context/CurrencyContext';
import { useProfile } from 'context/ProfileContext';
import cartSmile from 'images/cartSmile.svg';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
import Select from 'shared/Select/Select';

const CartPage = () => {
  const { t } = useTranslation();
  const { currency, switchCurrency } = useCurrency();
  const {
    cart,
    totalPrice,
    totalWithDiscounts,
    setShipping,
    shippingOptions,
    addOneProductToCart,
    removeManyProductToCart,
    removeOneProductToCart
  } = useCart();
  const { zipCode, changeZipCode, address } = useProfile();
  const [zipcodeInput, setZipcodeInput] = useState('');



//   const renderStatusSoldout = () => {
//     return (
//       <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
//         <NoSymbolIcon className="w-3.5 h-3.5" />
//         <span className="ml-1 leading-none">
//           {t(`Containers.ProductDetailPage.CartPage.labelSoldOut`)}
//         </span>
//       </div>
//     );
//   };

    const renderStatusInstock = () => {
        return (
        <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
            <CheckIcon className="w-3.5 h-3.5" />
            <span className="ml-1 leading-none">
            {t(`Containers.ProductDetailPage.CartPage.labelInStock`)}
            </span>
        </div>
        );
    };

    const renderProduct = (item: Item, index: number) => {
        const { image, name } = item.product;
        return (
        <div
            key={index}
            className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0"
        >
            <div className="relative h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
            <img
                src={image}
                alt={name}
                className="h-full w-full object-contain object-center"
            />
            <Link
                to={'/produto/' + item.product.id}
                className="absolute inset-0"
            ></Link>
            </div>

            <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
            <div>
                <div className="flex justify-between ">
                <div className="flex-[1.5] ">
                    <h3 className="text-base font-semibold">
                    <Link to={'/produto/' + item.product.id}>{name}</Link>
                    </h3>
                    <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                    <div className="flex items-center space-x-1.5">

                        <span>{``}</span>
                    </div>
                    <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                    <div className="flex items-center space-x-1.5">

                        <span>{``}</span>
                    </div>
                    </div>

                    <div className="mt-3 flex justify-between w-full sm:hidden relative">
                    <Prices
                        contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium"
                        price={switchCurrency(item.product)}
                    />
                    <NcInputNumber
                        className="relative z-10"
                        defaultValue={item.quantity}
                        handleAddOne={() =>
                        addOneProductToCart({ ...item, quantity: 1 })
                        }
                        handleRemoveOne={() => removeOneProductToCart(item)}
                    />
                    </div>
                </div>

                <div className="hidden sm:block text-center relative">
                    <NcInputNumber
                    className="relative z-10"
                    defaultValue={item.quantity}
                    handleAddOne={() =>
                        addOneProductToCart({ ...item, quantity: 1 })
                    }
                    handleRemoveOne={() => removeOneProductToCart(item)}
                    />
                </div>

                <div className="hidden flex-1 sm:flex justify-end">
                    <Prices
                    price={switchCurrency(item.product)}
                    className="mt-0.5"
                    />
                </div>
                </div>
            </div>

            <div className="flex mt-auto pt-4 items-end justify-end text-sm">
                {/* {Math.random() > 0.6
                ? renderStatusSoldout()
                : renderStatusInstock()} */}

                <a
                href="##"
                className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
                onClick={() => removeManyProductToCart([item])}
                >
                <span>
                    {t(`Containers.ProductDetailPage.CartPage.labelRemoveButton`)}
                </span>
                </a>
            </div>
            </div>
        </div>
        );
    };

    return (
        <div className="nc-CartPage">
            <Helmet>
                <title>
                {t(`Containers.ProductDetailPage.CartPage.labelHeaderName`)} || AP Professional
                </title>
            </Helmet>

            <main className="container py-16 lg:pb-28 lg:pt-20 ">
                <div className="mb-12 sm:mb-16">
                    <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
                        {t(`Containers.ProductDetailPage.CartPage.labelHeaderName`)}
                    </h2>
                    <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
                        <Link to={'/#'} className="">
                        Homepage
                        </Link>
                        <span className="text-xs mx-1 sm:mx-1.5">/</span>
                        {/* <Link to={"/#"} className="">
                        Clothing Categories
                        </Link>
                        <span className="text-xs mx-1 sm:mx-1.5">/</span> */}
                        <span className="underline">
                        {t(`Containers.ProductDetailPage.CartPage.labelHeaderName`)}
                        </span>
                    </div>
                </div>
                <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />

                <div className="flex flex-col lg:flex-row">
                    <div className="w-full lg:w-[60%] xl:w-[55%] divide-y divide-slate-200 dark:divide-slate-700 ">
                        {cart.length > 0 ? (
                        cart.map(renderProduct)
                        ) : (
                        <div className="flex flex-col justify-center text-center gap-4">
                            <div className="flex justify-center">
                            <img
                                className="flex-shrink-0 h-52 w-52 dark:invert opacity-70"
                                src={cartSmile}
                                alt={'Empyt cart'}
                            />
                            </div>
                            <h3 className="text-lg font-semibold ">
                            {t(`Containers.ProductDetailPage.CartPage.labelEmpytCart`)}
                            </h3>
                        </div>
                        )}
                    </div>
                    <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:mx-16 2xl:mx-20 flex-shrink-0"></div>
                    <div className="flex-1">
                        <div className="sticky top-28">
                            <h3 className="text-lg font-semibold ">
                                {t(`Containers.ProductDetailPage.CartPage.labelOrderSummary`)}
                            </h3>
                            <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                            <div className="flex justify-between pb-4">
                                <span>
                                    {t(`Containers.ProductDetailPage.CartPage.labelSubtotal`)}
                                </span>
                                <span className="font-semibold text-slate-900 dark:text-slate-200">
                                    {currency}
                                    {totalPrice}
                                </span>
                            </div>
                            {cart && cart.length > 0 && (
                            <>
                                {zipCode ? (
                                shippingOptions ? (
                                    <div className="flex flex-col py-4">
                                        {/* <div className="flex gap-4 justify-between items-center "> */}
                                        <span className="mb-4">
                                            {t(
                                            `Containers.ProductDetailPage.CartPage.labelShippingEstimate`
                                            )}
                                        </span>
                                        <Select
                                            className=""
                                            defaultValue={
                                            shippingOptions.find(
                                                (option) => option.menorPreco
                                            )?.preco
                                            }
                                            onChange={(value) => {
                                            setShipping(Number(value.target.value));
                                            }}
                                        >
                                            {shippingOptions.map((option, index) => (
                                            <option key={index} value={option.preco}>
                                                {option.preco === 0
                                                ? 'Grátis'
                                                : `${currency} ${option.preco}`}
                                                {' - '}
                                                {option.nome} {option.prazoEntrega}
                                            </option>
                                            ))}
                                        </Select>
                                        {/* </div> */}
                                        <span
                                            className="text-sm text-black dark:text-slate-400 mt-1 ml-1 cursor-pointer"
                                            onClick={() => changeZipCode('')}
                                        >
                                            {t(
                                            `Containers.ProductDetailPage.CartPage.labelAlternativeZipCode`
                                            )}
                                        </span>
                                        <div className="italic mt-4 ">
                                            {t(
                                            `Containers.ProductDetailPage.CartPage.labelAddress`
                                            )}
                                            {address}
                                        </div>
                                    </div>
                                ) : (
                                    <>Carregando ... </>
                                )
                                ) : (
                                <div className="flex flex-col py-4">
                                    <div className="flex justify-between items-center font-semibold text-slate-900 dark:text-slate-200 text-base gap-2">
                                    <Input
                                        type="text"
                                        value={zipcodeInput}
                                        placeholder={t(
                                        `Containers.ProductDetailPage.CartPage.labelZipCode`
                                        )}
                                        onChange={(e) => {
                                        setZipcodeInput(e.target.value);
                                        }}
                                        className="w-64"
                                    />
                                    <ButtonPrimary
                                        className="h-full"
                                        onClick={() => {
                                        changeZipCode(zipcodeInput);
                                        setZipcodeInput('');
                                        }}
                                    >
                                        {t(
                                        `Containers.ProductDetailPage.CartPage.labelCalculateShipping`
                                        )}
                                    </ButtonPrimary>
                                    </div>
                                    <span
                                    className="text-xs text-slate-500 dark:text-slate-400 mt-1 ml-1 cursor-pointer"
                                    onClick={() => {
                                        window.open(
                                        'https://buscacepinter.correios.com.br/app/endereco/index.php',
                                        '_blank'
                                        );
                                    }}
                                    >
                                    {t(
                                        `Containers.ProductDetailPage.CartPage.labelZipCodeDescription`
                                    )}
                                    </span>
                                </div>
                                )}
                            </>
                            )}
                            <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                                <span>
                                    {t(`Containers.ProductDetailPage.CartPage.labelOrderTotal`)}
                                </span>
                                <span>
                                    {currency}
                                    {totalWithDiscounts}
                                </span>
                            </div>
                        </div>
                        <ButtonPrimary href="/checkout" className="mt-8 w-full">
                            {t(`Containers.ProductDetailPage.CartPage.labelCheckout`)}
                        </ButtonPrimary>
                            <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
                                <p className="block relative pl-5">

                                Learn more{` `}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="##"
                                    className="text-slate-900 dark:text-slate-200 underline font-medium"
                                >
                                    Taxes
                                </a>
                                <span>
                                    {` `}and{` `}
                                </span>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="##"
                                    className="text-slate-900 dark:text-slate-200 underline font-medium"
                                >
                                    Shipping
                                </a>
                                {` `} infomation
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default CartPage;
