import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Label from 'components/Label/Label';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import Select from 'shared/Select/Select';
import { IShippingAddress } from './CheckoutPage';
import { EnderecoService } from 'services/endereco';
import Checkbox from 'shared/Checkbox/Checkbox';
import { useCart } from 'context/CartContext';
import { useCurrency } from 'context/CurrencyContext';
import { useProfile } from 'context/ProfileContext';
import { Value } from 'sass';

interface Props {
    isActive: boolean;
    onCloseActive: () => void;
    onOpenActive: () => void;
    shippingAddress: IShippingAddress;
    onChange: (shippingAddress: IShippingAddress) => void;
    errors: Record<string, string>;
}

const countries = [
    { name: 'United States', code: 'USA' },
    { name: 'Canada', code: 'CAN' },
    { name: 'Mexico', code: 'MEX' },
    { name: 'Israel', code: 'ISR' },
    { name: 'France', code: 'FRA' },
    { name: 'England', code: 'GBR' },
    { name: 'Laos', code: 'LAO' },
    { name: 'China', code: 'CHN' },
    { name: 'Afghanistan', code: 'AFG' },
    { name: 'Albania', code: 'ALB' },
    { name: 'Algeria', code: 'DZA' },
    { name: 'Andorra', code: 'AND' },
    { name: 'Angola', code: 'AGO' },
    { name: 'Antigua and Barbuda', code: 'ATG' },
    { name: 'Argentina', code: 'ARG' },
    { name: 'Armenia', code: 'ARM' },
    { name: 'Australia', code: 'AUS' },
    { name: 'Austria', code: 'AUT' },
    { name: 'Azerbaijan', code: 'AZE' },
    { name: 'Bahamas', code: 'BHS' },
    { name: 'Bahrain', code: 'BHR' },
    { name: 'Bangladesh', code: 'BGD' },
    { name: 'Barbados', code: 'BRB' },
    { name: 'Belarus', code: 'BLR' },
    { name: 'Belgium', code: 'BEL' },
    { name: 'Belize', code: 'BLZ' },
    { name: 'Benin', code: 'BEN' },
    { name: 'Bhutan', code: 'BTN' },
    { name: 'Bolivia', code: 'BOL' },
    { name: 'Bosnia and Herzegovina', code: 'BIH' },
    { name: 'Botswana', code: 'BWA' },
    { name: 'Brasil', code: 'BRA' },
    { name: 'Brunei', code: 'BRN' },
    { name: 'Bulgaria', code: 'BGR' },
    { name: 'Burkina Faso', code: 'BFA' },
    { name: 'Burundi', code: 'BDI' },
    { name: 'Cabo Verde', code: 'CPV' },
    { name: 'Cambodia', code: 'KHM' },
    { name: 'Cameroon', code: 'CMR' },
    { name: 'Central African Republic', code: 'CAF' },
    { name: 'Chad', code: 'TCD' },
    { name: 'Chile', code: 'CHL' },
    { name: 'Colombia', code: 'COL' },
    { name: 'Comoros', code: 'COM' },
    { name: 'Congo', code: 'COG' },
    { name: 'Costa Rica', code: 'CRI' },
    { name: 'Croatia', code: 'HRV' },
    { name: 'Cuba', code: 'CUB' },
    { name: 'Cyprus', code: 'CYP' },
    { name: 'Czech Republic', code: 'CZE' },
    { name: 'Denmark', code: 'DNK' },
    { name: 'Djibouti', code: 'DJI' },
    { name: 'Dominica', code: 'DMA' },
    { name: 'Dominican Republic', code: 'DOM' },
    { name: 'Ecuador', code: 'ECU' },
    { name: 'Egypt', code: 'EGY' },
    { name: 'El Salvador', code: 'SLV' },
    { name: 'Equatorial Guinea', code: 'GNQ' },
    { name: 'Eritrea', code: 'ERI' },
    { name: 'Estonia', code: 'EST' },
    { name: 'Eswatini', code: 'SWZ' },
    { name: 'Ethiopia', code: 'ETH' },
    { name: 'Fiji', code: 'FJI' },
    { name: 'Finland', code: 'FIN' },
    { name: 'Gabon', code: 'GAB' },
    { name: 'Gambia', code: 'GMB' },
    { name: 'Georgia', code: 'GEO' },
    { name: 'Germany', code: 'DEU' },
    { name: 'Ghana', code: 'GHA' },
    { name: 'Greece', code: 'GRC' },
    { name: 'Grenada', code: 'GRD' },
    { name: 'Guatemala', code: 'GTM' },
    { name: 'Guinea', code: 'GIN' },
    { name: 'Guinea-Bissau', code: 'GNB' },
    { name: 'Guyana', code: 'GUY' },
    { name: 'Haiti', code: 'HTI' },
    { name: 'Honduras', code: 'HND' },
    { name: 'Hungary', code: 'HUN' },
    { name: 'Iceland', code: 'ISL' },
    { name: 'India', code: 'IND' },
    { name: 'Indonesia', code: 'IDN' },
    { name: 'Iran', code: 'IRN' },
    { name: 'Iraq', code: 'IRQ' },
    { name: 'Ireland', code: 'IRL' },
    { name: 'Italy', code: 'ITA' },
    { name: 'Ivory Coast', code: 'CIV' },
    { name: 'Jamaica', code: 'JAM' },
    { name: 'Japan', code: 'JPN' },
    { name: 'Jordan', code: 'JOR' },
    { name: 'Kazakhstan', code: 'KAZ' },
    { name: 'Kenya', code: 'KEN' },
    { name: 'Kiribati', code: 'KIR' },
    { name: 'Kuwait', code: 'KWT' },
    { name: 'Kyrgyzstan', code: 'KGZ' },
    { name: 'Laos', code: 'LAO' },
    { name: 'Latvia', code: 'LVA' },
    { name: 'Lebanon', code: 'LBN' },
    { name: 'Lesotho', code: 'LSO' },
    { name: 'Liberia', code: 'LBR' },
    { name: 'Libya', code: 'LBY' },
    { name: 'Liechtenstein', code: 'LIE' },
    { name: 'Lithuania', code: 'LTU' },
    { name: 'Luxembourg', code: 'LUX' },
    { name: 'Madagascar', code: 'MDG' },
    { name: 'Malawi', code: 'MWI' },
    { name: 'Malaysia', code: 'MYS' },
    { name: 'Maldives', code: 'MDV' },
    { name: 'Mali', code: 'MLI' },
    { name: 'Malta', code: 'MLT' },
    { name: 'Marshall Islands', code: 'MHL' },
    { name: 'Mauritania', code: 'MRT' },
    { name: 'Mauritius', code: 'MUS' },
    { name: 'Micronesia', code: 'FSM' },
    { name: 'Moldova', code: 'MDA' },
    { name: 'Monaco', code: 'MCO' },
    { name: 'Mongolia', code: 'MNG' },
    { name: 'Montenegro', code: 'MNE' },
    { name: 'Morocco', code: 'MAR' },
    { name: 'Mozambique', code: 'MOZ' },
    { name: 'Myanmar', code: 'MMR' },
    { name: 'Namibia', code: 'NAM' },
    { name: 'Nauru', code: 'NRU' },
    { name: 'Nepal', code: 'NPL' },
    { name: 'Netherlands', code: 'NLD' },
    { name: 'New Zealand', code: 'NZL' },
    { name: 'Nicaragua', code: 'NIC' },
    { name: 'Niger', code: 'NER' },
    { name: 'Nigeria', code: 'NGA' },
    { name: 'North Korea', code: 'PRK' },
    { name: 'North Macedonia', code: 'MKD' },
    { name: 'Norway', code: 'NOR' },
    { name: 'Oman', code: 'OMN' },
    { name: 'Pakistan', code: 'PAK' },
    { name: 'Palau', code: 'PLW' },
    { name: 'Palestine', code: 'PSE' },
    { name: 'Panama', code: 'PAN' },
    { name: 'Papua New Guinea', code: 'PNG' },
    { name: 'Paraguay', code: 'PRY' },
    { name: 'Peru', code: 'PER' },
    { name: 'Philippines', code: 'PHL' },
    { name: 'Poland', code: 'POL' },
    { name: 'Portugal', code: 'PRT' },
    { name: 'Qatar', code: 'QAT' },
    { name: 'Romania', code: 'ROU' },
    { name: 'Russia', code: 'RUS' },
    { name: 'Rwanda', code: 'RWA' },
    { name: 'Saint Kitts and Nevis', code: 'KNA' },
    { name: 'Saint Lucia', code: 'LCA' },
    { name: 'Saint Vincent and the Grenadines', code: 'VCT' },
    { name: 'Samoa', code: 'WSM' },
    { name: 'San Marino', code: 'SMR' },
    { name: 'Sao Tome and Principe', code: 'STP' },
    { name: 'Saudi Arabia', code: 'SAU' },
    { name: 'Senegal', code: 'SEN' },
    { name: 'Serbia', code: 'SRB' },
    { name: 'Seychelles', code: 'SYC' },
    { name: 'Sierra Leone', code: 'SLE' },
    { name: 'Singapore', code: 'SGP' },
    { name: 'Slovakia', code: 'SVK' },
    { name: 'Slovenia', code: 'SVN' },
    { name: 'Solomon Islands', code: 'SLB' },
    { name: 'Somalia', code: 'SOM' },
    { name: 'South Africa', code: 'ZAF' },
    { name: 'South Korea', code: 'KOR' },
    { name: 'South Sudan', code: 'SSD' },
    { name: 'Spain', code: 'ESP' },
    { name: 'Sri Lanka', code: 'LKA' },
    { name: 'Sudan', code: 'SDN' },
    { name: 'Suriname', code: 'SUR' },
    { name: 'Sweden', code: 'SWE' },
    { name: 'Switzerland', code: 'CHE' },
    { name: 'Syria', code: 'SYR' },
    { name: 'Taiwan', code: 'TWN' },
    { name: 'Tajikistan', code: 'TJK' },
    { name: 'Tanzania', code: 'TZA' },
    { name: 'Thailand', code: 'THA' },
    { name: 'Timor-Leste', code: 'TLS' },
    { name: 'Togo', code: 'TGO' },
    { name: 'Tonga', code: 'TON' },
    { name: 'Trinidad and Tobago', code: 'TTO' },
    { name: 'Tunisia', code: 'TUN' },
    { name: 'Turkey', code: 'TUR' },
    { name: 'Turkmenistan', code: 'TKM' },
    { name: 'Tuvalu', code: 'TUV' },
    { name: 'Uganda', code: 'UGA' },
    { name: 'Ukraine', code: 'UKR' },
    { name: 'United Arab Emirates', code: 'ARE' },
    { name: 'United Kingdom', code: 'GBR' },
    { name: 'Uruguay', code: 'URY' },
    { name: 'Uzbekistan', code: 'UZB' },
    { name: 'Vanuatu', code: 'VUT' },
    { name: 'Vatican City', code: 'VAT' },
    { name: 'Venezuela', code: 'VEN' },
    { name: 'Vietnam', code: 'VNM' },
    { name: 'Yemen', code: 'YEM' },
    { name: 'Zambia', code: 'ZMB' },
    { name: 'Zimbabwe', code: 'ZWE' }
];

const ShippingAddress: FC<Props> = ({
    isActive,
    onCloseActive,
    onOpenActive,
    shippingAddress,
    onChange,
    errors
}) => {
    const { t } = useTranslation();

    const { shippingOptions, setShipping } = useCart();
    const { currency } = useCurrency();

    const [shippingData, setShippingData] =
        useState<IShippingAddress>(shippingAddress);
    const [isChecked, setChecked] = useState<number | boolean>(true);

    const handleChange =
        (field: keyof IShippingAddress) =>
        (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const { value } = event.target;
            setShippingData((prevData) => ({
                ...prevData,
                [field]: value
            }));
        };

    const handleChangePostalCode =
        (field: keyof IShippingAddress) =>
        (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const { value } = event.target;
            if (value.length > 7)
                EnderecoService.get({
                    cep: value.replace(/\D/g, '').substring(0, 8)
                }).then((response) => {
                    setShippingData((prevData) => ({
                        ...prevData,
                        [field]: value,
                        address: response.data.logradouro,
                        city: response.data.localidade,
                        state: response.data.uf
                    }));
                });
            else
                setShippingData((prevData) => ({
                    ...prevData,
                    [field]: value
                }));
        };

    useEffect(() => {
        onChange(shippingData);
    }, [shippingData]);

    const renderShippingAddress = () => {
        const handleChangeValue = (
            id: number | boolean,
            labelFare: number,
            prazoEntrega: string
        ) => {
            shippingAddress.deliveryTime =
                labelFare.toString() + ' - ' + prazoEntrega.toString();
            if (isChecked == false) {
                setChecked(id);
            }
            setChecked(id);
            setShipping(Number(labelFare));
        };

        return (
            <div className="border border-green-100 dark:border-slate-700 rounded-xl">
                <div className="p-6 flex flex-col sm:flex-row items-start">
                    <span className="hidden sm:block">
                        <svg
                            className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>

                    <div className="sm:ml-8">
                        <h3 className=" text-slate-700 dark:text-slate-300 flex ">
                            <span className="uppercase">
                                {t(
                                    'Containers.PageCheckout.ShippingAddress.labelShippingAddress'
                                )}
                            </span>
                            <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2.5"
                                stroke="currentColor"
                                className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4.5 12.75l6 6 9-13.5"
                                />
                            </svg>
                        </h3>
                        <div className="font-semibold mt-1 text-sm">
                            <span className="">
                                {`${shippingData.address}, ${shippingData.aptSuite}, ${shippingData.city}, ${shippingData.state}, ${shippingData.postalCode}`}
                            </span>
                        </div>
                    </div>
                    <ButtonSecondary
                        sizeClass="py-2 px-4 "
                        fontSize="text-sm font-medium"
                        className="bg-slate-100 shadow dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
                        onClick={onOpenActive}
                    >
                        {t(
                            'Containers.PageCheckout.ShippingAddress.labelChange'
                        )}
                    </ButtonSecondary>
                </div>
                <div
                    className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${isActive ? 'block' : 'hidden'}`}
                >
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                        <div>
                            <Label className="text-sm">
                                {t(
                                    'Containers.PageCheckout.ShippingAddress.labelCountry'
                                )}
                            </Label>
                            <Select
                                className="mt-1.5"
                                value={shippingData.country}
                                onChange={handleChange('country')}
                            >
                                {countries.map((country, index) => (
                                    <option key={index} value={country.code}>
                                        {country.name}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <Label className="text-sm">
                                {t(
                                    'Containers.PageCheckout.ShippingAddress.labelPostalCode'
                                )}
                            </Label>
                            <Input
                                className="mt-1.5"
                                value={shippingData.postalCode}
                                onChange={handleChangePostalCode('postalCode')}
                            />
                            {errors.postalCode && (
                                <p className="text-red-600 text-sm mt-1">
                                    {errors.postalCode}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                        <div className="flex-1">
                            <Label className="text-sm">
                                {t(
                                    'Containers.PageCheckout.ShippingAddress.labelAddress'
                                )}
                            </Label>
                            <Input
                                className="mt-1.5"
                                value={shippingData.address}
                                onChange={handleChange('address')}
                                type="text"
                            />
                            {errors.address && (
                                <p className="text-red-600 text-sm mt-1">
                                    {errors.address}
                                </p>
                            )}
                        </div>
                        <div className="">
                            <Label className="text-sm">
                                {t(
                                    'Containers.PageCheckout.ShippingAddress.labelAptSuite'
                                )}
                            </Label>
                            <Input
                                className="mt-1.5"
                                value={shippingData.aptSuite}
                                onChange={handleChange('aptSuite')}
                            />
                            {errors.address && (
                                <p className="text-red-600 text-sm mt-1">
                                    {errors.address}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-4 sm:gap-3">
                        <div className="w-full">
                            <Label className="text-sm">
                                {t(
                                    'Containers.PageCheckout.ShippingAddress.labelComplement'
                                )}
                            </Label>
                            <Input
                                className="mt-1.5"
                                value={shippingData.complement}
                                onChange={handleChange('complement')}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                        <div>
                            <Label className="text-sm">
                                {t(
                                    'Containers.PageCheckout.ShippingAddress.labelCity'
                                )}
                            </Label>
                            <Input
                                className="mt-1.5"
                                value={shippingData.city}
                                onChange={handleChange('city')}
                            />
                            {errors.city && (
                                <p className="text-red-600 text-sm mt-1">
                                    {errors.city}
                                </p>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">
                                {t(
                                    'Containers.PageCheckout.ShippingAddress.labelState'
                                )}
                            </Label>
                            <Input
                                className="mt-1.5"
                                value={shippingData.state}
                                onChange={handleChange('state')}
                            />
                            {errors.state && (
                                <p className="text-red-600  text-sm mt-1">
                                    {errors.state}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="block">
                        <div className="flex items-center">
                            <p className="w-1/2 border-t border-slate-200 dark:border-slate-700"></p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-6 h-6 m-2 text-slate-700 dark:text-slate-400 mt-0.5"
                            >
                                <path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 1 1 6 0h3a.75.75 0 0 0 .75-.75V15Z" />
                                <path d="M8.25 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM15.75 6.75a.75.75 0 0 0-.75.75v11.25c0 .087.015.17.042.248a3 3 0 0 1 5.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 0 0-3.732-10.104 1.837 1.837 0 0 0-1.47-.725H15.75Z" />
                                <path d="M19.5 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                            </svg>
                            <p className="w-1/2 border-t border-slate-200 dark:border-slate-700"></p>
                        </div>

                        <div className="text-center mt-0 mb-4 text-sm">
                            <p>
                                {t(
                                    'Containers.PageCheckout.ShippingAddress.labelDelivery'
                                )}
                            </p>
                        </div>
                        {shippingOptions ? (
                            <div>
                                {shippingOptions.map((opt, index) => (
                                    <div
                                        key={index}
                                        className={`w-full flex text-xs items-baseline bg-transparent border border-dashed rounded-2xl dark:border-slate-700 p-2 my-1`}
                                    >
                                        <Checkbox
                                            className={`!text-sm mr-1 ml-1`}
                                            name="receiveOffers"
                                            label={t(opt.nome)}
                                            onChange={() =>
                                                handleChangeValue(
                                                    index,
                                                    opt.precoFinalOriginal,
                                                    opt.prazoEntrega
                                                )
                                            }
                                            checked={isChecked === index}
                                        />
                                        {t(opt.prazoEntrega)}
                                        <div className="mr-4 ml-auto flex ${}">
                                            <p>
                                                {currency}
                                                {opt.precoFinalOriginal}
                                            </p>
                                            {/* <div className="w-0 h-0 overflow-hidden">
                                                {
                                                    (shippingAddress.deliveryTime =
                                                        opt.precoFinalOriginal.toString() +
                                                        ' - ' +
                                                        opt.prazoEntrega.toString())
                                                }
                                            </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <>Carregando ... </>
                        )}
                    </div>
                    <div className="flex flex-col sm:flex-row pt-6">
                        <ButtonPrimary
                            className="sm:!px-7 shadow-none"
                            onClick={onCloseActive}
                        >
                            {t(
                                'Containers.PageCheckout.ShippingAddress.labelSave'
                            )}
                        </ButtonPrimary>
                        <ButtonSecondary
                            className="mt-3 border border-slate-100 shadow sm:mt-0 sm:ml-3"
                            onClick={onCloseActive}
                        >
                            {t(
                                'Containers.PageCheckout.ShippingAddress.labelCancel'
                            )}
                        </ButtonSecondary>
                    </div>
                </div>
            </div>
        );
    };
    return renderShippingAddress();
};

export default ShippingAddress;
