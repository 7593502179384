import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Input from 'shared/Input/Input';
import { Link, useNavigate } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useTranslation } from 'react-i18next';
import { AuthService } from 'services/auth';
import notifyToaster from 'shared/Toaster/NotifyToaster';

export interface PageForgotPasswordProps {
  className?: string;
}

const PageForgotPassword: FC<PageForgotPasswordProps> = ({
  className = ''
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEmailError('');

    if (!validateEmail(email)) {
      setEmailError(t('Containers.PageForgotPassword.labelInvalidEmail'));
      return;
    }

    AuthService.forgotPassword({ email })
      .then(() => {
        notifyToaster(
          'success',
          t('Containers.PageForgotPassword.labelSuccess')
        );
        navigate('/login');
      })
      .catch((error) => {
        notifyToaster('error', error.response.data);
      });
  };

  return (
    <div
      className={`nc-PageForgotPassword ${className}`}
      data-nc-id="PageForgotPassword"
    >
      <Helmet>
        <title>
          {t('Containers.PageForgotPassword.labelForgotPassword')} || Ana Paula
          Professional
        </title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t('Containers.PageForgotPassword.labelForgotPassword')}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('Containers.PageForgotPassword.labelEmail')}
              </span>
              <Input
                type="email"
                placeholder={t(
                  'Containers.PageForgotPassword.labelPlaceholderEmail'
                )}
                className="mt-1"
                value={email}
                onChange={handleEmailChange}
              />
              {emailError && <div className="text-red-600">{emailError}</div>}
            </label>
            <ButtonPrimary type="submit">
              {t('Containers.PageForgotPassword.labelContinue')}
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            <Link className="text-green-600" to="/signup">
              {t('Containers.PageForgotPassword.labelBackToLogin')}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageForgotPassword;
