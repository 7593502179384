import { Popover, Tab, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { FC, Fragment, useEffect, useState } from 'react';
import { HeaderCurrency, headerCurrencyInitial } from './CurrencyDropdown';
import { useTranslation } from 'react-i18next';
import ptFlag from 'images/flags/pt.svg';
import enFlag from 'images/flags/en.svg';
import esFlag from 'images/flags/es.svg';

import { useLanguage } from 'context/LanguageContext';
import { CurrencyOptions, useCurrency } from 'context/CurrencyContext';
interface HeaderLanguage {
  id: string;
  name: string;
  description: string;
  href: string;
  active?: boolean;
}

export const headerLanguageInitial: HeaderLanguage[] = [
  {
    id: 'pt',
    name: 'Português',
    description: 'Português',
    href: '##',
    active: true
  },
  {
    id: 'en',
    name: 'English',
    description: 'United State',
    href: '##'
  },
  {
    id: 'es',
    name: 'Español',
    description: 'Español',
    href: '##'
  }
];

interface LangDropdownProps {
  panelClassName?: string;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const LangDropdown: FC<LangDropdownProps> = ({ panelClassName = '' }) => {
  const { t } = useTranslation();
  const { lang, changeLanguage } = useLanguage();
  const { currency, changeCurrency } = useCurrency();
  const [headerLanguage, setHeaderLanguage] = useState<HeaderLanguage[]>(
    headerLanguageInitial
  );
  const [headerCurrency, setHeaderCurrency] = useState<HeaderCurrency[]>(
    headerCurrencyInitial
  );

  useEffect(() => {
    const newHeaderLanguage = headerCurrency.map((item) => {
      if (item.id === currency) {
        return { ...item, active: true };
      }
      return { ...item, active: false };
    });
    setHeaderCurrency(newHeaderLanguage);
  }, [currency]);

  useEffect(() => {
    const newHeaderLanguage = headerLanguage.map((item) => {
      if (item.id === lang) {
        return { ...item, active: true };
      }
      return { ...item, active: false };
    });
    setHeaderLanguage(newHeaderLanguage);
  }, [lang]);

  const renderLang = () => {
    return (
      <div className="grid gap-8 lg:grid-cols-2">
        {headerLanguage.map((item, index) => (
          <a
            key={index}
            href={item.href}
            onClick={() => changeLanguage(item.id)}
            className={`flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 ${
              item.active ? 'bg-gray-100 dark:bg-gray-700' : 'opacity-80'
            }`}
          >
            <div className="">
              <p className="text-sm font-medium ">{item.name}</p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                {item.description}
              </p>
            </div>
          </a>
        ))}
      </div>
    );
  };

  const renderCurr = () => {
    return (
      <div className="grid gap-7 lg:grid-cols-2">
        {headerCurrency.map((item, index) => (
          <a
            key={index}
            href={item.href}
            onClick={() => {
              changeCurrency(item.id as CurrencyOptions);
              setHeaderCurrency(
                headerCurrency.map((item) => {
                  if (item.id === currency) {
                    return { ...item, active: true };
                  }
                  return { ...item, active: false };
                })
              );
            }}
            className={`flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${
              item.active ? 'bg-gray-100 dark:bg-gray-700' : 'opacity-80'
            }`}
          >
            {item.id === 'R$' ? (
              <img
                src={item.icon}
                alt={item.name}
                className="h-6 w-6 dark:invert"
              />
            ) : (
              <item.icon className={`w-[18px] h-[18px] `} />
            )}
            <p className="ml-2 text-sm font-medium ">{item.name}</p>
          </a>
        ))}
      </div>
    );
  };

  const getImageForLanguage = (lang: string) => {
    switch (lang) {
      case 'pt':
        return ptFlag;
      case 'en':
        return enFlag;
      case 'es':
        return esFlag; 
      default:
        return ptFlag; // Default to Portuguese flag
    }
  };

  return (
    <div className="LangDropdown hidden md:block">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-80'}
             group h-10 sm:h-12 px-3 py-1.5 inline-flex items-center text-sm text-gray-800 dark:text-neutral-200 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              {/* <GlobeAltIcon className="w-[18px] h-[18px] text-slate-400 hover:text-slate-50" /> */}
              <span className="ml-2">
                <img
                  src={getImageForLanguage(lang)}
                  alt="pt"
                  className="w-4 h-4"
                />
              </span>
              <ChevronDownIcon
                className={`${open ? '-rotate-180' : 'text-slate-400 hover:text-slate-50'}
                  ml-1 h-4 w-4  group-hover:text-slate-50 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`absolute z-20 w-96 mt-3.5 right-0 ${panelClassName}`}
              >
                <div className="p-6 rounded-2xl bg-white dark:bg-neutral-800 shadow-lg ring-1 ring-black ring-opacity-5">
                  <Tab.Group>
                    <Tab.List className="flex space-x-1 rounded-full bg-gray-100 dark:bg-slate-700 p-1">
                      {[
                        t('Header.LangDropdown.switch01'),
                        t('Header.LangDropdown.switch02')
                      ].map((category) => (
                        <Tab
                          key={category}
                          className={({ selected }) =>
                            classNames(
                              'w-full rounded-full py-2 text-sm font-medium leading-5 text-gray-700',
                              'focus:outline-none focus:ring-0',
                              selected
                                ? 'bg-white shadow'
                                : 'text-gray-700 dark:text-slate-300 hover:bg-white/70 dark:hover:bg-slate-900/40'
                            )
                          }
                        >
                          {category}
                        </Tab>
                      ))}
                    </Tab.List>
                    <Tab.Panels className="mt-5">
                      <Tab.Panel
                        className={classNames(
                          'rounded-xl p-3',
                          'focus:outline-none focus:ring-0'
                        )}
                      >
                        {renderLang()}
                      </Tab.Panel>
                      <Tab.Panel
                        className={classNames(
                          'rounded-xl p-3',
                          'focus:outline-none focus:ring-0'
                        )}
                      >
                        {renderCurr()}
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};
export default LangDropdown;
