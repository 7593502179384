// i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en-US/translation.json';
import translationES from './es-ES/translation.json';
import translationPT from './pt-BR/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  },
  pt: {
    translation: translationPT
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'pt',
  fallbackLng: 'pt',

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
