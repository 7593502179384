import React from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import CommonLayout from './CommonLayout';
import { useTranslation } from 'react-i18next';

const AccountBilling = () => {
  const { t } = useTranslation();
  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            {t('Containers.AccountPage.AccountBilling.labelHeaderName')}
          </h2>
          <div className="max-w-2xl prose prose-slate dark:prose-invert">
            <span className="">
              {t('Containers.AccountPage.AccountBilling.labelExplanation01')}
              <br />
              <br />
              {t('Containers.AccountPage.AccountBilling.labelExplanation02')}
              {` `}
              <a href="##">
                {t('Containers.AccountPage.AccountBilling.labelLearnMore')}
              </a>
            </span>
            <div className="pt-10">
              <ButtonPrimary>
                {t('Containers.AccountPage.AccountBilling.labelAddMethod')}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountBilling;
