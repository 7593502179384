import React, { FC } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import rightImgDemo from 'images/products_appro/cadeira-black.png';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useTranslation } from 'react-i18next';

export interface SectionPromo3Props {
  className?: string;
}

const SectionPromo3: FC<SectionPromo3Props> = ({ className = '' }) => {
    const { t } = useTranslation();
    return (
    <div className={`nc-SectionPromo3 ${className=""}`}>
        <div className="lg:relative lg:h-[580px] flex flex-col lg:flex-row bg-black dark:bg-gray-900 rounded-2xl sm:rounded-[40px] pb-0 sm:p-5 sm:pb-0 overflow-hidden">

            <div className="lg:w-[60%] max-w-xl">
                <h2 className="font-semibold text-center mt-6 text-3xl md:text-5xl text-gray-200">
                    {t('Components.SectionPromo3.labelSectionPromo3ProduTitleInit')}
                </h2>
                <p className='font-semibold text-center text-md mt-2 text-gray-200'>
                    {t('Components.SectionPromo3.labelSectionPromo3ProdutDescTitleFinal')}
                </p>
                <span className="block lg:mt-8 lg:ml-8 text-justify lg:text-lg leading-relaxed p-8 text-gray-200 dark:text-neutral-400">
                    {t('Components.SectionPromo3.labelSectionPromo3ProdutDesc')}
                </span>
                
                <div className="flex max-md:justify-center lg:justify-center lg:bottom-0 mb-6 lg:mt-6">
                    <ButtonPrimary
                    href="/busca"
                    className="bg-transparent border w-48 border-white hover:bg-gray-950"
                    >
                    {t('Components.SectionPromo1.labelLearnMore')}
                    </ButtonPrimary>
                </div>
            </div>

            <NcImage
                containerClassName="lg:absolute top-0 lg:right-0 max-w-[650px] object-none object-center"
                src={rightImgDemo}
            />
        </div>
    </div>
  );
};

export default SectionPromo3;
