import { useCurrency } from 'context/CurrencyContext';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface PricesProps {
  className?: string;
  price?: number;
  contentClass?: string;
  fontSize?: string;
  fontSpacing?: string;
}

const Prices: FC<PricesProps> = ({
  className = '',
  price = 33,
  contentClass = 'flex justify-start py-1 md:py-1.5 text-sm font-medium',
  fontSize = 'md',
  fontSpacing = 'widest'
}) => {
  const { currency } = useCurrency();
  const { t } = useTranslation();
  return (
    <div className={`${className}`}>
      <div className={`flex tracking-${fontSpacing}  ${contentClass}`}>
        <span
          className={`text-${fontSize} text-left text-gray-900 text-base font-bold dark:text-gray-200 !leading-none`}
        >
          {currency}
          {price}
        </span>
      </div>
      <p className="text-[10px] leading-tight mt-2">
        <span>{t('Containers.PageCheckout.labelIntallmentTextInit')}</span>
        <br></br>{' '}
        <span>{t('Containers.PageCheckout.labelIntallmentTextFinal')}</span>
      </p>
    </div>
  );
};

export default Prices;
