import { AxiosResponse } from 'axios';
import {
  IAuthForgotPasswordProps,
  IAuthForgotPasswordResponse,
  IAuthLoginProps,
  IAuthLoginResponse,
  IAuthRefreshResponse,
  IAuthRegisterProps,
  IAuthRegisterResponse,
  IAuthResetPasswordProps
} from './types';

import { axiosApi as authApi } from '../index';

const baseUrlAuth = '/api/auth';

export class AuthService {
  static async register(
    props?: IAuthRegisterProps
  ): Promise<AxiosResponse<IAuthRegisterResponse>> {
    const url = `${baseUrlAuth}/register`;
    return authApi.post(
      url,
      { ...props },
      { headers: { Accept: 'application/json' } }
    );
  }
  static async login(
    props?: IAuthLoginProps
  ): Promise<AxiosResponse<IAuthLoginResponse>> {
    const url = `${baseUrlAuth}/login`;
    return authApi.post(url, { ...props });
  }
  static async logout(): Promise<AxiosResponse<any>> {
    const url = `${baseUrlAuth}/logout`;
    return authApi.post(url);
  }
  static async refresh(): Promise<AxiosResponse<IAuthRefreshResponse>> {
    const url = `${baseUrlAuth}/refresh`;
    return authApi.post(url);
  }
  static async forgotPassword(
    props?: IAuthForgotPasswordProps
  ): Promise<AxiosResponse<IAuthForgotPasswordResponse>> {
    const url = `${baseUrlAuth}/forgot-password`;
    return authApi.post(
      url,
      { ...props },
      { headers: { Accept: 'application/json' } }
    );
  }
  static async resetPassword(
    props?: IAuthResetPasswordProps
  ): Promise<AxiosResponse<any>> {
    const url = `${baseUrlAuth}/reset-password/${props?.token}`;
    return authApi.post(url, {
      key: props?.key,
      password: props?.password,
      password_confirmation: props?.password_confirmation
    });
  }
  static async sendEmailVerification(): Promise<AxiosResponse<any>> {
    const url = `${baseUrlAuth}/send-email-verification`;
    return authApi.post(url);
  }
  static async verifyEmail(): Promise<AxiosResponse<any>> {
    const url = `${baseUrlAuth}/verify-email`;
    return authApi.post(url);
  }
}
