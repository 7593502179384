import Heading from 'shared/Heading/Heading';
import NcImage from 'shared/NcImage/NcImage';
import NcPlayIcon from 'shared/NcPlayIcon/NcPlayIcon';
import React, { FC, useState } from 'react';
import isSafariBrowser from 'utils/isSafariBrowser';
import thumbnailVideo from 'images/image-blogpage.jpeg';

export interface VideoType {
    id: string;
    title: string;
    thumbnail: string;
}

export interface SectionVideosProps {
    videos?: VideoType[];
    className?: string;
}

const VIDEOS_DEMO: VideoType[] = [
    {
        id: 'RzVvThhjAKw',
        title: 'Magical Scotland - 4K Scenic Relaxation Film with Calming Music',
        thumbnail: thumbnailVideo
        // 'https://images.unsplash.com/photo-1618172193622-ae2d025f4032?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064&q=80'
    }
];

const SectionVideos: FC<SectionVideosProps> = ({
    videos = VIDEOS_DEMO,
    className = ''
}) => {
    const [isPlay, setIsPlay] = useState(false);
    const [currentVideo] = useState(0);

    const renderMainVideo = () => {
        const video: VideoType = videos[currentVideo];
        return (
            <div
                className="group aspect-w-16 aspect-h-16 sm:aspect-h-9 bg-[#fff] rounded-3xl overflow-hidden border-4 border-white dark:border-neutral-900 sm:rounded-[50px] sm:border-[10px] z-0"
                title={video.title}
            >
                {isPlay ? (
                    <iframe
                        src={`https://www.youtube.com/embed/LFtB2yuTn_M?si=IqjVPHJsMdPeQ7XN${video.id}?autoplay=1${
                            isSafariBrowser() ? '&mute=1' : ''
                        }`}
                        title={video.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen
                        className="rounded-3xl"
                    ></iframe>
                ) : (
                    <>
                        <div
                            onClick={() => setIsPlay(true)}
                            className="cursor-pointer absolute inset-0 flex items-center justify-center z-10"
                        >
                            <NcPlayIcon />
                        </div>
                        <NcImage
                            containerClassName="absolute inset-0 rounded-3xl overflow-hidden z-0"
                            className="object-cover w-full h-full transition-transform group-hover:scale-105 duration-300  "
                            src={video.thumbnail}
                            title={video.title}
                            alt={video.title}
                        />
                    </>
                )}
            </div>
        );
    };

    return (
        <div className={`nc-SectionVideos ${className}`}>
            <Heading desc="A Mais Tecnológica e Charmosa Chapinha de Todos os Tempos! Luxury Titanium By Ana Paula Carvalho">
                Chapinha Luxury Titanium
            </Heading>

            <div className="flex flex-col relative sm:pr-4 sm:py-4 md:pr-6 md:py-6 xl:pr-14 xl:py-14 lg:flex-row">
                <div className="absolute -top-4 -bottom-4 -right-4 w-2/3 rounded-3xl  bg-[#f1f5f9] z-0 sm:rounded-[50px] md:top-0 md:bottom-0 md:right-0 xl:w-7/12 dark:bg-neutral-800/80"></div>
                <div className="flex-grow relative  ">{renderMainVideo()}</div>
            </div>
        </div>
    );
};

export default SectionVideos;
